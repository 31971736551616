
<div class="mx-calendar">
  <div class="mx-calendar-header" v-if="currentPanel === 'time'">
    <a @click="currentPanel = 'date'">{{ now.toLocaleDateString() }}</a>
  </div>
  <div class="mx-calendar-header" v-else>
    <a class="mx-calendar__prev-icon" @click="changeYear(-1)">&laquo;</a>
    <a
      v-show="currentPanel === 'date'"
      class="mx-calendar__prev-icon"
      @click="changeMonth(-1)"
      >&lsaquo;</a
    >
    <a class="mx-calendar__next-icon" @click="changeYear(1)">&raquo;</a>
    <a
      v-show="currentPanel === 'date'"
      class="mx-calendar__next-icon"
      @click="changeMonth(1)"
      >&rsaquo;</a
    >
    <a @click="showMonths">{{ months[currentMonth] }}</a>
    <a @click="showYears">{{ currentYear }}</a>
  </div>
  <div class="mx-calendar-content">
    <table class="mx-calendar-table" v-show="currentPanel === 'date'">
      <thead>
        <tr>
          <th v-for="(day, index) in days" :key="index">{{ day }}</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(row, i) in dates" :key="i">
          <td
            v-for="(cell, j) in row"
            :key="j"
            :title="cell.title"
            :class="getDateClasses(cell)"
            @click="selectDate(cell)"
          >
            {{ cell.day }}
          </td>
        </tr>
      </tbody>
    </table>
    <div class="mx-calendar-year" v-show="currentPanel === 'years'">
      <a
        v-for="(year, i) in years"
        :key="i"
        @click="selectYear(year)"
        :class="{
          current: currentYear === year,
          disabled: isDisabledYear(year),
        }"
        >{{ year }}</a
      >
    </div>
    <div class="mx-calendar-month" v-show="currentPanel === 'months'">
      <a
        v-for="(month, index) in months"
        :key="index"
        @click="selectMonth(index)"
        :class="{
          current: currentMonth === index,
          disabled: isDisabledMonth(index),
        }"
        >{{ month }}</a
      >
    </div>
    <div class="mx-calendar-time" v-show="currentPanel === 'time'">
      <div v-if="timeSelectOptions.length" class="mx-time-list-wrapper">
        <ul class="mx-time-list">
          <li
            class="mx-time-item mx-time-picker-item"
            :class="
              getTimeClasses(item.value.hours * 60 + item.value.minutes, -1)
            "
            @click="pickTime(item.value)"
            v-for="(item, i) in timeSelectOptions"
            :key="i"
          >
            {{ item.label }}
          </li>
        </ul>
      </div>
      <div
        v-else
        class="mx-time-list-wrapper"
        :style="{ width: 100 / times.length + '%' }"
        v-for="(time, index) in times"
        :key="index"
      >
        <ul class="mx-time-list">
          <li
            class="mx-time-item"
            v-for="num in time"
            :class="getTimeClasses(num, index)"
            :key="num"
            @click="selectTime(num, index)"
          >
            {{ num | timeText }}
          </li>
        </ul>
      </div>
    </div>
  </div>
</div>
