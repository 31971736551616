
<div :class="wrapClasses">
  <template v-if="type !== 'textarea'">
    <div
      :class="[prefixCls + '_group_prepend']"
      v-if="prepend"
      v-show="slotReady"
    >
      <slot name="prepend"></slot>
    </div>
    <!-- <i class="ivu-icon" :class="['ivu-icon-' + icon, prefixCls + '-icon', prefixCls + '-icon-normal']" v-if="icon" @click="handleIconClick"></i> -->
    <!-- <transition name="fade">
              <i class="ivu-icon ivu-icon-load-c ivu-load-loop" :class="[prefixCls + '-icon', prefixCls + '-icon-validate']" v-if="!icon"></i>
          </transition> -->
    <input
      :id="elementId"
      :autocomplete="autocomplete"
      ref="input"
      :type="type"
      :class="inputClasses"
      :placeholder="placeholder"
      :disabled="disabled"
      :maxlength="maxlength"
      :readonly="readonly"
      :name="name"
      :value="currentValue"
      :number="number"
      :autofocus="autofocus"
      :style="{
        width: width,
      }"
      @keyup.enter="handleEnter"
      @keyup="handleKeyup"
      @keypress="handleKeypress"
      @keydown="handleKeydown"
      @focus="handleFocus"
      @blur="handleBlur"
      @input="handleInput"
      @change="handleChange"
    />
    <div
      :class="[prefixCls + '_group_append']"
      v-if="append"
      v-show="slotReady"
    >
      <slot name="append"></slot>
    </div>
  </template>
  <textarea
    v-else
    :id="elementId"
    :autocomplete="autocomplete"
    ref="textarea"
    :class="textareaClasses"
    :style="textareaStyles"
    :placeholder="placeholder"
    :disabled="disabled"
    :rows="rows"
    :cols="cols"
    :maxlength="maxlength"
    :readonly="readonly"
    :name="name"
    :value="currentValue"
    :autofocus="autofocus"
    @keyup.enter="handleEnter"
    @keyup="handleKeyup"
    @keypress="handleKeypress"
    @keydown="handleKeydown"
    @focus="handleFocus"
    @blur="handleBlur"
    @input="handleInput"
  >
  </textarea>
</div>
