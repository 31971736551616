
<div :class="classes">
  <div class="field_adjust_wrapper" v-if="field_adjust">
    <klk-dialog
      v-model="choose_field_show"
      width="auto"
      close_icon
      mask_closable
    >
      <div class="dialog_frame">
        <klk-checkbox-group v-model="table_cols_checked" @on-change="setCols">
          <klk-checkbox
            v-for="item in cols_static"
            :label="item.label"
            :key="item.label"
            :disabled="item.disabled"
            >{{ item.label }}</klk-checkbox
          >
        </klk-checkbox-group>
      </div>
    </klk-dialog>
    <button
      class="theme_btn_base"
      @click="choose_field_show = !choose_field_show"
    >
      {{ tips.choose_field }}
    </button>
  </div>

  <table>
    <thead>
      <slot v-if="thead_slot" name="thead"> </slot>
      <tr v-else>
        <th
          v-for="(item, i) in cols_copy"
          v-show="!item.hide"
          :width="item.width"
          :key="i"
        >
          <span v-html="item.label"></span>
          <div class="sortable_wrapper">
            <span
              v-on:click="
                sortProxy(
                  item.prop,
                  item.sortable,
                  'asc',
                  item.sortableFn,
                  $event
                )
              "
              :class="item.sortable ? 'sortable asc' : ''"
            ></span>
            <span
              v-on:click="
                sortProxy(
                  item.prop,
                  item.sortable,
                  'desc',
                  item.sortableFn,
                  $event
                )
              "
              :class="item.sortable ? 'sortable desc' : ''"
            ></span>
          </div>
        </th>
      </tr>
    </thead>
    <tbody>
      <slot
        v-for="(item, index) in table_data_copy"
        :$index="index"
        :row="item"
      ></slot>
    </tbody>
    <slot name="tfoot"></slot>
  </table>
</div>
