
<div class="no_data" v-show="no_data_show">
  <div class="icon_wrapper">
    <klk-icon
      class="klook-icon-empty-failed icon"
      type="empty-failed"
    ></klk-icon>
  </div>
  <p class="title">
    {{ no_data_title }}
  </p>
  <p class="desc" v-show="no_data_title_desc">
    {{ no_data_title_desc }}
  </p>
  <button
    class="theme_btn_base btn"
    v-show="no_data_button_text"
    @click="button_click"
  >
    {{ no_data_button_text }}
  </button>
</div>
