
<label :class="wrapClasses">
  <span :class="radioClasses">
    <span :class="innerClasses"></span>
    <input
      type="radio"
      :class="inputClasses"
      :disabled="disabled"
      :checked="currentValue"
      :name="groupName"
      @change="change"
      @focus="onFocus"
      @blur="onBlur"
    />
  </span>
  <span :class="labelWrapperClassses">
    <slot>{{ label }}</slot>
  </span>
</label>
