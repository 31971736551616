import inhouse from "@klook/inhouse-tracker";

const trim = function (string) {
  return (string || "").replace(/^[\s\uFEFF]+|[\s\uFEFF]+$/g, "");
};

// Find components upward
function findComponentUpward(context, componentName, componentNames) {
  if (typeof componentName === "string") {
    componentNames = [ componentName ];
  } else {
    componentNames = componentName;
  }

  var parent = context.$parent;
  var name = parent.$options.name;
  while ( parent && (!name || componentNames.indexOf(name) < 0) ) {
    parent = parent.$parent;
    if (parent) name = parent.$options.name;
  }
  return parent;
}

// Find components downward
function findComponentsDownward(context, componentName) {
  return context.$children.reduce(function (components, child) {
    if (child.$options.name === componentName) components.push(child);
    var foundChilds = findComponentsDownward(child, componentName);
    return components.concat(foundChilds);
  }, []);
}

function oneOf(value, validList) {
  for (var i = 0; i < validList.length; i++) {
    if (value === validList[i]) {
      return true;
    }
  }
  return false;
}

function hasClass(el, clsName) {
  return !!(
    el &&
    (el.className || "")
      .split(" ")
      .map((cls) => cls.trim())
      .includes(clsName)
  );
}

function addClass(el, cls) {
  if (!el) return;
  let curClass = el.className;
  const classes = (cls || "").split(" ");

  for (let i = 0, j = classes.length; i < j; i++) {
    const clsName = classes[i];
    if (!clsName) continue;

    if (el.classList) {
      el.classList.add(clsName);
    } else {
      if (!hasClass(el, clsName)) {
        curClass += " " + clsName;
      }
    }
  }
  if (!el.classList) {
    el.className = curClass;
  }
}

/* istanbul ignore next */
function removeClass(el, cls) {
  if (!el || !cls) return;
  const classes = cls.split(" ");
  let curClass = " " + el.className + " ";

  for (let i = 0, j = classes.length; i < j; i++) {
    const clsName = classes[i];
    if (!clsName) continue;

    if (el.classList) {
      el.classList.remove(clsName);
    } else {
      if (hasClass(el, clsName)) {
        curClass = curClass.replace(" " + clsName + " ", " ");
      }
    }
  }
  if (!el.classList) {
    el.className = trim(curClass);
  }
}

const SUPPORT_CURRENCY_SYMBO = {
  HKD: "HK$",
  USD: "US$",
  TWD: "NT$",
  SGD: "S$",
  CNY: "¥",
  AUD: "AUD",
  GBP: "£",
  LAK: "₭",
  EUR: "€",
  THB: "฿",
  NZD: "NZ$",
  MYR: "RM",
  JPY: "¥",
  AED: "AED",
  PHP: "₱",
  KRW: "₩",
  VND: "₫",
  IDR: "Rp",
  SEK: "kr",
  NOK: "kr",
  DKK: "kr",
  CHF: "CHF",
  TRY: "YTL",
  ISK: "kr",
  INR: "₹",
  KHR: "KHR",
  MMK: "BUK",
  MOP: "MOP$",
  QAR: "QR",
  OMR: "OMR",
  JOD: "JOD",
  FJD: "$",
  CAD: "CAD",
  MUR: "Rs",
  MXN: "$",
  MAD: "DH",
  EGP: "EGP",
  ZAR: "R",
  MGA: "MGA",
};

function formatThousands(n = 0, formatter = ",") {
  if (!n) return "0";
  const number = n.toString();
  // 如果价格中包含 ',' 则直接返回价格
  if (number.includes(",")) {
    return number;
  }
  if (number.includes(".")) {
    const numberArray = number.split(".");
    return `${numberArray[0].replace(/(?=(?!(\b))(\d{3})+$)/g, formatter)}.${
      numberArray[1]
    }`;
  }

  return number.replace(/(?=(?!(\b))(\d{3})+$)/g, formatter);
}

function formatPriceThousands(price) {
  price = (price || "0").toString();
  let tmp;
  if (!price.includes(".")) {
    tmp = formatThousands(price);
  } else {
    const prices = price.split(".");
    tmp = `${formatThousands(prices[0])}.${prices[1]}`;
  }
  return tmp;
}

function copyCode(copy_text) {
  console.log('copy_text', copy_text);
  const textarea = document.createElement("textarea");
  textarea.style.position = "fixed";
  textarea.style.top = 0;
  textarea.style.left = 0;
  textarea.style.border = "none";
  textarea.style.outline = "none";
  textarea.style.resize = "none";
  textarea.style.background = "transparent";
  textarea.style.color = "transparent";
  textarea.value = copy_text;
  document.body.appendChild(textarea);
  textarea.select();
  try {
    document.execCommand("Copy");
  } catch (e) {
  }
  document.body.removeChild(textarea);
}


function isMobile(tablet = true) {
  const mobiles =
    "(android|bbd+|meego).+mobile|avantgo|bada/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)/|plucker|pocket|psp|series[46]0|symbian|treo|up.(browser|link)|vodafone|wap|windows (ce|phone)|xda|xiino";
  const tablets = `${mobiles}|android|ipad|playbook|silk`;
  const RE = tablet ? mobiles : tablets;
  return new RegExp(RE, "i").test(window.navigator.userAgent);
}

function triggerRoutePageView(to, routeName, className, time = 800) {
  if (to.name === routeName) {
    setTimeout(() => {
      inhouse.track("pageview", className);
    }, time);
  }
}

function triggerRoutePathPageView(to, path, className, time = 800) {
  if (to.path === path) {
    setTimeout(() => {
      inhouse.track("pageview", className);
    }, time);
  }
}

function getSpmContentAff(ihtKey, aid) {
  if (/\S+\?\S+$/.test(ihtKey)) {
    if (aid) {
      return `${ihtKey}&oid=affiliate_${aid}`;
    } else {
      return ihtKey;
    }
  } else {
    return !aid ? `${ihtKey}` :
      `${ihtKey}?oid=affiliate_${aid}`;
  }
}

function getSpmContentExt(ihtKey, obj) {
  console.log('obj', obj);
  if (/\S+\?\S+$/.test(ihtKey)) {
    if (obj) {
      return `${ihtKey}&ext=${JSON.stringify(obj)}`;
    } else {
      return ihtKey;
    }
  } else {
    return !obj ? `${ihtKey}` :
      `${ihtKey}?ext=${JSON.stringify(obj)}`;
  }
}

const ihtNameMap = {
  home: 'Home',
  tools: 'Tools',
  help: 'Help',
  contact_us: 'ContactUs',
  dashboard: 'Dashboard',
  my_ads: 'MyAds',
  ticket_status_list: 'MonthlyReports',
  performance_overview: 'PerformanceOverview',
  ticket_list: 'TicketList',
  performance: 'Performance',
  ad_promo_code: 'PromoCodes',
  hotel_deals: 'HotelDeals',
  my_account: 'MyAccount',
  admin: 'Admin',
  terms_conditions: 'TNC',
  commission_rates: 'CommRates',
  resource_hub: 'ResourceHub',
  notification: 'Notification',
  signup_auto: 'SignUpInfo',
  choose_user_type: 'SignUpProgram',
  text_links: 'TextLinks',
  dynaminc_widgets: 'DynamicWidgets',
  more_ad_tools: 'OtherTools',
};

const adListPathMap = {
  "/my_ads/": "AdList",
  "/my_ads/co_brand": "CoBranding",
  "/my_ads/text_links": "TextLinks",
  "/my_ads/dynaminc_widgets": "DynamicWidgets",
  "/my_ads/ad_promo_code": "PromoCodes",
  "/my_ads/hotel_deals": "HotelDeals",
  "/my_ads/more_ad_tools": "OtherTools",
  "/my_ads/ad_tools/search_vertical": "SearchBox",
  "/my_ads/ad_tools/banner": "StaticBanners",
  "/my_ads/ad_tools/activity": "ActivityBanners",
}


export {
  adListPathMap,
  ihtNameMap,
  getSpmContentExt,
  copyCode,
  getSpmContentAff,
  triggerRoutePathPageView,
  triggerRoutePageView,
  findComponentUpward,
  findComponentsDownward,
  oneOf,
  hasClass,
  addClass,
  isMobile,
  removeClass,
  SUPPORT_CURRENCY_SYMBO,
  formatPriceThousands,
};
