<style lang="scss">
.common_footer {
  height: 343px;
  background: #333;
  font-size: 14px;

  .common_footer_content {
    width: 1160px;
    margin: 0 auto;

    .klk_svg {
      width: 40px;
      height: 40px;
    }

    .footer_upper {
      padding: 40px 0;
      width: 100%;
      display: flex;
      justify-content: space-between;
      color: #999;
      border-bottom: 1px solid rgba(153, 153, 153, 0.1);

      .related_links {
        display: flex;
        align-items: center;

        .wechat_wrapper {
          .klk_poptip_popper {
            top: 40px !important;
          }
        }
      }

      .email_wrapper {
        display: flex;
        align-items: center;

        span {
          padding-right: 10px;
        }

        input {
          width: 360px;
          height: 42px;
          border-radius: 2px;
          background-color: #fff;
          padding: 12px;
        }
      }
    }

    .footer_center {
      padding: 30px 0;
      color: #999;
      font-size: 12px;
      display: flex;
      justify-content: space-between;

      .licence_wrapper {
        width: 220px;
        line-height: 1.6;
      }

      .list {
        font-size: 14px;

        a {
          color: #999;
          text-decoration: none;
        }

        .list_title {
          color: #fff;
          margin-bottom: 20px;
        }

        li {
          line-height: 1.8;
          cursor: pointer;
        }
      }
    }

    .ask_klook_and_payment_channel {
      .ask_klook_btn {
        width: 214px;
        margin-bottom: 30px;
        border: solid 1px #888;
        background: none;
        color: #888;
        cursor: pointer;

        &:hover {
          color: #fff;
          border-color: #fff;
        }
      }

      .payment_channel_wrapper {
        .payment_channel {
          display: flex;
          flex-wrap: wrap;
          align-items: center;
          width: 220px;

          .klk_svg {
            margin-right: 16px;

            &:nth-child(4n) {
              margin-right: 0;
            }
          }
        }
      }
    }
  }
}
</style>

<template >
  <div class="common_footer" v-if="should_show_common_footer" :data-spm-page="`${getPageName}?trg=manual`">
    <div class="common_footer_content" :data-spm-module="'Footer?trg=manual'">
      <div class="footer_upper">
        <div class="related_links">
          <a
            v-for="item in related_link_list"
            :key="item.url"
            :href="item.url"
            target="_blank"
          >
            <klk-poptip
              trigger="hover"
              class="wechat_wrapper"
              v-if="item.icon_type === 'wechat'"
            >
              <klk-icon :type="item.icon_type"></klk-icon>
              <div slot="content" class="poptip_content">
                <img src="../../imgs/klook.png" width="130px" alt=""/>
              </div>
            </klk-poptip>
            <klk-icon :type="item.icon_type" v-else></klk-icon>
          </a>
        </div>
      </div>
      <div class="footer_center">
        <div class="licence_wrapper">
          © 2014-{{ new Date().getFullYear() }} Klook Travel Technology <br/>
          Limited. All Rights Reserved. <br/>
          HK Travel Agent Licence: 354005. <br/>
          SG Travel Agent Licence: 02851. <br/>
          TW Travel Agent License: 甲 03950.
        </div>
        <div class="about_klook list">
          <div class="list_title">
            {{ $t("COMMON_FOOTER_about_klook") }}
          </div>
          <ul>
            <li v-for="(item, index) in about_klook_list" :key="index">
              <a target="_blank" :href="item.url">{{ item.label }}</a>
            </li>
          </ul>
        </div>

        <div class="about_klook list">
          <div class="list_title">
            {{ $t("COMMON_FOOTER_partnerships") }}
          </div>
          <ul>
            <li v-for="(item, index) in partnerships_list" :key="index">
              <a
                target="_blank"
                :href="item.url"
                @click="on_partnership_link(index)"
              >{{ item.label }}</a
              >
            </li>
          </ul>
        </div>

        <div class="about_klook list">
          <div class="list_title">
            {{ $t("COMMON_FOOTER_terms_of_user") }}
          </div>
          <ul>
            <li v-for="(item, index) in terms_of_user_list" :key="index">
              <a target="_blank" :href="item.url">{{ item.label }}</a>
            </li>
          </ul>
        </div>

        <div class="ask_klook_and_payment_channel">
          <button
            class="base_btn ask_klook_btn"
            type="button"
            :data-spm-item="'ContactUs'"
            name="button"
            @click="ask_klook"
          >
            {{ $t("COMMON_FOOTER_ask_klook") }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ihtNameMap } from "@/desktop/js/utils";

export default {
  name: "CommonFooter",
  data() {
    let influencer_program_link_en =
      "https://docs.google.com/forms/d/e/1FAIpQLSdL2Ih8naxwUEQDs4Zl5GPtbJYR1sdD7azMwVEi9vDiX85ReQ/viewform";
    let influencer_program_list = {
      en: influencer_program_link_en,
      "zh-CN": "https://jinshuju.net/f/sPCSJL",
      "zh-TW":
        "https://docs.google.com/forms/d/e/1FAIpQLSdL2Ih8naxwUEQDs4Zl5GPtbJYR1sdD7azMwVEi9vDiX85ReQ/viewform",
      "zh-HK":
        "https://docs.google.com/forms/d/e/1FAIpQLSdL2Ih8naxwUEQDs4Zl5GPtbJYR1sdD7azMwVEi9vDiX85ReQ/viewform",
      ko: influencer_program_link_en,
      th: influencer_program_link_en,
      vi: influencer_program_link_en,
      id: influencer_program_link_en,
      ja: influencer_program_link_en,
    };
    let langsNotSupportedBlog = [ "ko/", "th/", "id/", "vi/", "ja/" ];
    let langsNotSupportedAgent = [ "ko/", "th/", "id/", "vi/", "ja/" ];
    let langsNotSupportedMerchant = [ "ko/", "th/", "id/", "vi/" ];
    let langURLBlog =
      langsNotSupportedBlog.indexOf(KLK_LANG_PATH) > -1 ? "" : KLK_LANG_PATH;
    let langURLAgent =
      langsNotSupportedAgent.indexOf(KLK_LANG_PATH) > -1 ? "" : KLK_LANG_PATH;
    let langURLMerchant =
      langsNotSupportedMerchant.indexOf(KLK_LANG_PATH) > -1
        ? ""
        : KLK_LANG_PATH;
    let blogURL = "//www.klook.com/" + langURLBlog + "blog";
    let agentURL = "//www.klook.com/" + langURLAgent + "agent";
    let merchantURL = "//merchant.klook.com/" + langURLMerchant + "login";
    let giftURL = "//zfrmz.com/F98mA5DhqLijVlbapbYT";
    return {
      should_show_common_footer: true,
      related_link_list: [
        {
          icon_type: "fb",
          url: "https://www.facebook.com/klookglobal",
        },
        {
          icon_type: "twitter",
          url: "https://twitter.com/klooktravel",
        },
        {
          icon_type: "wechat",
          url: "https://www.klook.com/",
        },
        {
          icon_type: "ins",
          url: "https://www.instagram.com/klooktravel",
        },
      ],

      about_klook_list: [
        {
          url: `//www.klook.com/${KLK_LANG_PATH}about`,
          label: this.$t("COMMON_FOOTER_about_klook_list_1"),
        },
        {
          url: blogURL,
          label: this.$t("COMMON_FOOTER_about_klook_list_2"),
        },
        {
          url: `//www.klook.com/${KLK_LANG_PATH}partner`,
          label: this.$t("COMMON_FOOTER_about_klook_list_3"),
        },
        {
          url: `//www.klook.com/${KLK_LANG_PATH}careers`,
          label: this.$t("COMMON_FOOTER_about_klook_list_4"),
        },
      ],
      partnerships_list: [
        {
          url: merchantURL,
          label: this.$t("COMMON_FOOTER_partnerships_list_1"),
        },
        {
          url: `//affiliate.klook.com/${KLK_LANG_PATH}`,
          label: this.$t("COMMON_FOOTER_partnerships_list_2"),
        },
        {
          url: influencer_program_list[KLK_LANG],
          label: this.$t("COMMON_FOOTER_partnerships_list_3"),
        },
        {
          url: agentURL,
          label: this.$t("COMMON_FOOTER_partnerships_list_4"),
        },
        {
          url: giftURL,
          label: this.$t("gift_card"),
        },
      ],
      terms_of_user_list: [
        {
          url: `//www.klook.com/${KLK_LANG_PATH}policy`,
          label: this.$t("COMMON_FOOTER_terms_of_user_list_2"),
        },
      ],
      ihtNameMap: ihtNameMap,
      payment_channel: [
        {
          icon_type: "alipay",
        },
        {
          icon_type: "paypal",
        },
        {
          icon_type: "visa",
        },
        {
          icon_type: "master_card",
        },
        {
          icon_type: "american_express",
        },
      ],
    };
  },
  computed: {
    getPageName() {
      console.log('this.$route', this.$route);
      if(this.$route.matched.length && this.$route.matched[0]['path'] === '/admin') {
        return 'Admin'
      }
      return this.ihtNameMap[this.$route.name];
    }
  },
  methods: {
    ask_klook() {
      this.$router.push({
        name: "contact_us",
      });
    },
    on_partnership_link(index) {
      // Google Tag Manager for "Click Influencer Program" Event
      if (index == 2) {
        window.dataLayer.push({
          event: "influencer",
          eventCategory: "Footer",
          eventAction: "Click Influencer Program",
        });
      }
    },
  },
  watch: {
    $route(to, from) {
      const toSignUpAuto = to.path.startsWith("/signup_auto");
      const toChooseSignUpType = to.path.startsWith("/choose_user_type");
      const fromSignUpAuto = from.path.startsWith("/signup_auto");
      const toActivationFeedback = to.path.startsWith("/activation_feedback");
      const fromActivationFeedback = from.path.startsWith(
        "/activation_feedback"
      );

      if (toChooseSignUpType || toSignUpAuto || toActivationFeedback) {
        this.should_show_common_footer = false;
      }
      if (fromSignUpAuto && !toChooseSignUpType || fromActivationFeedback) {
        this.should_show_common_footer = true;
      }
    },
  },
};
</script>
