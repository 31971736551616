<script>
//https://vuejs.org/v2/guide/render-function.html#Functional-Components
// booing.vue页面尝试了这种渲染，其它页面还是使用的noData和table分开的形式渲染
import { KlkTable } from "../../table";
import { KlkNoData } from "../../no_data";
export default {
  name: "KlkSmartList",
  functional: true,
  render(createElement, context) {
    function appropriateListComponent() {
      //  var items = context.props.items
      if (
        (context.props.type === "no_data" ||
          context.props.table_data.length === 0) &&
        !context.props.no_tips
      ) {
        return KlkNoData;
      }

      return KlkTable;
    }
    const slots = context.slots();
    return createElement(
      appropriateListComponent(),
      {
        attrs: context.data.attrs,
        on: context.data.on,
        props: context.props,
        scopedSlots: {
          // Scoped slots in the form of  { name: props => VNode | Array<VNode> }
          default: context.data.scopedSlots.default,
          thead: (/* props */) => slots.thead,
          tfoot: (/* props */) => slots.tfoot,
        },
      },
      context.children
    );
  },
};
</script>
