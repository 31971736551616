import logger from '@klook/aff-logger';

export const initMonitor = (Vue, type = 'portal') => {
  const $logger = logger[type];
  // 1. 全局脚本错误: window.onerror
  // catch: SyntaxError | RuntimeError(ReferenceError...)
  if (!window.onerror) {
    window.onerror = (message, source, lineno, colno, error) => {
      $logger.alert.global(error || new Error(message), { source, lineno, colno });
    };
  }
  // catch: ErrorEvent
  // capture 捕获静态资源加载报错
  window.addEventListener('error', function(event) {
    let err = event.error;
    if (!err) {
      // TODO: 暂不上报静态资源加载报错
      if (!event.message) return;
      let msg = event.message || 'addEventListener.error';
      if (event.target && event.target.outerHTML) msg += (' => ' + event.target.outerHTML);
      err = new Error(msg);
    }
    $logger.alert.global(err, { event });
  }, true);
  // 2. Promise => PromiseRejectionEvent
  window.addEventListener('unhandledrejection', function(event) {
    $logger.alert.unhandledrejection(new Error(event.reason || 'unhandledrejection'), { event });
  });
  // 3. Vue 组件报错
  Vue.config.errorHandler = (err, vm, info) => {
    console.info(err, vm);
    $logger.alert.vue(err, { info });
  };
};
