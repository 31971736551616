import { KlkAlert } from "./alert";
import { KlkCheckbox, KlkCheckboxGroup } from "./checkbox";
import { KlkRadio, KlkRadioGroup } from "./radio";
import { KlkDialog } from "./dialog";
import { KlkForm, KlkFormItem } from "./form";

import { KlkGroupTab } from "./group_tab";

import { KlkInput } from "./input";
import { KlkNoData } from "./no_data";
import { KlkPagination } from "./pagination";

import { KlkRate } from "./rate";

import { KlkPoptip } from "./poptip";
import { KlkSelect, KlkOption, KlkOptionGroup } from "./select";
import { KlkSmartList } from "./smart_list";
import { KlkSwitch } from "./switch";
import { KlkTable } from "./table";
import { KlkTabs, KlkTabPane } from "./tabs";
import { KlkCollapse, KlkPanel } from "./collapse";

import { KlkWeekList } from "./week_list";
import { KlkCircle } from "./circle";
import { KlkIcon } from "./icon";

import { KlkUpload } from "./upload";
import { KlkDatePicker } from "./datepicker";

import { KlkMessage } from "./message";
import { KlkToast } from "./toast";

import { KlkTablePoptip } from "./table_poptip";

const components = {
  KlkAlert,
  KlkCheckbox,
  KlkCheckboxGroup,
  KlkRadio,
  KlkRadioGroup,
  KlkDialog,
  KlkForm,
  KlkFormItem,
  KlkGroupTab,
  KlkInput,
  KlkNoData,
  KlkPagination,
  KlkRate,
  KlkPoptip,
  KlkSelect,
  KlkOption,
  KlkOptionGroup,
  KlkSmartList,
  KlkSwitch,
  KlkTable,
  KlkTabs,
  KlkTabPane,
  KlkCollapse,
  KlkPanel,
  KlkWeekList,
  KlkCircle,
  KlkIcon,
  KlkUpload,
  KlkDatePicker,
  KlkTablePoptip,
  KlkMessage,
  KlkToast
};

const install = function (Vue /* , options */) {
  Object.keys(components).map(function (name) {
    const component = components[name];
    Vue.component(component.name, component);
  });
  Vue.prototype.$Modal = KlkDialog;
  Vue.prototype.$Message = KlkMessage;
  Vue.prototype.$Toast = KlkToast;
};

export default {
  ...components,
  install,
};
