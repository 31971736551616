import { addClass, removeClass } from "../../utils/assist";
//有BUG的padding动画
const Transition = {
  beforeEnter(el) {
    addClass(el, "collapse-transition");
    if (!el.dataset) el.dataset = {};
    el.style.height = "0";
  },

  enter(el) {
    el.dataset.oldOverflow = el.style.overflow;
    if (el.scrollHeight !== 0) {
      el.style.height = el.scrollHeight + "px";
    } else {
      el.style.height = "";
    }

    el.style.overflow = "hidden";
  },

  afterEnter(el) {
    // for safari: remove class then reset height is necessary
    removeClass(el, "collapse-transition");
    el.style.height = "";
    el.style.overflow = el.dataset.oldOverflow;
  },

  beforeLeave(el) {
    if (!el.dataset) el.dataset = {};
    el.dataset.oldOverflow = el.style.overflow;

    el.style.height = el.scrollHeight + "px";
    el.style.overflow = "hidden";
  },

  leave(el) {
    if (el.scrollHeight !== 0) {
      // for safari: add class after set height, or it will jump to zero height suddenly, weired
      addClass(el, "collapse-transition");
      el.style.height = 0;
    }
  },

  afterLeave(el) {
    removeClass(el, "collapse-transition");
    el.style.height = "";
    el.style.overflow = el.dataset.oldOverflow;
  },
};

export default {
  name: "CollapseTransition",
  functional: true,
  render(h, { children }) {
    const data = {
      on: Transition,
    };

    return h("transition", data, children);
  },
};
