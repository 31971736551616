
<label :class="wrapClasses">
  <span :class="checkboxClasses">
    <span :class="innerClasses"></span>
    <input
      v-if="group"
      type="checkbox"
      :class="inputClasses"
      :disabled="disabled"
      :value="label"
      v-model="model"
      :name="name"
      @change="change"
    />
    <input
      v-if="!group"
      type="checkbox"
      :class="inputClasses"
      :disabled="disabled"
      :checked="currentValue"
      :name="name"
      @change="change"
    />
  </span>
  <slot
    ><span v-if="showSlot" v-html="htmlStr">{{ label }}</span></slot
  >
</label>
