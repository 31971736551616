
<ul class="group_tab">
  <li
    v-for="(item, index) in data_copy"
    :key="index"
    @click="tabTrigger(index)"
    :class="{ active_tab: item.selected }"
  >
    {{ item.label }}
  </li>
</ul>
