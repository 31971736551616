<template >
  <div class="no_data" v-show="no_data_show">
    <div class="icon_wrapper">
      <klk-icon
        class="klook-icon-empty-failed icon"
        type="empty-failed"
      ></klk-icon>
    </div>
    <p class="title">
      {{ no_data_title }}
    </p>
    <p class="desc" v-show="no_data_title_desc">
      {{ no_data_title_desc }}
    </p>
    <button
      class="theme_btn_base btn"
      v-show="no_data_button_text"
      @click="button_click"
    >
      {{ no_data_button_text }}
    </button>
  </div>
</template>

<script>
import { $t } from "@/comm/utils";

export default {
  name: "KlkNoData",
  props: {
    no_data_show: {
      type: Boolean,
      default: () => true,
    },
    no_data_title: {
      type: String,
      default: () => $t("NO_DATA_no_data_warning"),
    },
    no_data_title_desc: {
      type: String,
      default: () => "",
    },
    no_data_button_text: {
      type: String,
      default: () => "",
    },
  },
  methods: {
    button_click() {
      this.$emit("no_data_button_click");
    },
  },
};
</script>

<style lang="scss">
.no_data {
  padding: 20px 0 40px;
  text-align: center;
  .icon_wrapper {
    .icon {
      width: 72px;
      height: 72px;
    }
  }
  .title {
    margin: 16px 0;
    font-size: 24px;
    font-weight: 500;
    color: $theme_color;
    text-align: center;
  }
  .desc {
    color: #666666;
  }
  .btn {
    margin-top: 20px;
  }
}
</style>
