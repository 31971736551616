<style lang="scss">
.klk_table {
  &.klk_table_stripe {
    table {
      tr:nth-child(2n) td {
        background: #fbfdff;
      }
      th {
        background: #ecf2f7;
        font-weight: 500;
        color: #333;
      }
    }
  }
  table {
    table-layout: fixed;
    border-spacing: 0;
    border-collapse: collapse;
    width: 100%;
    color: #333;
    td,
    th {
      line-height: 14px;
      border: solid 1px #dee4eb;
    }
    thead {
      tr {
        height: 48px;
        th {
          line-height: 15px;
          background-color: #ecf2f7;
          position: relative;
          font-size: 12px;
          font-weight: 500;
          vertical-align: middle;
          .sortable_wrapper {
            position: absolute;
            height: 11px;
            right: 12px;
            transform: translate(50%, -50%);
            top: 50%;
            .sortable {
              cursor: pointer;
              border-left: 5px solid transparent;
              border-right: 5px solid transparent;
              position: absolute;
            }
            .desc {
              border-top: 7px solid #b2b2b2;
              right: 0;
              bottom: -3px;
              &.sortable_active {
                border-top: 7px solid #2f2f2f;
              }
            }
            .asc {
              border-bottom: 7px solid #b2b2b2;
              right: 0;
              top: -2px;
              &.sortable_active {
                border-bottom: 7px solid #2f2f2f;
              }
            }
          }
        }
      }
    }
    tbody,
    tfoot {
      tr {
        td {
          padding: 17px 12px 16px;
          font-size: 12px;
          text-align: center;
          // color: #424242;
          vertical-align: middle;
          white-space: normal;
          word-wrap: break-word;
          box-sizing: border-box;
        }
      }
    }
  }
}
</style>

<template>
  <div :class="classes">
    <div class="field_adjust_wrapper" v-if="field_adjust">
      <klk-dialog
        v-model="choose_field_show"
        width="auto"
        close_icon
        mask_closable
      >
        <div class="dialog_frame">
          <klk-checkbox-group v-model="table_cols_checked" @on-change="setCols">
            <klk-checkbox
              v-for="item in cols_static"
              :label="item.label"
              :key="item.label"
              :disabled="item.disabled"
              >{{ item.label }}</klk-checkbox
            >
          </klk-checkbox-group>
        </div>
      </klk-dialog>
      <button
        class="theme_btn_base"
        @click="choose_field_show = !choose_field_show"
      >
        {{ tips.choose_field }}
      </button>
    </div>

    <table>
      <thead>
        <slot v-if="thead_slot" name="thead"> </slot>
        <tr v-else>
          <th
            v-for="(item, i) in cols_copy"
            v-show="!item.hide"
            :width="item.width"
            :key="i"
          >
            <span v-html="item.label"></span>
            <div class="sortable_wrapper">
              <span
                v-on:click="
                  sortProxy(
                    item.prop,
                    item.sortable,
                    'asc',
                    item.sortableFn,
                    $event
                  )
                "
                :class="item.sortable ? 'sortable asc' : ''"
              ></span>
              <span
                v-on:click="
                  sortProxy(
                    item.prop,
                    item.sortable,
                    'desc',
                    item.sortableFn,
                    $event
                  )
                "
                :class="item.sortable ? 'sortable desc' : ''"
              ></span>
            </div>
          </th>
        </tr>
      </thead>
      <tbody>
        <slot
          v-for="(item, index) in table_data_copy"
          :$index="index"
          :row="item"
        ></slot>
      </tbody>
      <slot name="tfoot"></slot>
    </table>
  </div>
</template>

<script>
import _ from "underscore";
import { hasClass } from "@/desktop/js/utils/assist";

const prefixCls = "klk_table";
export default {
  name: "KlkTable",
  props: {
    table_data: {
      type: Array,
      require: true,
    },
    /*    col item
            {
                label:'',
                disabled:'',//optional
                hide:false,//optional
                width: '80px',//optional
                prop:'user_name',//option
                sortable:true,
                sortableFn:function(type){
                    this.search.orderby_type = type!='' ? 'ParticipantName' : '';
                    this.search.orderby_sequence = type;//type =>'asc'|| 'desc'
                    this.loadData()
                }.bind(this)
            }*/
    stripe: {
      type: Boolean,
      default: false,
    },
    cols: {
      type: Array,
      require: true,
    },
    thead_slot: {
      type: Boolean,
      default: () => false,
    },
    field_adjust: {
      //trigger field filter feature
      type: Boolean,
      default: () => false,
    },
  },
  data() {
    return {
      table_data_copy: [],
      //mainly for field filter feature
      choose_field_show: false,
      cols_static: [],
      table_cols_checked: [],
    };
  },
  watch: {
    table_data: function (newVal) {
      this.table_data_copy = newVal.concat([]);
    },
    cols: function (newVal) {
      this.cols_copy = newVal.concat([]);
      if (this.field_adjust) {
        this.initCols();
      }
    },
  },
  computed: {
    classes() {
      return [
        `${prefixCls}`,
        {
          [`${prefixCls}_stripe`]: this.stripe,
          [`${prefixCls}_field_adjust`]: this.field_adjust,
        },
      ];
    },
  },
  created() {
    this.table_data_copy = this.table_data.concat([]);
    this.cols_copy = this.cols.concat([]);
    if (this.field_adjust) {
      this.initCols();
    }
  },
  methods: {
    // below mainly for field filter feature
    //example relates to merchant booking_report_feedback

    initCols() {
      this.cols_static = this.cols.reduce((acc, cur) => {
        acc[cur.label] = cur;
        return acc;
      }, {});
      this.table_cols_checked = _.keys(this.cols_static);
      this.setCols();
      this.setTableDataCopy();
    },
    hasCol(colName) {
      return this.table_cols_checked.indexOf(colName) != -1;
    },
    setCols() {
      var newCols = [];
      _.keys(this.cols_static).forEach((col) => {
        //之所以遍历这个，是为了保证header顺序不变
        if (this.table_cols_checked.indexOf(col) != -1) {
          newCols.push(this.cols_static[col]);
        }
      });
      this.cols_copy = newCols;
    },
    setTableDataCopy() {
      this.table_data_copy.forEach((item) => {
        item.hasCol = this.hasCol;
      });
      this.table_data_copy = this.table_data_copy.concat([]);
    },

    //below  mainly for sort feature

    // [{
    //         label: tips.booking_time,
    //         sortable: true,
    //         prop: 'booking_time',
    //         sortableFn:function(type){
    //             this.search.orderby_type = 'BookingTime';
    //             this.search.orderby_sequence = type;//type =>'asc'|| 'desc' || ‘’
    //             this.loadData()
    //         }.bind(this),
    //hide:true, //这个字段主要是为权限准备的
    //     }]
    changeStyle(event) {
      let closestTable,
        sortableEl,
        curEl = event.target.parentElement;
      while (curEl) {
        if (hasClass(curEl, "klk_table")) {
          closestTable = curEl;
          break;
        }
        curEl = curEl.parentElement;
      }
      if (closestTable) {
        sortableEl = closestTable.querySelector(".sortable");
        if (sortableEl) {
          sortableEl.className = sortableEl.className.replace(
            "sortable_active",
            ""
          );
        }
      }
      event.target.className += " sortable_active";
    },
    sortProxy(prop, sortable, type, sortableFn, event) {
      function sorted() {
        return hasClass(event.target, "sortable_active");
      }

      if (sorted()) {
        //已经选过 不包括async 和 sync
        event.target.className = event.target.className.replace(
          "sortable_active",
          ""
        );
        if (sortableFn) {
          sortableFn("");
          return;
        }
        this.sortBy(prop, sortable, "");
      } else {
        this.changeStyle(event);
        if (sortableFn) {
          //做的目的是为了满足搜索结果的 async
          sortableFn(type);
          return;
        }
        this.sortBy(prop, sortable, type);
      }
    },
    sortBy(prop, sortable, type) {
      if (!sortable) return;
      if (type == "") {
        //还原之前的列表显示 , 静态分页的时候
        this.table_data_copy = this.table_data.cancat([]);
        return;
      }

      function ascendCompare(a, b) {
        if (a < b) return -1;
        if (a > b) return 1;
        return 0;
      }

      function descendCompare(a, b) {
        if (a > b) return -1;
        if (a < b) return 1;
        return 0;
      }
      this.table_data_copy.sort((tr1, tr2) => {
        //目前支持 string和number
        var a = tr1[prop],
          b = tr2[prop];
        if (typeof a == "string") {
          a = a.toUpperCase(); // ignore upper and lowercase
          b = b.toUpperCase(); // ignore upper and lowercase
        }
        if (type == "asc") {
          return ascendCompare(a, b);
        } else if (type == "desc") {
          return descendCompare(a, b);
        }
      });
    },
  },
};
</script>
