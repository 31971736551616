<template>
  <klk-cast-feedback
    v-if="questionObj.question_show"
    ref="feedback"
    :visible="false"
    :question="questionObj.question"
    :feedbackText="$t('feedback')"
    :btnSubmitText="$t('submit')"
    :successText="$t('feedback_thanks')"
    :placeholder="$t('feedback_tell_us')"
    :hearFromYou="$t('hear_from_you')"
    :showClose="true"
    mainColor="#2FC48D"
    position="right"
    :delayShowTime="delayShowTime"
    :delayFadeTime="delayFadeTime"
    @handle-close="handleCloseFn"
    @handle-submit="handleSubmit(arguments)"
    @handle-open-feedback="handleOpenFeedback"
  >
  </klk-cast-feedback>
</template>

<script>
import klook from 'klook'
import { urlObj } from "../../../comm/common_data";
export default {
  name: "AllOverFeedback",
  data() {
    return {};
  },
  computed: {
    delayShowTime() {
      return +klook.cacheJS("delay_show_time");
    },
    delayFadeTime() {
      return +klook.cacheJS("delay_fade_time");
    },
    questionObj() {
      return this.$store.state.globalObjModule.question;
    },
  },
  methods: {
    sendGTMTrack(type, text = "", score = 0) {
      window.dataLayer.push({
        event: "overallCsat",
        eventCategory: "Overall CSAT",
        eventAction: type,
        eventLabel: score ? score : text,
      });
    },
    handleSubmit(args) {
      this.sendGTMTrack("Submit", "", args[0]);
      klook.ajaxPostJSON(
        urlObj.feedback_post_cast,
        {
          question_id: this.questionObj.id,
          url_path: this.$route.path,
          feedback_score: args[0],
          feedback_content: args[1],
        },
        (resp) => {
          if (resp.success) {
            this.$refs.feedback.submitSucess();
          } else {
            this.$Message.warning(resp.error.message);
          }
        }
      );
    },
    handleOpenFeedback() {
      this.sendGTMTrack("Entrance");
    },
    handleCloseFn(submit) {
      let text = submit ? "thank you" : "";
      this.sendGTMTrack("Close", text);
    },
  },
};
</script>
<style lang="scss"></style>
