
<transition name="fade" @after-leave="handleAfterLeave">
  <div
    :class="wrapClasses"
    v-show="visible"
    @mouseenter="clearTimer"
    @mouseleave="startTimer"
  >
    <p class="klk_message_content">{{ content }}</p>
    <p @click="close" v-show="showClose" class="klk_message_close">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="16"
        height="16"
        viewBox="0 0 16 16"
      >
        <path
          :fill="closeIconColor"
          fill-rule="evenodd"
          d="M8 6.869L3.616 2.485l-1.131 1.13L6.869 8l-4.384 4.384 1.13 1.131L8 9.131l4.384 4.384 1.131-1.13L9.131 8l4.384-4.384-1.13-1.131L8 6.869z"
        />
      </svg>
    </p>
  </div>
</transition>
