
<div id="app" :style="changeWebSiteName() ? { padding: 0 } : {}">
  <div>
    <common-header
      :is_signin="is_signin"
      :total="total"
      v-if="!changeWebSiteName()"
    ></common-header>
    <div class="notification_message" v-show="showNotification()">
      {{ $t("27384", { number: total }) }}
      &nbsp;
      <a class="jump" @click="jumpUrl()">Click here to read</a>
      <div @click="handleClickClose" class="svg_wrapper">
        <klk-icon type="close_white"></klk-icon>
      </div>
    </div>
  </div>

  <div
    class="global_content"
    :style="changeWebSiteName() ? { display: 'flex' } : {}"
  >
    <all-over-feedback v-if="is_not_admin"></all-over-feedback>
    <div
      class="alert-wrapper"
      :style="{
        'padding-top': showNotification() ? '30px' : 0,
      }"
      v-if="showKreatorNotification()"
    >
      <el-alert :type="getNotificationType" :closable="false" show-icon>
        <div v-html="getKreatorNotification()"></div>
      </el-alert>
    </div>
    <transition name="fade" mode="out-in">
      <keep-alive include="Dashboard">
        <router-view></router-view>
      </keep-alive>
    </transition>
  </div>
  <common-footer v-if="!changeWebSiteName()"></common-footer>
  <confirm-user-info-dialog
    :dialog_show="show_confirm_user_info_dailog"
    @on-close="fromLoginFlag = false"
    v-if="show_confirm_user_info_dailog"
  ></confirm-user-info-dialog>
</div>
