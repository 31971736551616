<template>
  <div
    id="klk-cast-feedback-content"
    class="klk-cast-feedback-content"
    :style="autoStyle"
  >
    <div v-if="showClose" class="klk-cast-close" @click="handleCloseFn()">
      <img :src="genResourceFullPath('feedback/icon_close.png')" alt="" />
    </div>
    <div v-if="!isSubmit">
      <p class="klk-cast-title">{{ question }}</p>
      <div class="klk-cast-wrapper">
        <span v-for="(item, index) in imgList" :key="index">
          <img
            @click="hanleCastFn(item.value)"
            :src="getCurrentIcon(index + 1)"
            alt=""
          />
        </span>
      </div>
      <template v-if="score">
        <textarea
          class="klk-cast-context"
          v-model="feedbackContext"
          :placeholder="placeholder"
          :rows="4"
        ></textarea>
        <div
          :class="{
            'klk-cast-content-footer': true,
            'button-flex': btnRemindText,
          }"
        >
          <span
            v-if="btnRemindText"
            class="fd-btn-close"
            @click="handleCloseFn()"
          >
            {{ btnRemindText }}
          </span>
          <span :style="canSubmit ? btnColor : ''" @click="submitData">
            {{ btnSubmitText }}
          </span>
        </div>
      </template>
    </div>
    <div v-if="isSubmit" class="klk-cast-success">
      <img :src="genResourceFullPath('feedback/success.png')" alt="" />
      <span>{{ successText }}</span>
    </div>
  </div>
</template>
<script>
export default {
  name: "KlkCastFeedbackContent",
  props: {
    width: {
      type: [Number, String],
      default: 360,
    },
    color: {
      type: String,
      default: "",
    },
    mainColor: {
      type: String,
      default: "",
    },
    question: {
      type: String,
      default: "",
    },
    btnRemindText: {
      type: String,
      default: "",
    },
    btnSubmitText: {
      type: String,
      default: "",
    },
    showClose: {
      type: Boolean,
      dafault: false,
    },
    successText: {
      type: String,
      default: "",
    },
    position: {
      type: String,
      dafault: "right",
    },
    placeholder: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      score: 0,
      feedbackContext: "",
      isSubmit: false,
      timer: null,
    };
  },
  computed: {
    canSubmit() {
      return this.score > 3 || (this.score && this.feedbackContext);
    },
    autoStyle() {
      let style = {
        width: typeof this.width === "string" ? this.width : `${this.width}px`,
        height: `${this.height}px`,
        color: this.color,
      };
      return Object.assign(style, this.positionList[this.position]);
    },
    btnColor() {
      return {
        backgroundColor: this.mainColor,
      };
    },
    imgList() {
      return [
        {
          value: 1,
          img: this.genResourceFullPath("feedback/1.png"),
        },
        {
          value: 2,
          img: this.genResourceFullPath("feedback/2.png"),
        },
        {
          value: 3,
          img: this.genResourceFullPath("feedback/3.png"),
        },
        {
          value: 4,
          img: this.genResourceFullPath("feedback/4.png"),
        },
        {
          value: 5,
          img: this.genResourceFullPath("feedback/5.png"),
        },
      ];
    },
    positionList() {
      return {
        right: { right: "50px", top: "450px" },
        static: { position: "static" },
      };
    },
  },
  methods: {
    hanleCastFn(score) {
      this.score = score;
    },
    submitData() {
      if (this.canSubmit) {
        this.$emit("submit", this.score, this.feedbackContext);
      }
    },
    submitSuccess() {
      this.isSubmit = true;
      if (this.timer) clearTimeout(this.timer);
      this.timer = setTimeout(() => {
        this.handleCloseFn();
      }, 3000);
    },
    getCurrentIcon(item) {
      if (item == +this.score) {
        return this.genResourceFullPath(`${item}.png`);
      }
      return this.genResourceFullPath(`nc${item}.png`);
    },
    handleCloseFn() {
      this.$emit("handle-close", this.isSubmit);
    },
  },
};
</script>
<style lang="scss">
.klk-cast-feedback-content {
  position: fixed;
  padding: 24px 24px 10px;
  background-color: #fff;
  border-radius: 4px;
  z-index: 100;
  display: flex;
  justify-content: center;
  box-shadow: 0px 1px 3px #ccc;
  cursor: default;
}

.klk-cast-close {
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
  img {
    width: 15px;
    height: 15px;
  }
}

.klk-cast-title {
  margin: 10px;
  text-align: center;
  font-size: 14px;
}

.klk-cast-wrapper {
  margin: 10px;
  display: flex;
  justify-content: center;
  align-content: center;
  cursor: pointer;
  img {
    width: 40px;
    height: 40px;
    margin: 0 5px;
  }
}

.klk-cast-context {
  width: 100%;
  background-color: #f5f5f5;
  border: 1px solid #f5f5f5;
  min-width: 300px;
  resize: none;
}

.klk-cast-content-footer {
  margin: 10px 0;
  display: flex;
  justify-content: flex-end;
  span {
    font-size: 12px;
    line-height: 16px;
    padding: 6px 12px;
    color: #fff;
    background: #ccc;
    border-radius: 2px;
    cursor: pointer;
  }
}
.button-flex {
  justify-content: center;
}

span.fd-btn-close {
  color: #000;
  margin-right: 20px;
  background-color: #fff;
  border: 1px solid #000;
}

.klk-cast-success {
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  img {
    width: 26px;
    height: 26px;
    margin-bottom: 10px;
  }
  span {
    line-height: 20px;
    text-align: center;
  }
}
</style>
