import { js_i18n } from "@/desktop/js/i18n";
import { RENDER_CONFIG } from "@/config";
import _cloneDeep from "lodash/cloneDeep";
import dayjs from "dayjs";

export function isEnglish(value) {
  const str = String(value).trim();
  const reg = new RegExp(/^[A-Za-z0-9 ,_.'-]+$/);
  return reg.test(str);
}

function shouldEditWebsite(data) {
  let info = data ? data : USER_INFO;
  if (info && info.websites) {
    let index = info.websites.findIndex((item) => {
      return item.name === "" || !isEnglish(item.name);
    });
    return index !== -1;
  } else {
    return false;
  }
}

export function formatObjEmpty(params, filter) {
  let searchParams = _cloneDeep(params);
  let keys = [];
  if (!filter) {
    keys = Object.keys(searchParams);
    keys.map((key) => {
      if (!searchParams[key] && searchParams[key] !== 0) {
        delete searchParams[key];
      }
    });
  }

  return searchParams;
}

export function formatExportUrl(params, filter) {
  let searchParams = _cloneDeep(params);
  let str = "?";
  let keys = [];
  if (!filter) {
    keys = Object.keys(searchParams);
    keys.map((key) => {
      if (!searchParams[key]) {
        delete searchParams[key];
      }
    });
  }

  keys = Object.keys(searchParams);
  keys.map((key, index) => {
    if (index !== keys.length - 1) {
      str += `${key}=${searchParams[key]}&`;
    } else {
      str += `${key}=${searchParams[key]}`;
    }
  });
  return str;
}

function $t(key, args) {
  return js_i18n.t(key, args);
}

export function getHotelPath(ad_type) {
  let obj = {
    suggest_link: "link",
    hotel_link: "link",
    hotel_static_widget: "hotel_widget",
    hotel_dynamic_widget: "hotel_widget",
    deals_ads_page: "deals_ads_page",
  };
  return obj[ad_type];
}

export function isHotelTools(ad_type) {
  return [
    "suggest_link",
    "hotel_link",
    "hotel_static_widget",
    "hotel_dynamic_widget",
  ].includes(ad_type);
}

const TextLinkTypeMap = {
  "link":"all",
  "city_link":"ttd",
  "listing_link": "ttd",
  "suggest_link":"hotel",
  "hotel_link": "hotel",
  "car_rental_link":"car_rental"
}
export function isWidgetType(ad_type) {
  return ['dynamic_widget', "hotel_static_widget", "hotel_dynamic_widget"].includes(ad_type);
}
export function isTextLink(ad_type) {
  return Object.keys(TextLinkTypeMap)?.includes(ad_type);
}

export function handleNewLink(ad_type, adid, uid) {
  if(isTextLink(ad_type)) {
    window.open(
      this.$router.resolve({
        name: "text_links",
        query: { ad_id: adid, uid: uid, other_page: 1, type: TextLinkTypeMap[ad_type]},
      }).href
    );
    return true
  } else if(isWidgetType(ad_type)) {
    window.open(
      this.$router.resolve({
        name: "dynaminc_widgets",
        query: { ad_id: adid, uid: uid, other_page: 1, type: isHotelTools(ad_type) ? "hotel": "ttd"},
      }).href
    );
    return true
  } 

  return false;
}

export function showConfig(ad_type_backend) {
  // 旧的 search 不支持编辑, placement_link 也不支持, auto_dynamic_widget 为自动创建 widget 的类型，不允许 config
  let hideConfigType = [
    "search",
    "placement_link",
    "auto_dynamic_widget",
    "hotel_deal_link",
    "hotel_deal_widget",
    "auto_hotel_dynamic_widget",
    "ttd_search_api"
  ];
  if (!ad_type_backend) {
    return false;
  }
  // 临时加的特殊逻辑
  if (ad_type_backend === "listing_link" && window.KLK_LANG !== "zh-TW") {
    return false;
  }
  return !hideConfigType.includes(ad_type_backend);
}

// 聚合 ads_type 为widget类型来做跳转
export function allWidgetType() {
  return RENDER_CONFIG.reduce((acc, cur) => {
    let widget = cur.render_map.find((data) => +data.id === 1);
    if (widget) {
      let d = widget.info.type.map((item) => item.name);
      return acc.concat(d);
    } else {
      return acc;
    }
  }, []);
}

export function jump(ad_type) {
  // 需要渲染的ads_type 从配置文件中读取, link类型的从配置中读取
  if (allWidgetType().includes(ad_type)) {
    return "dynamic_widget";
  } else if (["link", "city_link", "listing_link"].includes(ad_type)) {
    return "link"; // 聚合为link
  } else {
    return ad_type;
  }
}

export function getAfterDay(day) {
  return {
    start: dayjs().subtract(day, "day").format("YYYY-MM-DD"),
    end: dayjs().subtract(1, "day").format("YYYY-MM-DD"),
  };
}

const downloadFileBlob = async (name, path) => {
  if (klook.isAdminApi(path)) {
    // 前置请求 admin 接口，先走鉴权逻辑再触发下载
    await klook.checkGoogleAuthStatus();
  }
  return new Promise(function (resolve, reject) {
    var xhr = new XMLHttpRequest();
    xhr.open("get", path);
    xhr.responseType = "blob";
    xhr.setRequestHeader(
      "Partner-Session",
      sessionStorage.getItem("session") || ""
    );
    xhr.setRequestHeader("Partner-Uid", sessionStorage.getItem("uid") || "");
    xhr.onload = function () {
      if (this.status === 200 || this.status === 304) {
        const url = URL.createObjectURL(this.response);
        const a = document.createElement("a");
        a.style.display = "none";
        a.href = url;
        a.download = name
          ? name
          : xhr
              .getResponseHeader("content-disposition")
              ?.split("attachment; filename=")[1];
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
        URL.revokeObjectURL(url);
        resolve(this.response);
      } else {
        reject({
          status: this.status,
          statusText: xhr.statusText,
        });
      }
    };
    xhr.onerror = function () {
      reject({
        status: this.status,
        statusText: xhr.statusText,
      });
    };
    xhr.send();
  });
};

export { $t, shouldEditWebsite, downloadFileBlob };
