<template>
  <form autocomplete="off" :class="classes">
    <slot></slot>
    <!-- 防止一个input enter提交form 刷新页面 -->
    <input type="text" autocomplete="off" style="display: none" />
  </form>
</template>
<script>
// import { oneOf } from '../../utils/assist';

var prefixCls = "klk_form";

export default {
  name: "KlkForm",
  props: {
    model: {
      type: Object,
    },
    rules: {
      type: Object,
    },
    flex_row: {
      type: Boolean,
      default: false,
    },
    // labelWidth: {
    //     type: Number
    // },
    // labelPosition: {
    //     validator (value) {
    //         return oneOf(value, ['left', 'right', 'top']);
    //     },
    //     default: 'right'
    // },
    // inline: {
    //     type: Boolean,
    //     default: false
    // },
    // showMessage: {
    //     type: Boolean,
    //     default: true
    // }
  },
  data() {
    return {
      fields: [],
    };
  },
  computed: {
    classes() {
      return [
        // `${prefixCls}`,
        // `${prefixCls}-label-${this.labelPosition}`,
        // {
        //     [`${prefixCls}-inline`]: this.inline
        // }
        prefixCls,
        {
          [`${prefixCls}_flex_row`]: this.flex_row,
        },
      ];
    },
  },
  methods: {
    resetFields() {
      this.fields.forEach((field) => {
        field.resetField();
      });
    },
    validate(callback) {
      var valid = true;
      var count = 0;
      let errors = [];
      this.fields.forEach((field) => {
        field.validate("", (error) => {
          if (error) {
            errors.push(error);
            valid = false;
          }
          if (
            typeof callback === "function" &&
            ++count === this.fields.length
          ) {
            callback(valid, errors);
          }
        });
      });
    },
    validateField(prop, cb) {
      var field = this.fields.filter((field) => field.prop === prop)[0];
      if (!field) {
        throw new Error(
          "[form warn]: must call validateField with valid prop string!"
        );
      }

      field.validate("", cb);
    },
  },
  watch: {
    rules() {
      this.validate();
    },
  },
  created() {
    this.$on("on-form-item-add", (field) => {
      if (field) this.fields.push(field);
      return false;
    });
    this.$on("on-form-item-remove", (field) => {
      if (field.prop) this.fields.splice(this.fields.indexOf(field), 1);
      return false;
    });
  },
};
</script>
<style lang="scss">
.klk_input_wrapper {
  .klk_input_group_prepend,
  .klk_input_group_append {
    border: 1px solid #e0e0e0;
    border-radius: 2px;
    display: table-cell;
    padding: 0 12px;
    vertical-align: middle;
    text-align: center;
  }
  .klk_input_group_prepend {
    border-right: 0;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
  .klk_input_group_append {
    border-left: 0;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
  &.klk_input_group_with_prepend {
    input {
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
    }
  }
  &.klk_input_group_with_append {
    input {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
    }
  }
  &.klk_input_group {
    display: table;
  }
  textarea {
    font-size: 16px;
    padding: 12px;
  }
  &.klk_input_wrapper_small {
    input {
      height: 32px;
    }
  }
}

.klk_checkbox_wrapper {
  display: inline-block;
  margin-right: 10px;
  cursor: pointer;
  .klk_checkbox_wrapper_disabled {
    color: #ccc;
  }
}
input,
select {
  background: #fff;
  font-size: 16px;
  width: 100%;
  color: #333333;
  padding: 0 12px;
  height: 40px;
}
.klk_form {
  input[type="radio"] {
    width: auto;
  }

  &.klk_form_flex_row {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    input[type="text"],
    select,
    .klk_select {
      width: 220px;
    }
    .klk_form_item {
      width: auto;
      margin-right: 20px;
      margin-top: 22px;
    }
  }
  .klk_radio_wrapper {
    display: flex;
    align-items: center;
    cursor: pointer;
    margin-right: 10px;
    .klk_radio_label_wrapper {
      margin: 0 10px;
      color: #333;
      font-size: 14px;
    }
  }
  .klk_radio_group {
    display: flex;
  }

  .klk_form_item {
    width: 100%;
    margin-top: 16px;
    position: relative;
    word-wrap: break-all;
    white-space: normal;
    display: inline-block;
    .klk_input_label {
      margin-bottom: 4px;
      font-size: 14px;
      color: #333;
    }
    .klk_form_item_content {
      margin: 8px 0;
    }

    .klk_checkbox_wrapper {
      cursor: pointer;
      margin-right: 10px;
      input[type="checkbox"] {
        width: auto;
      }
      .klk_checkbox {
        display: inline-block;
        vertical-align: middle;
        white-space: nowrap;
        cursor: pointer;
        outline: 0;
        line-height: 1;
        position: relative;
        margin: 6px 0;
      }
      .klk_checkbox_inner {
        display: inline-block;
        width: 14px;
        height: 14px;
        position: relative;
        top: 0;
        left: 0;
        border: 1px solid #dddee1;
        border-radius: 2px;
        background-color: #fff;
        transition: border-color 0.2s ease-in-out,
          background-color 0.2s ease-in-out;
        &::after {
          content: "";
          display: table;
          width: 4px;
          height: 8px;
          position: absolute;
          top: -1px;
          left: 3px;
          border: 2px solid #fff;
          border-top: 0;
          border-left: 0;
          transform: rotate(45deg) scale(1);
          transition: all 0.2s ease-in-out;
        }
      }
      &.klk_checkbox_wrapper_checked {
        .klk_checkbox_inner {
          border-color: $theme_color;
          background-color: $theme_color;
        }
      }
      .klk_checkbox_input {
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        z-index: 1;
        cursor: pointer;
        opacity: 0;
      }
    }
  }
  .klk_form_item_error {
    input,
    select,
    textarea {
      border: 1px solid #ff5722;
    }
    .klk_select {
      .select_action_wrapper {
        border-color: #ff5722;
      }
    }
    .klk_form_item_error_tip {
      position: absolute;
      padding-top: 2px;
      font-size: 14px;
      height: 16px;
      color: #ff5722;
    }
  }
}
</style>
