import { hasNewFlagKeys } from "@/comm/common_data";
const getNewFlagStoreKey = (key) => {
  let storeKey =  key.replaceAll(".", "_");

  // 兼容老逻辑不匹配，直接写固定
  if(key === "tool.text_links.car_rental") {
    storeKey = "tool_text_link_car_rental";
  } 

  return storeKey; 
}
var mixin = function () {
  return {
    created() {
      //Disabling data reactivity also avoids the performance cost of converting data into reactive objects.
      // if(!this.common_data){//children components auto inject common_data
      //     this.common_data = common_data;
      // }
    },
    methods: {
      genResourceFullPath(relativePath){
        return new URL(`../../imgs/${relativePath}`,import.meta.url).href
      },
      handlePageSizeChange(newVal) {
        //由于pagination的page_size_opts在SS-38需求才加入，所以会影响之前所有的，于是做了这个折中全局绑定方案
        if (this.pagination && this.pagination.pageSize) {
          this.pagination.pageSize = newVal;
          // this.loadData && this.loadData();
        }
      },

      
      ifShowNewFlag(key){
        if(!hasNewFlagKeys.includes(key)) return false;
        const storeKey = getNewFlagStoreKey(key);
        return storeKey && !this.$store.state.globalObjModule.clickNewGategoryFlags[storeKey]
      },
      
      updateNewCategoryFlag (key) {
        const storeKey = getNewFlagStoreKey(key);
        this.$store.commit("UPDATE_NEW_CATEGORY_FLAG", {[storeKey]: true});
      }
    },
  };
};

const global_mixin = mixin();
export { global_mixin };
