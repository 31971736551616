// 此配置文件提供 Affiliate 前后端共享配置
// 如果是某一端独有的配置，请勿加入此文件，请加入各端各自配置文件

const COUNTRY_CURRENCY_MAP = {
  AU: { currency: "AUD", currencySymbol: "AUD" },
  CN: { currency: "CNY", currencySymbol: "¥" },
  GB: { currency: "GBP", currencySymbol: "£" },
  HK: { currency: "HKD", currencySymbol: "HK$" },
  SG: { currency: "SGD", currencySymbol: "S$" },
  TW: { currency: "TWD", currencySymbol: "NT$" },
  US: { currency: "USD", currencySymbol: "US$" },
  TH: { currency: "THB", currencySymbol: "฿" },
  JP: { currency: "JPY", currencySymbol: "¥" },
  KR: { currency: "KRW", currencySymbol: "₩" },
  ID: { currency: "IDR", currencySymbol: "Rp" },
  MY: { currency: "MYR", currencySymbol: "RM" },
  PH: { currency: "PHP", currencySymbol: "₱" },
};

const CURRENCY_SYMBO_MAP = {
  HKD: "HK$",
  USD: "US$",
  TWD: "NT$",
  SGD: "S$",
  CNY: "¥",
  AUD: "AUD",
  GBP: "£",
  LAK: "₭",
  EUR: "€",
  THB: "฿",
  NZD: "NZ$",
  MYR: "RM",
  JPY: "¥",
  AED: "AED",
  PHP: "₱",
  KRW: "₩",
  VND: "₫",
  IDR: "Rp",
  SEK: "kr",
  NOK: "kr",
  DKK: "kr",
  CHF: "CHF",
  RUB: "₽",
  TRY: "YTL",
  ISK: "kr",
  INR: "₹",
  KHR: "KHR",
  MMK: "MMK",
  QAR: "QR",
  OMR: "OMR",
  JOD: "JOD",
  LBP: "LBP",
  FJD: "$",
  CAD: "$",
  MUR: "Rs",
  MXN: "$",
};

const LANG_MAP = {
  en: { title: "English", path: "/", name: "en-US", currency: 'USD' },
  "zh-CN": { title: "中文（简体）", path: "/zh-CN", name: "zh-CN",currency: 'CNY' },
  "zh-TW": { title: "繁體中文 (台灣)", path: "/zh-TW", name: "zh-TW" ,currency: 'TWD'},
  "zh-HK": { title: "繁體中文 (香港)", path: "/zh-HK", name: "zh-HK",currency: 'HKD' },
  ko: { title: "한국어", path: "/ko", name: "ko-KR",currency: 'KRW' },
  ja: { title: "日本語", path: "/ja", name: "ja-JP",currency: 'JPY' },
  th: { title: "ไทย", path: "/th", name: "th-TH" ,currency: 'THB'},
  vi: { title: "Tiếng Việt", path: "/vi", name: "vi-VN",currency: 'VND' },
  id: { title: "Bahasa Indonesia", path: "/id", name: "id-ID",currency: 'IDR' },
  fr: { title: "Français", path: "/fr", name: "fr-FR",currency: 'EUR' },
  es: { title: "Español", path: "/es", name: "es-ES",currency: 'EUR' },
  de: { title: "Deutsch", path: "/de", name: "de-DE",currency: 'EUR' },
  ru: { title: "Pусский", path: "/ru", name: "ru-RU",currency: 'EUR' },
};

// affiliate lang 映射"用户中心" 表, 用户注册接口中使用该变量
/* 用户中心定义字段
//EN_US 英文
    EN_US = "en_US" //"en_US"
//ZH_CN 简体
    ZH_CN = "zh_CN" //"zh_CN"
//ZH_TW 台湾繁体
    ZH_TW = "zh_TW" //"zh_TW"
//ZH_HK 香港繁体
    ZH_HK = "zh_HK" //"zh_HK"
//KO_KR
    KO_KR = "ko_KR" //"ko_KR"
//TH_TH
    TH_TH = "th_TH" //"th_TH"
//vi_VN
    VI_VN = "vi_VN" //"vi_VN"
//id_ID
    ID_ID = "id_ID" // "id_ID"
//ja_JP
    JA_JP = "ja_JP" // "ja_JP"
//en_GB
    EN_GB = "en_GB"
//en_AU
    EN_AU = "en_AU"
//en_NZ
    EN_NZ = "en_NZ"
//en_CA
    EN_CA = "en_CA"
//en_SG
    EN_SG = "en_SG"
//en_HK
    EN_HK = "en_HK"
//en_PH
    EN_PH = "en_PH"
//en_MY
    EN_MY = "en_MY"
//en_IN
    EN_IN = "en_IN"
    EN_BS = "en_BS"
// 法语
    FR_FR = "fr_FR"
// 西班牙语
    ES_ES = "es_ES"
// 德育
    DE_DE = "de_DE"
// 俄语
    RU_RU = "ru_RU"
// 意大利语
    IT_IT = "it_IT"
*/
const USER_LANG_MAP = {
  en: "en_US",
  "zh-CN": "zh_CN",
  "zh-TW": "zh_TW",
  "zh-HK": "zh_HK",
  ko: "ko_KR",
  ja: "ja_JP",
  th: "th_TH",
  vi: "vi_VN",
  id: "id_ID",
  fr: "FR_FR",
  es: "es_ES",
  de: "de_DE",
  ru: "ru_RU",
};

const KLK_LANG_OG_LOCALE_MAP = {
  en: "EN",
  "zh-CN": "zh_CN",
  "zh-TW": "zh_TW",
  "zh-HK": "zh_HK",
  ko: "ko_KR",
  ja: "ja-JP",
  th: "th_TH",
  vi: "vi_VN",
  id: "id_ID",
};

function getLangOptions(lang_list) {
  return lang_list.map((key) => {
    return {
      title: LANG_MAP[key].title,
      value: key, //SUPPORT_LANGS[key].name.replace('-','_')
      currency: LANG_MAP[key].currency
    };
  });
}

function getLangAPIOptions(lang_list) {
  return lang_list.map((key) => {
    return {
      title: LANG_MAP[key].title,
      value: LANG_MAP[key].name,
    };
  });
}

const SUPPORT_LANGS_LIST = [
  "en",
  "zh-CN",
  "zh-TW",
  "zh-HK",
  "ko",
  "ja",
  "th",
  "vi",
  "id",
];

const SUPPORT_LANGS_LIST_WIDGET = SUPPORT_LANGS_LIST.concat([
  "fr",
  "es",
  "de",
  "ru",
]);

const SUPPORT_LANGS = SUPPORT_LANGS_LIST.reduce((res, lang) => {
  res[lang] = Object.assign({}, LANG_MAP[lang]);
  return res;
}, {});

const LANG_OPTIONS = getLangOptions(SUPPORT_LANGS_LIST);

const LANG_OPTIONS_WIDGET = getLangOptions(
  SUPPORT_LANGS_LIST_WIDGET
);

const LANG_OPTIONS_API = getLangAPIOptions(SUPPORT_LANGS_LIST);

const ALL_LANG_OPTIONS = getLangOptions(Object.keys(LANG_MAP));

// currency
const CURRENCY_LIST = Object.keys(CURRENCY_SYMBO_MAP);

const SUPPORT_COUNTRY_CURRENCY_MAP = CURRENCY_LIST.reduce(
  (result, currency) => {
    Object.keys(COUNTRY_CURRENCY_MAP).forEach((country) => {
      if (COUNTRY_CURRENCY_MAP[country].currency == currency) {
        result[country] = Object.assign({}, COUNTRY_CURRENCY_MAP[country]);
      }
    });
    return result;
  },
  {}
);

module.exports = {
  CURRENCY_LIST,
  CURRENCY_SYMBO_MAP,
  SUPPORT_COUNTRY_CURRENCY_MAP,
  SUPPORT_LANGS,
  SUPPORT_LANGS_LIST,
  LANG_OPTIONS,
  LANG_OPTIONS_WIDGET,
  LANG_OPTIONS_API,
  ALL_LANG_OPTIONS,
  LANG_MAP,
  KLK_LANG_OG_LOCALE_MAP,
  USER_LANG_MAP,
};
