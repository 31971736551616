<template>
  <svg :class="classes" :style="styles">
    <use :xlink:href="link_type"></use>
  </svg>
</template>

<script>
const prefixCls = "klk_svg";
export default {
  name: "KlkIcon",
  props: {
    type: String,
    size: [Number, String],
    color: String,
  },
  data() {
    return {
      link_type: "#" + this.type,
    };
  },
  watch: {
    type: function (newVal) {
      this.link_type = `#${newVal}`;
    },
  },
  computed: {
    classes() {
      return [prefixCls, `${prefixCls}_${this.type}`];
    },
    styles() {
      let style = {};

      if (this.size) {
        style["width"] = `${parseFloat(this.size)}px`;
        style["height"] = `${parseFloat(this.size)}px`;
      }

      if (this.color) {
        style.color = this.color;
      }

      return style;
    },
  },
};
</script>

<style lang="scss">
.klk_svg {
  width: 12px;
  height: 12px;
  cursor: pointer;
  outline: none;
  &.up_triangle {
    color: $theme_color;
  }
  &.down_triangle {
    color: #f44b48;
    transform: rotate(180deg);
  }
}
</style>
