import Vue from "vue";
import Modal from "./dialog.vue";
// var Button from '../button/button.vue';
import _ from "underscore";
var prefixCls = "klk_modal_alert";

Modal.newInstance = function (properties) {
  var _props = properties || {};

  var Instance = new Vue({
    // mixins: [ Locale ],
    data: _.extend(
      {},
      {
        visible: false,
        // width: 416,
        title: "",
        content: "",
        // iconType: '',
        // iconName: '',
        okText: undefined,
        okClass: undefined,
        cancelText: undefined,
        cancelClass: undefined,
        showCancel: false,
        showOk: false,
        // loading: false,
        // buttonLoading: false,
        // scrollable: false
      },
      _props
    ),
    render(h) {
      var footerVNodes = [];
      if (this.showCancel) {
        footerVNodes.push(
          h("button", {
            props: {
              type: "button",
            },
            attrs: {
              class: this.cancelClass || "theme_btn_base_white",
            },
            style: {
              marginRight: "20px",
            },
            on: {
              click: this.cancel,
            },
            domProps: {
              innerHTML: this.localeCancelText,
            },
          })
        );
      }
      if (this.showOk) {
        footerVNodes.push(
          h("button", {
            props: {
              type: "button",
            },
            attrs: {
              class: this.okClass || "theme_btn_base",
            },
            on: {
              click: this.ok,
            },
            domProps: {
              innerHTML: this.localeOkText,
            },
          })
        );
      }

      // render content
      var body_render;
      if (this.render) {
        body_render = h(
          "div",
          {
            attrs: {
              class: prefixCls + "_body " + prefixCls + "_body_render",
            },
          },
          [this.render(h)]
        );
      } else {
        body_render = h(
          "div",
          {
            attrs: {
              class: prefixCls + "_body",
            },
          },
          [
            // h('div', {
            //     class: this.iconTypeCls
            // }, [
            //     h('i', {
            //         class: this.iconNameCls
            //     })
            // ]),
            h("div", {
              domProps: {
                innerHTML: this.content,
              },
            }),
          ]
        );
      }

      return h(
        Modal,
        //     {
        //     props: Object.assign({}, _props, {
        //         width: this.width,
        //         scrollable: this.scrollable
        //     }),
        //     // domProps: {
        //     //     value: this.visible
        //     // },
        //     // on: {
        //     //     input: function(status){
        //     //         this.visible = status;
        //     //     }
        //     // }
        // },
        [
          h(
            "div",
            {
              attrs: {
                class: prefixCls,
              },
            },
            [
              h(
                "div",
                {
                  attrs: {
                    class: prefixCls + "_head",
                  },
                },
                [
                  h("div", {
                    attrs: {
                      class: prefixCls + "_head_title",
                    },
                    domProps: {
                      innerHTML: this.title,
                    },
                  }),
                ]
              ),
              body_render,
              h(
                "div",
                {
                  attrs: {
                    class: prefixCls + "_footer",
                  },
                },
                footerVNodes
              ),
            ]
          ),
        ]
      );
    },
    computed: {
      // iconTypeCls () {
      //     return [
      //         `${prefixCls}-body-icon`,
      //         `${prefixCls}-body-icon-${this.iconType}`
      //     ];
      // },
      // iconNameCls () {
      //     return [
      //         'ivu-icon',
      //         `ivu-icon-${this.iconName}`
      //     ];
      // },
      localeOkText() {
        if (this.okText) {
          return this.okText;
        } else {
          return "OK";
        }
      },
      localeCancelText() {
        if (this.cancelText) {
          return this.cancelText;
        } else {
          return "Cancel";
        }
      },
    },
    methods: {
      cancel() {
        this.$children[0].visible = false;
        this.buttonLoading = false;
        this.onCancel();
        this.remove();
      },
      ok() {
        if (this.loading) {
          this.buttonLoading = true;
        } else {
          this.$children[0].visible = false;
          this.remove();
        }
        this.onOk();
      },
      remove() {
        var _this = this;
        setTimeout(function () {
          _this.destroy();
        }, 300);
      },
      destroy() {
        this.$destroy();
        document.body.removeChild(this.$el);
        this.onRemove();
      },
      onOk() {},
      onCancel() {},
      onRemove() {},
    },
  });

  var component = Instance.$mount();
  document.body.appendChild(component.$el);
  var modal = Instance.$children[0];

  return {
    show(props) {
      modal.$parent.showCancel = props.showCancel;
      modal.$parent.showOk = props.showOk;
      // modal.$parent.iconType = props.icon;

      // switch (props.icon) {
      //     case 'info':
      //         modal.$parent.iconName = 'information-circled';
      //         break;
      //     case 'success':
      //         modal.$parent.iconName = 'checkmark-circled';
      //         break;
      //     case 'warning':
      //         modal.$parent.iconName = 'android-alert';
      //         break;
      //     case 'error':
      //         modal.$parent.iconName = 'close-circled';
      //         break;
      //     case 'confirm':
      //         modal.$parent.iconName = 'help-circled';
      //         break;
      // }

      if ("width" in props) {
        modal.$parent.width = props.width;
      }

      if ("title" in props) {
        modal.$parent.title = props.title;
      }

      if ("content" in props) {
        modal.$parent.content = props.content;
      }

      if ("okText" in props) {
        modal.$parent.okText = props.okText;
      }

      if ("cancelText" in props) {
        modal.$parent.cancelText = props.cancelText;
      }

      if ("onCancel" in props) {
        modal.$parent.onCancel = props.onCancel;
      }

      if ("okClass" in props) {
        modal.$parent.okClass = props.okClass;
      }

      if ("cancelClass" in props) {
        modal.$parent.cancelClass = props.cancelClass;
      }

      if ("onOk" in props) {
        modal.$parent.onOk = props.onOk;
      }

      // async for ok
      if ("loading" in props) {
        modal.$parent.loading = props.loading;
      }

      if ("scrollable" in props) {
        modal.$parent.scrollable = props.scrollable;
      }

      // notice when component destroy
      modal.$parent.onRemove = props.onRemove;

      modal.visible = true;
    },
    remove() {
      modal.visible = false;
      modal.$parent.buttonLoading = false;
      modal.$parent.remove();
    },
    component: modal,
  };
};

export default Modal;
