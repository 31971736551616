<style lang="scss">
.klk_poptip {
  display: inline-block;
  position: relative;
  .klk_poptip_rel {
    position: relative;
    cursor: pointer;
  }
  .klk_poptip_popper {
    position: absolute;
    top: 28px;
    padding: 10px;
    background: #fff;
    border-radius: 4px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.08);
    border: 1px solid #e0e0e0;
    z-index: 1;
    line-height: 1.5;
    // min-width: 250px;
    .klk_poptip_content {
      font-size: 14px;
      font-weight: 400;
      .klk_poptip_arrow {
        border-top: 1px solid #e5e5e5;
        position: absolute;
        border-left: 1px solid #e5e5e5;
        height: 10px;
        width: 10px;
        background: #fff;
        top: -6px;
        transform: rotate(45deg);
        left: 70px;
      }
    }
  }
}
</style>

<template>
  <div
    :class="classes"
    @mouseenter="handleMouseenter"
    @mouseleave="handleMouseleave"
    v-clickoutside="handleClose"
  >
    <div
      :class="[prefixCls + '_rel']"
      ref="reference"
      @click="handleClick"
      @mousedown="handleFocus(false)"
      @mouseup="handleBlur(false)"
    >
      <slot></slot>
    </div>
    <!-- <transition name="fade"> -->
    <div
      :class="popperClasses"
      :style="styles"
      ref="popper"
      v-show="visible"
      @mouseenter="handleMouseenter"
      @mouseleave="handleMouseleave"
    >
      <div :class="[prefixCls + '_content']">
        <div ref="up_arrow" :class="[prefixCls + '_arrow']"></div>
        <!-- <div :class="[prefixCls + '_inner']" v-if="confirm">
                        <div :class="[prefixCls + '_body']">
                            <i class="ivu-icon ivu-icon-help-circled"></i>
                            <div :class="[prefixCls + '-body-message']"><slot name="title">{{ title }}</slot></div>
                        </div>
                        <div :class="[prefixCls + '-footer']">
                            <i-button type="text" size="small" @click.native="cancel">{{ localeCancelText }}</i-button>
                            <i-button type="primary" size="small" @click.native="ok">{{ localeOkText }}</i-button>
                        </div>
                    </div> -->
        <div :class="[prefixCls + '_inner']" v-if="!confirm">
          <div :class="[prefixCls + '_title']" v-if="showTitle" ref="title">
            <slot name="title">
              <div :class="[prefixCls + '_title_inner']">{{ title }}</div>
            </slot>
          </div>
          <div :class="[prefixCls + '_body']">
            <div :class="[prefixCls + '_body_content']">
              <slot name="content">
                <div :class="[prefixCls + '_body_content_inner']">
                  {{ content }}
                </div>
              </slot>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- </transition> -->
  </div>
</template>

<script>
// import Popper from '../base/popper';
// import iButton from '../button/button.vue';
import clickoutside from "../../../directives/clickoutside";
// import TransferDom from '../../directives/transfer-dom';
import { oneOf } from "../../../utils/assist";
// import Locale from '../../mixins/locale';

const prefixCls = "klk_poptip";

export default {
  name: "KlkPoptip",
  // mixins: [ Popper, Locale ],
  // directives: { clickoutside,TransferDom },
  directives: {
    clickoutside,
  },
  // components: { iButton },
  props: {
    trigger: {
      validator(value) {
        return oneOf(value, ["click", "focus", "hover"]);
      },
      default: "click",
    },
    placement: {
      validator(value) {
        return oneOf(value, [
          "top",
          "top-start",
          "top-end",
          "bottom",
          "bottom-start",
          "bottom-end",
          "left",
          "left-start",
          "left-end",
          "right",
          "right-start",
          "right-end",
        ]);
      },
      default: "top",
    },
    title: {
      type: [String, Number],
    },
    content: {
      type: [String, Number],
      default: "",
    },
    width: {
      type: [String, Number],
    },
    confirm: {
      type: Boolean,
      default: false,
    },
    leftAverage: {
      type: Number,
    },
    okText: {
      type: String,
    },
    cancelText: {
      type: String,
    },
    // transfer: {
    //     type: Boolean,
    //     default: false
    // }
  },
  data() {
    return {
      prefixCls: prefixCls,
      showTitle: true,
      isInput: false,
      visible: this.value,
    };
  },
  computed: {
    classes() {
      return [
        `${prefixCls}`,
        `${prefixCls}_placement`, //easy for later manual adjustment by placement (not implemented auto popper position yet)
        {
          [`${prefixCls}_confirm`]: this.confirm,
        },
      ];
    },
    popperClasses() {
      return [
        `${prefixCls}_popper`,
        {
          [`${prefixCls}_confirm`]: this.transfer && this.confirm,
        },
      ];
    },
    styles() {
      let style = {};

      if (this.width) {
        style.width = `${this.width}px`;
      }
      return style;
    },
    localeOkText() {
      if (this.okText === undefined) {
        return this.tips.confirm;
      } else {
        return this.okText;
      }
    },
    localeCancelText() {
      if (this.cancelText === undefined) {
        return this.tips.cancel;
      } else {
        return this.cancelText;
      }
    },
  },
  methods: {
    calPosition() {
      //横向居中poptip
      var popper_dom = this.$refs.popper,
        reference_dom = this.$refs.reference,
        up_arrow_dom = this.$refs.up_arrow,
        coordinate_of_reference = reference_dom.getBoundingClientRect(),
        coordinate_of_popper = popper_dom.getBoundingClientRect(),
        coordinate_of_up_arrow = up_arrow_dom.getBoundingClientRect(),
        up_arrow_half_width =
          parseFloat(
            coordinate_of_up_arrow.right - coordinate_of_up_arrow.left
          ) / 2,
        reference_half_width =
          parseFloat(
            coordinate_of_reference.right - coordinate_of_reference.left
          ) / 2,
        popper_half_width =
          parseFloat(coordinate_of_popper.right - coordinate_of_popper.left) /
          (this.leftAverage || 2);
      up_arrow_dom.style.left = popper_half_width - up_arrow_half_width + "px"; //arrow宽度
      if (parseInt(popper_half_width) > parseInt(reference_half_width)) {
        popper_dom.style.left =
          "-" + (popper_half_width - reference_half_width) + "px";
      } else {
        popper_dom.style.left = reference_half_width - popper_half_width + "px";
      }
    },
    handleClick() {
      if (this.confirm) {
        this.visible = !this.visible;
        return true;
      }
      if (this.trigger !== "click") {
        return false;
      }
      this.visible = !this.visible;
      this.$nextTick(() => {
        this.calPosition();
      });
    },
    handleClose() {
      if (this.confirm) {
        this.visible = false;
        return true;
      }
      if (this.trigger !== "click") {
        return false;
      }
      this.visible = false;
    },
    handleFocus(fromInput = true) {
      if (
        this.trigger !== "focus" ||
        this.confirm ||
        (this.isInput && !fromInput)
      ) {
        return false;
      }
      this.visible = true;
      this.$nextTick(() => {
        this.calPosition();
      });
    },
    handleBlur(fromInput = true) {
      if (
        this.trigger !== "focus" ||
        this.confirm ||
        (this.isInput && !fromInput)
      ) {
        return false;
      }
      this.visible = false;
    },
    handleMouseenter() {
      if (this.trigger !== "hover" || this.confirm) {
        return false;
      }
      if (this.enterTimer) clearTimeout(this.enterTimer);
      this.enterTimer = setTimeout(() => {
        this.visible = true;
        this.$nextTick(() => {
          this.calPosition();
        });
      }, 100);
    },
    handleMouseleave() {
      if (this.trigger !== "hover" || this.confirm) {
        return false;
      }
      if (this.enterTimer) {
        clearTimeout(this.enterTimer);
        this.enterTimer = setTimeout(() => {
          this.visible = false;
        }, 100);
      }
    },
    cancel() {
      this.visible = false;
      this.$emit("on-cancel");
    },
    ok() {
      this.visible = false;
      this.$emit("on-ok");
    },
    getInputChildren() {
      const $input = this.$refs.reference.querySelectorAll("input");
      const $textarea = this.$refs.reference.querySelectorAll("textarea");
      let $children = null;

      if ($input.length) {
        $children = $input[0];
      } else if ($textarea.length) {
        $children = $textarea[0];
      }

      return $children;
    },
  },
  mounted() {
    if (!this.confirm) {
      this.showTitle = this.$slots.title !== undefined || this.title;
    }
    // if trigger and children is input or textarea,listen focus & blur event
    if (this.trigger === "focus") {
      this.$nextTick(() => {
        const $children = this.getInputChildren();
        if ($children) {
          this.isInput = true;
          $children.addEventListener("focus", this.handleFocus, false);
          $children.addEventListener("blur", this.handleBlur, false);
        }
      });
    }
    this.calPosition();
  },
  beforeDestroy() {
    const $children = this.getInputChildren();
    if ($children) {
      $children.removeEventListener("focus", this.handleFocus, false);
      $children.removeEventListener("blur", this.handleBlur, false);
    }
  },
};
</script>
