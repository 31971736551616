<template>
  <transition name="fade" @after-leave="handleAfterLeave">
    <div
      :class="wrapClasses"
      v-show="visible"
      @mouseenter="clearTimer"
      @mouseleave="startTimer"
    >
      <p class="klk_message_content">{{ content }}</p>
      <p @click="close" v-show="showClose" class="klk_message_close">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="16"
          height="16"
          viewBox="0 0 16 16"
        >
          <path
            :fill="closeIconColor"
            fill-rule="evenodd"
            d="M8 6.869L3.616 2.485l-1.131 1.13L6.869 8l-4.384 4.384 1.13 1.131L8 9.131l4.384 4.384 1.131-1.13L9.131 8l4.384-4.384-1.13-1.131L8 6.869z"
          />
        </svg>
      </p>
    </div>
  </transition>
</template>

<script>
const prefixCls = "klk_message";

export default {
  name: "KlkMessage",
  data() {
    return {
      visible: false, // 是否可见
      type: "info", // 弹框类型 可选：info | success | warning
      timer: null, // 定时器
      duration: 3000, // 持续时间
      content: "", // 弹框内容
      showClose: true, // 是否显示关闭按钮
      isCenter: true, // 是否居中
    };
  },
  computed: {
    wrapClasses() {
      return [
        `${prefixCls}_wrapper`,
        this.type ? `${prefixCls}_wrapper_${this.type}` : "",
        {
          is_center: this.isCenter,
        },
      ];
    },
    closeIconColor() {
      let map = {
        info: "#666666",
        success: "#2FC48D",
        warning: "#f56c6c",
      };
      return map[this.type];
    },
  },
  mounted() {
    this.startTimer();
  },
  methods: {
    startTimer() {
      this.timer = setTimeout(() => {
        if (this.visible) {
          this.close();
        }
      }, this.duration);
    },
    clearTimer() {
      clearTimeout(this.timer);
    },
    close() {
      this.visible = false;
    },
    handleAfterLeave() {
      this.$destroy(true);
      document.body.removeChild(this.$el);
    },
  },
};
</script>

<style lang="scss">
.klk_message_wrapper {
  width: 750px;
  line-height: 20px;
  padding: 10px 16px;
  position: fixed;
  top: 72px;
  left: 50%;
  margin-left: -375px;
  z-index: 9999;
  border-radius: 5px;

  &_info {
    color: #666;
    background: #f4f4f5;
    border: 1px solid #999;
  }

  &_success {
    color: $theme_color;
    background: #f0f9eb;
    border: 1px solid $theme_color;
  }

  &_warning {
    color: #f56c6c;
    background: #fef0f0;
    border: 1px solid #f56c6c;
  }

  &.is_center {
    text-align: center;
  }

  .klk_message_content {
    width: 700px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 4;
    /*! autoprefixer: ignore next */
    -webkit-box-orient: vertical;
    font-size: 14px;
  }

  .klk_message_close {
    position: absolute;
    right: 8px;
    top: 12px;
    cursor: pointer;
  }
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
  transform: translateY(-20px);
}

.fade-enter-active,
.fade-leave-active {
  transition: all 0.3s;
}

.fade-enter-to,
.fade-leave {
  opacity: 1;
  transform: translateY(0);
}
</style>
