<style lang="scss">
.klk_input_label {
  .required_mark {
    color: #ff5722;
    margin-right: 5px;
  }
}
</style>
<template>
  <div :class="classes" :style="itemStyles">
    <!-- <label :class="[prefixCls + '-label']" :for="labelFor" :style="labelStyles" v-if="label || $slots.label"><slot name="label">{{ label }}</slot></label> -->
    <!-- <div class="klk_input_label"><slot name="label">{{ label }}</slot></div> -->
    <div class="klk_input_label">
      <slot name="label"
        ><span class="required_mark" v-show="!!required">*</span
        >{{ label }}</slot
      >
    </div>
    <div :class="[prefixCls + '_content']" :style="contentStyles">
      <slot></slot>
      <!-- <transition name="fade"> -->
      <div :class="[prefixCls + '_error_tip']" v-if="validateState === 'error'">
        {{ validateMessage }}
      </div>
      <!-- </transition> -->
    </div>
  </div>
</template>
<script>
import AsyncValidator from "async-validator";
import Emitter from "../../../mixins/emitter";

const prefixCls = "klk_form_item";

function getPropByPath(obj, path, index) {
  var tempObj = obj;
  path = path.replace(/\[(\w+)\]/g, ".$1");
  path = path.replace(/^\./, "");

  var keyArr = path.split(".");
  var i = 0;
  if (Array.isArray(tempObj)) {
    tempObj = tempObj.filter((item, i) => {
      return +i === +index;
    })[0];
  }
  for (var len = keyArr.length; i < len - 1; ++i) {
    var key = keyArr[i];
    if (key in tempObj) {
      tempObj = tempObj[key];
    } else {
      throw new Error(
        "[form-item warn]: please transfer a valid prop path to form item!"
      );
    }
  }
  return {
    o: tempObj,
    k: keyArr[i],
    v: tempObj[keyArr[i]],
  };
}

export default {
  name: "KlkFormItem",
  mixins: [Emitter],
  props: {
    itemWidth: {
      type: String,
      default: "",
    },
    label: {
      type: String,
      default: "",
    },
    labelWidth: {
      type: Number,
    },
    prop: {
      type: String,
    },
    required: {
      type: Boolean,
      default: false,
    },
    rules: {
      type: [Object, Array],
    },
    error: {
      type: String,
    },
    validateStatus: {
      type: Boolean,
    },
    showMessage: {
      type: Boolean,
      default: true,
    },
    labelFor: {
      type: String,
    },
    index: {
      type: Number,
    },
  },
  data() {
    return {
      prefixCls: prefixCls,
      isRequired: false,
      validateState: "",
      validateMessage: "",
      validateDisabled: false,
      validator: {},
    };
  },
  watch: {
    error(val) {
      this.validateMessage = val;
      this.validateState = val === "" ? "" : "error";
    },
    validateStatus(val) {
      this.validateState = val;
    },
  },
  computed: {
    classes() {
      return [
        prefixCls,
        {
          //     [`${prefixCls}-required`]: this.required || this.isRequired,
          [`${prefixCls}_error`]: this.validateState === "error",
          //     [`${prefixCls}-validating`]: this.validateState === 'validating'
        },
      ];
    },
    form() {
      var parent = this.$parent;
      while (parent.$options.name !== "KlkForm") {
        parent = parent.$parent;
      }
      return parent;
    },
    fieldValue: {
      cache: false,
      get() {
        const model = this.form.model;
        if (!model || !this.prop) {
          return;
        }
        const index = this.index;
        var path = this.prop;
        if (path.indexOf(":") !== -1) {
          path = path.replace(/:/, ".");
        }
        return getPropByPath(model, path, index).v;
      },
    },
    labelStyles() {
      var style = {};
      const labelWidth = this.labelWidth || this.form.labelWidth;
      if (labelWidth) {
        style.width = `${labelWidth}px`;
      }
      return style;
    },
    itemStyles() {
      var style = {};
      const itemWidth = this.itemWidth;
      if (itemWidth) {
        style.width = `${itemWidth}`;
      }
      return style;
    },
    contentStyles() {
      var style = {};
      const labelWidth = this.labelWidth || this.form.labelWidth;
      if (labelWidth) {
        style.marginLeft = `${labelWidth}px`;
      }
      return style;
    },
  },
  methods: {
    getRules() {
      var formRules = this.form.rules;
      const selfRules = this.rules;

      formRules = formRules ? formRules[this.prop] : [];

      return [].concat(selfRules || formRules || []);
    },
    getFilteredRule(trigger) {
      const rules = this.getRules();

      return rules.filter(
        (rule) => !rule.trigger || rule.trigger.indexOf(trigger) !== -1
      );
    },
    validate(trigger, callback = function () {}) {
      const rules = this.getFilteredRule(trigger);
      if (!rules || rules.length === 0) {
        callback();
        return true;
      }

      this.validateState = "validating";

      var descriptor = {};
      descriptor[this.prop] = rules;

      const validator = new AsyncValidator(descriptor);
      var model = {};

      model[this.prop] = this.fieldValue;

      validator.validate(model, { firstFields: true }, (errors) => {
        this.validateState = !errors ? "success" : "error";
        this.validateMessage = errors ? errors[0].message : "";

        callback(this.validateMessage);
      });
      this.validateDisabled = false;
    },
    resetField() {
      this.validateState = "";
      this.validateMessage = "";

      var model = this.form.model;
      var index = this.form.index;
      var value = this.fieldValue;
      var path = this.prop;
      if (path.indexOf(":") !== -1) {
        path = path.replace(/:/, ".");
      }

      var prop = getPropByPath(model, path, index);

      if (Array.isArray(value)) {
        this.validateDisabled = true;
        prop.o[prop.k] = [].concat(this.initialValue);
      } else {
        this.validateDisabled = true;
        prop.o[prop.k] = this.initialValue;
      }
    },
    onFieldBlur() {
      this.validate("blur");
    },
    onFieldChange() {
      if (this.validateDisabled) {
        //prevent reset loop
        this.validateDisabled = false;
        return;
      }

      this.validate("change");
    },
  },
  mounted() {
    if (this.prop) {
      this.dispatch("KlkForm", "on-form-item-add", this);

      Object.defineProperty(this, "initialValue", {
        value: this.fieldValue,
      });

      var rules = this.getRules();

      if (rules.length) {
        rules.every((rule) => {
          if (rule.required) {
            this.isRequired = true;
            return false;
          }
        });
        this.$on("on-form-blur", this.onFieldBlur);
        this.$on("on-form-change", this.onFieldChange);
      }
    }
  },
  beforeDestroy() {
    this.dispatch("KlkForm", "on-form-item-remove", this);
  },
};
</script>
