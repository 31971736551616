<template>
  <div id="klk-cast-feedback" class="klk-cast-feedback" :style="style">
    <div v-if="!visible" class="klk-cast-icon" @click="openFeedbackFn">
      <img :src="genResourceFullPath('feedback/feedback.png')" alt="" />
      <span>{{ feedbackText }}</span>
    </div>

    <div v-if="remind" class="klk-cast-remind-feedback">
      {{ hearFromYou }}<i class="klk-feedback-triangle"></i>
    </div>
    <cast-context
      ref="cast"
      v-if="open"
      :width="width"
      :color="color"
      :question="question"
      :mainColor="mainColor"
      :btnRemindText="btnRemindText"
      :btnSubmitText="btnSubmitText"
      :showClose="showClose"
      :successText="successText"
      :position="position"
      :placeholder="placeholder"
      @submit="handleSubmit(arguments)"
      @handle-close="handleClose"
    >
    </cast-context>
  </div>
</template>
<script>
import castContext from "./cast-context.vue";
export default {
  name: "KlkCastFeedback",
  props: {
    feedbackText: {
      type: String,
      require: true,
    },
    visible: {
      type: Boolean,
      dafault: false,
    },
    width: {
      type: [Number, String],
      default: 360,
    },
    color: {
      type: String,
      default: "",
    },
    mainColor: {
      type: String,
      default: "",
    },
    question: {
      type: String,
      require: true,
    },
    btnRemindText: {
      type: String,
      default: "",
    },
    btnSubmitText: {
      type: String,
      default: "",
    },
    showClose: {
      type: Boolean,
      dafault: false,
    },
    successText: {
      type: String,
      default: "",
    },
    position: {
      type: String,
      dafault: "right",
    },
    placeholder: {
      type: String,
      default: "",
    },
    delayShowTime: {
      type: Number,
      default: 0,
    },
    delayFadeTime: {
      type: Number,
      default: 0,
    },
    hearFromYou: {
      type: String,
      default: "",
    },
  },
  components: {
    castContext,
  },
  data() {
    return {
      open: false,
      remind: false,
      timer1: null,
      timer2: null,
    };
  },
  computed: {
    style() {
      if (this.position === "static") {
        return {
          position: "static",
        };
      }
      return {};
    },
  },
  created() {
    this.open = this.visible;
    this.handleRemind();
  },
  methods: {
    handleClose(submit) {
      this.open = false;
      this.$emit("handle-close", submit);
    },
    openFeedbackFn() {
      this.open = true;
      this.remind = false;
      this.$emit("handle-open-feedback");
    },
    handleSubmit(args) {
      this.$emit("handle-submit", args[0], args[1]);
    },
    submitSucess() {
      this.$refs.cast.submitSuccess();
    },
    setRemindTime() {
      clearTimeout(this.timer1);
      this.timer1 = setTimeout(() => {
        this.remind = true;
      }, this.delayShowTime * 1000 * 60);
    },
    setFadeTime() {
      clearTimeout(this.timer2);
      const time = Number(this.delayShowTime) + Number(this.delayFadeTime);
      this.timer2 = setTimeout(() => {
        this.remind = false;
      }, time * 1000 * 60);
    },
    handleRemind() {
      if (this.delayShowTime) {
        this.setRemindTime();
      }
      if (this.delayFadeTime) {
        this.setFadeTime();
      }
    },
  },
};
</script>
<style lang="scss">
body {
  position: relative;
}
.klk-cast-feedback {
  z-index: 100;
  position: fixed;
  top: 500px;
  right: 0;
  border-radius: 2px;
  cursor: pointer;
  background-color: #fff;
  box-shadow: 0 1px 2px 0 #e5e5e5;
  .klk-cast-icon {
    width: 32px;
    cursor: pointer;
    display: flex;
    padding: 10px 0;
    flex-direction: column;
    align-items: center;

    span {
      font-size: 14px;
      writing-mode: vertical-rl;
    }
    img {
      width: 15px;
      height: 15px;
      margin-bottom: 6px;
    }
  }
  .klk-cast-remind-feedback {
    min-width: 240px;
    border-radius: 2px;
    text-align: center;
    padding: 8px;
    font-size: 14px;
    color: #000;
    position: absolute;
    bottom: 100%;
    transform: translateY(-12px);
    right: 20px;
    background-color: #fff8de;
    cursor: default;
    .klk-feedback-triangle {
      position: absolute;
      bottom: -16px;
      right: 10px;
      width: 0;
      height: 0;
      border: 8px solid transparent;
      border-top: 8px solid #fff8de;
    }
  }
}
</style>
