<template>
  <div id="klk-faq-feedback" class="klk-faq-feedback">
    <div class="klk-faq-feedback-wrapper">
      <span
        @click="handleHelpfulFn(1)"
        :class="isHelpful == 1 ? 'helpful' : ''"
      >
        <img :src="getCurrentIcon(1)" alt="" /> {{ helpfulText }}
      </span>
      <span
        @click="handleHelpfulFn(2)"
        :class="isHelpful == 2 ? 'not-helpful' : ''"
      >
        <img :src="getCurrentIcon(2)" alt="" /> {{ nothelpfulText }}
      </span>
    </div>
    <!-- 只有在unhelpful & unsubmit -->
    <template v-if="isHelpful === 2 && !isSubmit">
      <textarea
        class="klk-faq-feedback-context"
        v-model="feedbackContext"
        :placeholder="placeholder"
        :rows="4"
        :maxlength="250"
      ></textarea>
      <div class="klk-faq-feedback-footer">
        <span class="confirm" :style="style" @click="submitData">
          {{ btnSubmitText }}
        </span>
      </div>
    </template>
    <div class="klk-faq-feedback-back">
      <span v-if="isHelpful === 1">{{ helpfulFeedback }}</span>
      <span v-if="isHelpful === 2 && isSubmit">{{ nothelpfulFeedback }}</span>
    </div>
  </div>
</template>
<script>
export default {
  name: "KlkFaqFeedback",
  props: {
    helpfulText: {
      type: String,
      require: true,
    },
    nothelpfulText: {
      type: String,
      require: true,
    },
    mainColor: {
      type: String,
      default: "",
    },
    btnSubmitText: {
      type: String,
      require: true,
    },
    helpfulFeedback: {
      type: String,
      require: true,
    },
    nothelpfulFeedback: {
      type: String,
      require: true,
    },
    placeholder: {
      type: String,
      default: "",
    },
    value: {
      type: Number,
      default: -1,
    },
  },
  data() {
    return {
      isHelpful: -1,
      feedbackContext: "",
      isSubmit: false,
    };
  },
  computed: {
    style() {
      return {
        backgroundColor: this.mainColor,
      };
    },
  },
  watch: {
    value: function () {
      this.getInitStatus();
    },
  },
  created() {
    // init status
    this.getInitStatus();
  },
  methods: {
    getInitStatus() {
      this.isHelpful = this.value;
      this.isSubmit = this.isHelpful === 2 ? true : false;
    },
    getCurrentIcon(helpful) {
      return helpful === 1
        ? this.genResourceFullPath(`feedback/${
          this.isHelpful === 1 ? "" : "default-"
          }helpful.png`)
        : this.genResourceFullPath(`${
            this.isHelpful === 2 ? "" : "feedback/default-"
          }unhelpful.png`);
    },
    submitData() {
      this.isSubmit = true;
      this.$emit("handle-submit-content");
      this.handleSubmit();
    },

    handleHelpfulFn(value) {
      // only click once
      if ([1, 2].includes(this.isHelpful)) {
        return false;
      }

      this.isHelpful = value;
      if (value === 1) {
        this.$emit("handle-helpful");
        this.handleSubmit();
      }
      if (value === 2) {
        this.$emit("handle-nothelpful");
      }
    },
    handleSubmit() {
      this.$emit("handle-submit", this.isHelpful, this.feedbackContext);
    },
  },
};
</script>
<style lang="scss">
.klk-faq-feedback {
  width: 100%;
  background-color: #fafafa;
  margin: 12px 0;
  padding: 16px;
  color: #9b9b9b;
}
.klk-faq-feedback-wrapper {
  display: flex;
  span {
    font-size: 12px;
    color: #9b9b9b;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 5px 8px;
    margin-right: 10px;
    border: 1px solid #9b9b9b;
    border-radius: 4px;
    cursor: pointer;
    img {
      width: 15px;
      height: 15px;
      margin-right: 5px;
    }
  }
}
.klk-faq-feedback-context {
  margin-top: 10px;
  width: 100%;
  background-color: #fff;
  border-color: #fafafa;
  resize: none;
}
.klk-faq-feedback-footer {
  margin-top: 10px;
  span {
    font-size: 12px;
    line-height: 16px;
    padding: 8px 12px;
    color: #fff;
    border-radius: 2px;
    cursor: pointer;
  }
}
span.helpful {
  color: $theme_color;
  border-color: $theme_color;
}
span.not-helpful {
  color: #e64340;
  border-color: #e64340;
}
.klk-faq-feedback-back {
  font-size: 12px;
  span {
    display: inline-block;
    margin-top: 12px;
  }
}
</style>
