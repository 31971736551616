/**
 * 极验sdk支持77种语言，这里和我们界面语言做一个映射，兜底是英语en;
 */
const getGeetestLang = function () {
  const map = {
    "zh-CN": "zh-cn",
    "zh-HK": "zh-hk",
    "zh-TW": "zh-tw",
    ja: "ja",
    ko: "ko",
    id: "id",
    th: "th",
    vi: "vi",
    es: "es",
    "en-GB": "en-gb",
    fr: "fr",
    ru: "ru",
    en: "en",
    de: "de",
  };

  return map[window.KLK_LANG] || "en";
};

export { getGeetestLang };