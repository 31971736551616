<style lang="scss">
.klk_tabs {
  box-sizing: border-box;
  position: relative;
  .klk_tabs_nav {
    position: relative;
    margin-bottom: 20px;
    height: 64px;
    line-height: 64px;
    font-size: 0;
    .klk_tabs_bar {
      border-bottom: 1px solid #ebebeb;
      position: absolute;
      width: 100%;
      bottom: -2px;
    }
    .klk_tabs_tab {
      display: inline-block;
      padding: 0 16px;
      position: relative;
      box-sizing: border-box;
      cursor: pointer;
      font-size: 14px;
      font-weight: 500;
      color: #666;
      transition: color 0.3s ease-in-out;
      text-decoration: none;
      &.klk_tabs_tab_active {
        color: $theme_color;
        border-bottom: 3px solid $theme_color;
      }
      &:hover {
        color: $theme_color;
      }
    }
  }
  &.klk_tabs_text {
    .klk_tabs_nav {
      .klk_tabs_bar {
        border-bottom: none;
      }
      .klk_tabs_tab {
        &.klk_tabs_tab_active {
          border-bottom: none;
        }
      }
    }
  }
  &.klk_tabs_card {
    width: auto;
    .klk_tabs_nav {
      display: inline-block;
      border-radius: 2px;
      border: 1px solid #e0e0e0;
      height: 32px;
      line-height: 30px;
      .klk_tabs_bar {
        display: none;
      }
      .klk_tabs_tab {
        height: 30px;
        min-width: 120px;
        text-align: center;
        border-right: 1px solid #e0e0e0;
        &:last-child {
          border: none;
        }
        &.klk_tabs_tab_active {
          background: $theme_color;
          color: #fff;
          border-color: $theme_color;
        }
      }
    }
  }
  &.klk_tabs_line {
    .klk_tabs_tab {
      min-width: 150px;
      text-align: center;
      font-weight: 500;
      font-size: 16px;
      &:hover {
        color: #333333;
      }
      &.klk_tabs_tab_active {
        color: #333333;
      }
    }
  }

  &.klk_tabs_line-card {
    .klk_tabs_nav {
      height: 45px;
      line-height: 45px;
      .klk_tabs_bar {
        border-bottom: 1px solid #2FC48D;;
      }
      .klk_tabs_tab {
        &.klk_tabs_tab_active {
          border: 1px solid #2FC48D;
          border-bottom: 0;
        }
      }
    } 
  }
}
</style>

<template >
  <div :class="classes">
    <div ref="nav" :class="[prefixCls + '_nav']" :style="navStyle">
      <div class="klk_tabs_bar"></div>
      <div
        :class="tabCls(item)"
        v-for="(item, index) in navList"
        :key="index"
        @click="eventTargetType === 'click' && handleChange(index)"
        @mouseover="eventTargetType === 'hover' && handleChange(index)"
      >
        <nav-item :label="item.label" ></nav-item> 
      </div>
    </div>
  
    <div :class="contentClasses" v-show="showChildContent">
      <slot></slot>
    </div>
  </div>
</template>

<script>
import { oneOf } from "../../../utils/assist";
import _debounce from "lodash/debounce";

const NavItem = {
  props: {
    label: ""
  },
  render (h) {
    const _this = this;
    return h("div", _this.label)
  }
} 
const prefixCls = "klk_tabs";
export default {
  name: "KlkTabs",
  components: {
    NavItem
  },
  props: {
    value: {
      type: [String, Number],
    },
    type: {
      validator(value) {
        return oneOf(value, ["line", "card", "text", "line-card"]);
      },
      default: "line",
    },
    showChildContent: {
      type: Boolean,
      default: true,
    },
    navStyle: {},

    eventTargetType: {
      default: "click"
    }
  },
  data() {
    return {
      prefixCls: prefixCls,
      navList: [],
      activeKey: this.value,
    };
  },
  computed: {
    classes() {
      return [
        `${prefixCls}`,
        {
          [`${prefixCls}_card`]: this.type === "card",
          [`${prefixCls}_line`]: this.type === "line",
          [`${prefixCls}_text`]: this.type === "text",
          [`${prefixCls}_line-card`]: this.type === "line-card", 
        },
      ];
    },
    contentClasses() {
      return [`${prefixCls}_content`];
    },
  },
  methods: {
    tabCls(item) {
      return [
        `${prefixCls}_tab`,
        {
          // [`${prefixCls}-tab-disabled`]: item.disabled,
          [`${prefixCls}_tab_active`]: item.name === this.activeKey,
        },
      ];
    },
    getTabs() {
      return this.$children.filter(
        (item) => item.$options.name === "KlkTabPane"
      );
    },
    updateNav() {
      this.navList = [];
      this.getTabs().forEach((pane, index) => {
        if (!pane.currentName) pane.currentName = index;
        this.navList.push({
          label: pane.$slots.label || pane.label,
          name: pane.currentName,
          ...pane.$attrs
        });
        if (index === 0) {
          if (!this.activeKey) this.activeKey = pane.currentName || index;
        }
      });
      this.updateStatus();
    },
    handleChange(index) {
      const nav = this.navList[index];
      this.activeKey = nav.name;
      this.$emit("input", nav.name);
      this.$emit("on-click", nav.name);
    },
    updateStatus() {
      const tabs = this.getTabs();
      tabs.forEach((tab) => (tab.show = tab.currentName === this.activeKey));
    },
  },
  watch: {
    value(val) {
      this.activeKey = val;
    },
    activeKey() {
      this.updateStatus();
    },
  },
  mounted() {},
};
</script>
