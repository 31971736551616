
<div
  :class="classes"
  v-clickoutside="hideMenu"
  @keydown.down="handleFocus"
  :style="{ width: width, height }"
>
  <div
    :style="{ height }"
    :tabindex="filterable ? -1 : 0"
    class="select_action_wrapper"
    @click="toggleMenu"
    ref="select_action_wrapper"
  >
    <div
      :style="{ height }"
      :class="[prefixCls + '_tag_wrapper']"
      v-for="(item, index) in selectedMultiple"
      :key="index"
    >
      <span :class="[prefixCls + '_tag_text']">{{ item.label }}</span>
      <span class="remove_tag" @click.stop="removeTag(index)">x</span>
    </div>
    <div
      :style="{ height }"
      :class="[prefixCls + '_placeholder']"
      v-show="showPlaceholder && !filterable && !remote"
    >
      {{ placeholder }}
    </div>
    <div
      :style="{ lineHeight: height }"
      :class="[prefixCls + '_selected_label']"
      v-show="!showPlaceholder && !filterable && !remote"
    >
      {{ label }}
    </div>
    <input
      :style="{
        width: width,
        height,
      }"
      :disabled="disabled"
      ref="query_input"
      :class="[prefixCls + '_input']"
      @blur="handleBlur"
      @focus="inputFocus"
      v-on:input="handleQuery"
      v-model="query"
      type="text"
      :placeholder="placeholder"
      v-show="filterable || remote"
    />
    <klk-icon
      v-if="isFocus && searchMod"
      class="klook-icon-search"
      type="search"
    ></klk-icon>
    <klk-icon
      v-else
      class="klook-icon-arrow-down"
      type="arrow-down"
    ></klk-icon>
  </div>
  <transition name="zoom-in">
    <div :class="dropdownCls" v-show="dropdown_visible" ref="dropdown">
      <ul v-show="no_data" class="klk_option_no_data">
        <li></li>
      </ul>
      <ul v-show="remote_loading" class="klk_option_remote_loading">
        <li>loading...</li>
      </ul>
      <ul v-show="!no_data && !remote_loading">
        <slot></slot>
      </ul>
    </div>
  </transition>
</div>
