/**
 * this store module is for Google Tag Manager(GTM) events
 */

// initial state
var state = {
  login_event_category: "",
  signup_event_category: "",
};

// getters
var getters = {};

// actions
var actions = {};

// mutations
var mutations = {
  change_login_event_category(state, login_event_category) {
    state.login_event_category = login_event_category;
  },
  change_signup_event_category(state, signup_event_category) {
    state.signup_event_category = signup_event_category;
  },
};

export { state, getters, actions, mutations };
