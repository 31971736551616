import klkCastFeedback from "./src/cast.vue";
import KlkFaqFeedback from "./src/faq.vue";

const Components = [klkCastFeedback, KlkFaqFeedback];

const feedbackPlugin = {
  install(Vue /* , opt */) {
    Components.forEach((component) => {
      Vue.component(component.name, component);
    });
  },
};
export default feedbackPlugin;
