
<div :class="[prefixCls]">
  <div
    :class="classes"
    @click="handleClick"
    @drop.prevent="onDrop"
    @dragover.prevent="dragOver = true"
    @dragleave.prevent="dragOver = false"
  >
    <input
      ref="input"
      type="file"
      :class="[prefixCls + '_input']"
      @change="handleChange"
      :multiple="multiple"
      :disabled="disabled"
      :accept="accept"
    />
    <slot></slot>
  </div>
  <slot name="tip"></slot>
  <upload-list
    v-if="showUploadList"
    :files="fileList"
    @on-file-remove="handleRemove"
    @on-file-preview="handlePreview"
  ></upload-list>
</div>
