export const RENDER_CONFIG = [
  {
    id: -1,
    name: "affiliate", // affiliate 不需要iframe info 中的信息都为特殊值, 暂定 -1
    render_map: [
      {
        id: -1,
        name: "main",
        info: {
          type: [
            {
              id: -1,
              name: "main",
            },
          ],
          iframeScriptVersion: -1,
          insClassName: "",
        },
      },
      {
        id: -2,
        name: "ads_demo_page",

        info: {
          type: [{ id: -2, name: "ads_demo_page" }],
          iframeScriptVersion: -1,
          insClassName: "",
        },
      },
      {
        id: -3,
        name: "product_listing_page",

        info: {
          type: [{ id: -3, name: "product_listing_page" }],
          iframeScriptVersion: -1,
          insClassName: "",
        },
      },
    ],
  },
  {
    id: 2,
    name: "things-to-do",
    render_map: [
      {
        id: 1,
        name: "dynamic_widget", // 渲染模版名称
        i18Key: "ADS_DEMO_PAGE_dynamic_widget",
        info: {
          type: [
            {
              id: 1,
              name: "dynamic_widget",
            },
            { id: 2, name: "static_widget" },
          ], // dynamic_widget 和 static widget 渲染用的页面一致
          iframeScriptVersion: 3, // iframe 脚本版本
          insClassName: "things_to_do_dynamic_widget", // ins 标签 class名称，iframe脚本中会用到
        },
      },
      {
        id: 2,
        name: "auto_dynamic_widget",
        i18Key: "MULTIPLE_auto_dynamic_widget",
        info: {
          type: [{ id: 1, name: "auto_dynamic_widget" }], // dynamic_widget 和 static widget 渲染用的页面一致
          iframeScriptVersion: 3,
          insClassName: "klookaff_auto_dynamic_widget",
        },
      },
    ],
  },
  {
    id: 3,
    name: "hotel",
    render_map: [
      {
        id: 1,
        name: "hotel_dynamic_widget", // 用于打包配置
        i18Key: "dynamic_widget_hotel",
        info: {
          type: [
            { id: 1, name: "hotel_dynamic_widget" },
            { id: 2, name: "hotel_static_widget" },
          ],
          iframeScriptVersion: 3,
          insClassName: "klook_hotel_dynamic_widget",
        },
      },
      {
        id: 2,
        name: "auto_hotel_dynamic_widget",
        i18Key: "dynamic_widget_auto_hotel",
        info: {
          type: [{ id: 1, name: "auto_hotel_dynamic_widget" }],
          iframeScriptVersion: 3,
          insClassName: "klook_auto_hotel_dynamic_widget",
        },
      },
    ],
  },
  {
    // 用于共用广告, 配置上不区分, 脚本也不区分
    id: 4,
    name: "common",
    render_map: [
      {
        id: 3,
        name: "search_vertical",
        i18Key: "MULTIPLE_search_box",
        info: {
          type: [{ id: 1, name: "search_vertical" }],
          iframeScriptVersion: 3,
          insClassName: "klook_aff_search_box",
        },
      },
      {
        // static_banner
        id: 4,
        name: "static_banner",
        i18Key: "MULTIPLE_static_banners",
        info: {
          type: [
            {
              id: 1,
              name: "banner",
            },
          ],
          iframeScriptVersion: 3,
          insClassName: "static_banner",
        },
      },
      {
        // mul_act_banner
        id: 5,
        name: "mul_act_banner",
        i18Key: "MULTIPLE_activity_banners",
        info: {
          type: [
            {
              id: 1,
              name: "mul_act",
            },
          ],
          iframeScriptVersion: 3,
          insClassName: "mul_act_banner",
        },
      },
      {
        // single_act_banner
        id: 6,
        name: "single_act_banner",
        i18Key: "MULTIPLE_activity_banners",
        info: {
          type: [
            {
              id: 1,
              name: "single_act",
            },
          ],
          iframeScriptVersion: 3,
          insClassName: "single_act_banner",
        },
      },
    ],
  },
];
