<template>
  <div :class="prop_class">
    <transition :name="transition_names[0]">
      <div class="klk_modal_mask" v-cloak v-show="visible"></div>
    </transition>
    <transition :name="transition_names[1]">
      <div
        class="klk_dialog_container"
        v-show="visible"
        @click="hideDialogFromMask()"
      >
        <div class="klk_dialog" :style="mainStyles">
          <div
            @click="closeDialogFromIcon"
            v-show="close_icon"
            class="klk_dialog_close"
          >
            <svg class="svg_wrapper">
              <use xlink:href="#close"></use>
            </svg>
          </div>
          <slot></slot>
        </div>
      </div>
    </transition>
  </div>
</template>
<script type="text/babel">
import _ from "underscore";
export default {
  name: "KlkDialog",
  props: {
    value: {
      //to support v-modal
      type: Boolean,
      default: () => false,
    },
    show: {
      // deprecate in the future, use v-modal instead
      type: Boolean,
      default: () => false,
    },
    close_icon: {
      type: Boolean,
      default: () => false,
    },
    lock_scroll: {
      type: Boolean,
      default: () => true,
    },
    prop_class: {
      type: String,
      default: () => "",
    },
    transition_names: {
      type: Array,
      default() {
        return ["fade", "ease"];
      },
    },
    mask_closable: {
      type: Boolean,
      default: () => false,
    },
    width: {
      type: [Number, String],
      default: 420,
    },
    top: {
      type: String,
    },
    styles: {
      type: Object,
    },
  },
  data() {
    return {
      visible: this.value,
    };
  },
  watch: {
    value(val) {
      this.dialogControllerChanged(val);
    },
    show(val) {
      this.dialogControllerChanged(val);
    },
    visible(newVal) {
      if (newVal) {
        this.open();
      } else {
        this.close();
      }
    },
  },
  created() {
    document.body.style.overflow = this.visible ? "hidden" : ""; //fix bug : set key prop in dialog lead to can not scroll
  },
  beforeDestroy() {
    document.body.style.overflow = "";
  },
  computed: {
    mainStyles() {
      var style = {};
      var width = parseInt(this.width); //(width: auto) => NaN
      var styleWidth = {
        width: width ? `${width}px` : this.width,
        top: this.top,
      };
      var customStyle = this.styles ? this.styles : {};

      _.extend(style, styleWidth, customStyle);

      return style;
    },
  },
  methods: {
    dialogControllerChanged(val) {
      this.visible = val;
      if (this.lock_scroll) {
        document.body.style.overflow = val ? "hidden" : "";
      }
    },
    closeDialogFromIcon() {
      this.$emit("close_icon_fn");
      this.close();
    },

    hideDialogFromMask() {
      this.$emit("close_dialog_on_mask_click");
      if (this.mask_closable) {
        this.close();
      }
    },
    open() {
      this.visible = true;
      this.$emit("input", true);
      this.$emit("on-open");
      this.$emit("open"); //compatible width old code
    },
    close() {
      this.visible = false;
      this.$emit("input", false);
      this.$emit("on-close");
      this.$emit("close"); //compatible width old code
    },
  },
};
</script>

<style lang="scss">
.klk_dialog_container {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 115;
  overflow: auto;
  text-align: center;
  padding: 50px 0;
  border-radius: 2px;

  &:before {
    content: "";
    display: inline-block;
    height: 100%;
    width: 0;
    vertical-align: middle;
  }
}

.klk_dialog {
  display: inline-block;
  vertical-align: middle;
  box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.24), 0 0 4px 0 rgba(0, 0, 0, 0.12);
  background: #fff;
  border-radius: 2px;
  text-align: left;
  width: 420px;
  // padding-top: 16px;
  position: relative;

  textarea {
    padding: 10px 12px;
    font-size: 16px;
  }

  .klk_modal_alert {
    padding: 24px;
    .klk_modal_alert_head {
      padding-bottom: 12px;
      .klk_modal_alert_head_title {
        display: inline-block;
        font-size: 16px;
        color: #333;
        font-weight: 500;
      }
    }
    .klk_modal_alert_body {
      line-height: 24px;
      padding-bottom: 24px;
      font-size: 14px;
      color: #666;
    }
    .klk_modal_alert_footer {
      display: flex;
      justify-content: flex-end;
      button {
        line-height: 1.4;
        padding: 7px 32px;
        font-size: 14px;
        text-align: center;
      }
    }
  }

  .klk_dialog_close {
    position: absolute;
    right: 8px;
    top: 8px;
    cursor: pointer;
    .svg_wrapper {
      width: 16px;
      height: 16px;
    }
  }
  .dialog_frame {
    padding: 30px 70px;
  }
  .dialog_frame3 {
    padding: 30px 70px 0;
  }
  .dialog_frame4 {
    padding: 0 70px;
  }
  .dialog_frame2 {
    padding: 0 48px 24px;
  }
  .klk_dialog_title {
    line-height: 54px;
    padding: 16px 40px 0;
    font-size: 24px;
    color: #333333;
  }
  .klk_dialog_body {
    margin: 20px 40px;
  }
  .klk_dialog_footer {
    text-align: center;
    margin-bottom: 30px;
  }
}
.klk_modal_mask {
  position: fixed;
  z-index: 111;
  background-color: rgba(0, 0, 0, 0.8);
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}
</style>
