<style lang="scss">
.klk_input_wrapper {
  .klk_input_group_prepend,
  .klk_input_group_append {
    border: 1px solid #e0e0e0;
    border-radius: 2px;
    display: table-cell;
    padding: 0 12px;
    vertical-align: middle;
    text-align: center;
  }
  .klk_input_group_prepend {
    border-right: 0;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
  .klk_input_group_append {
    border-left: 0;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
  &.klk_input_group_with_prepend {
    input {
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
    }
  }
  &.klk_input_group_with_append {
    input {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
    }
  }
  &.klk_input_group {
    display: table;
  }
  textarea {
    font-size: 16px;
    padding: 12px;
    border: 1px solid #e0e0e0;
  }
  &.klk_input_wrapper_small {
    input {
      height: 32px;
    }
  }
}

.klk_checkbox_wrapper {
  display: inline-block;
  margin-right: 10px;
  cursor: pointer;
  .klk_checkbox_wrapper_disabled {
    color: #ccc;
  }
}
input,
select {
  background: #fff;
  font-size: 16px;
  width: 100%;
  color: #333333;
  padding: 0 12px;
  height: 40px;
  border: 1px solid #e0e0e0;
}
.klk_form {
  input[type="radio"] {
    width: auto;
  }

  &.klk_form_flex_row {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    input[type="text"],
    select,
    .klk_select {
      width: 220px;
    }
    .klk_form_item {
      width: auto;
      margin-right: 20px;
    }
  }
  .klk_radio_wrapper {
    display: flex;
    align-items: center;
    cursor: pointer;
    margin-right: 10px;
    .klk_radio_label_wrapper {
      margin: 0 10px;
      color: #333;
      font-size: 14px;
    }
  }
  .klk_radio_group {
    display: flex;
  }

  .klk_form_item {
    width: 100%;
    margin-top: 16px;
    position: relative;
    word-wrap: break-word;
    white-space: normal;
    display: inline-block;
    .klk_input_label {
      margin-bottom: 4px;
      font-size: 14px;
      color: #333;
    }
    .klk_form_item_content {
      margin: 8px 0;
    }

    .klk_checkbox_wrapper {
      cursor: pointer;
      margin-right: 10px;
      input[type="checkbox"] {
        width: auto;
      }
      .klk_checkbox {
        display: inline-block;
        vertical-align: middle;
        white-space: nowrap;
        cursor: pointer;
        outline: 0;
        line-height: 1;
        position: relative;
        margin: 6px 0;
      }
      .klk_checkbox_inner {
        display: inline-block;
        width: 14px;
        height: 14px;
        position: relative;
        top: 0;
        left: 0;
        border: 1px solid #dddee1;
        border-radius: 2px;
        background-color: #fff;
        transition: border-color 0.2s ease-in-out,
          background-color 0.2s ease-in-out;
        &::after {
          content: "";
          display: table;
          width: 4px;
          height: 8px;
          position: absolute;
          top: -1px;
          left: 3px;
          border: 2px solid #fff;
          border-top: 0;
          border-left: 0;
          transform: rotate(45deg) scale(1);
          transition: all 0.2s ease-in-out;
        }
      }
      &.klk_checkbox_wrapper_checked {
        .klk_checkbox_inner {
          border-color: $theme_color;
          background-color: $theme_color;
        }
      }
      .klk_checkbox_input {
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        z-index: 1;
        cursor: pointer;
        opacity: 0;
      }
    }
  }
  .klk_form_item_error {
    input,
    select,
    textarea {
      border: 1px solid #ff5722;
    }
    .klk_select {
      .select_action_wrapper {
        border-color: #ff5722;
      }
    }
    .klk_form_item_error_tip {
      position: absolute;
      padding-top: 2px;
      font-size: 14px;
      height: 16px;
      color: #ff5722;
    }
  }
}
</style>

<template>
  <div :class="wrapClasses">
    <template v-if="type !== 'textarea'">
      <div
        :class="[prefixCls + '_group_prepend']"
        v-if="prepend"
        v-show="slotReady"
      >
        <slot name="prepend"></slot>
      </div>
      <!-- <i class="ivu-icon" :class="['ivu-icon-' + icon, prefixCls + '-icon', prefixCls + '-icon-normal']" v-if="icon" @click="handleIconClick"></i> -->
      <!-- <transition name="fade">
                <i class="ivu-icon ivu-icon-load-c ivu-load-loop" :class="[prefixCls + '-icon', prefixCls + '-icon-validate']" v-if="!icon"></i>
            </transition> -->
      <input
        :id="elementId"
        :autocomplete="autocomplete"
        ref="input"
        :type="type"
        :class="inputClasses"
        :placeholder="placeholder"
        :disabled="disabled"
        :maxlength="maxlength"
        :readonly="readonly"
        :name="name"
        :value="currentValue"
        :number="number"
        :autofocus="autofocus"
        :style="{
          width: width,
        }"
        @keyup.enter="handleEnter"
        @keyup="handleKeyup"
        @keypress="handleKeypress"
        @keydown="handleKeydown"
        @focus="handleFocus"
        @blur="handleBlur"
        @input="handleInput"
        @change="handleChange"
      />
      <div
        :class="[prefixCls + '_group_append']"
        v-if="append"
        v-show="slotReady"
      >
        <slot name="append"></slot>
      </div>
    </template>
    <textarea
      v-else
      :id="elementId"
      :autocomplete="autocomplete"
      ref="textarea"
      :class="textareaClasses"
      :style="textareaStyles"
      :placeholder="placeholder"
      :disabled="disabled"
      :rows="rows"
      :cols="cols"
      :maxlength="maxlength"
      :readonly="readonly"
      :name="name"
      :value="currentValue"
      :autofocus="autofocus"
      @keyup.enter="handleEnter"
      @keyup="handleKeyup"
      @keypress="handleKeypress"
      @keydown="handleKeydown"
      @focus="handleFocus"
      @blur="handleBlur"
      @input="handleInput"
    >
    </textarea>
  </div>
</template>
<script>
// import { oneOf, findComponentUpward } from '../../utils/assist';
// import calcTextareaHeight from '../../utils/calcTextareaHeight';
import Emitter from "../../../mixins/emitter";

const prefixCls = "klk_input";

export default {
  name: "KlkInput",
  mixins: [Emitter],
  props: {
    type: {
      validator(value) {
        return ["text", "textarea", "password"].indexOf(value) != -1;
      },
      default: "text",
    },
    value: {
      type: [String, Number],
      default: "",
    },
    size: {
      validator(value) {
        return ["small", "large", "default"].indexOf(value) != -1;
      },
    },
    /* style: {
      type: Object,
      default: () => {},
    }, */
    placeholder: {
      type: String,
      default: "",
    },
    maxlength: {
      type: [Number, String],
      default: 200,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    icon: String,
    autosize: {
      type: [Boolean, Object],
      default: false,
    },
    rows: {
      type: [Number, String],
      default: 2,
    },
    cols: {
      type: [Number, String],
      default: 6,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    width: {
      type: String,
    },
    name: {
      type: String,
    },
    number: {
      type: Boolean,
      default: false,
    },
    autofocus: {
      type: Boolean,
      default: false,
    },
    autocomplete: {
      validator(value) {
        return ["on", "off"].indexOf(value) != -1;
      },
      default: "off",
    },
    elementId: {
      type: String,
    },
  },
  data() {
    return {
      currentValue: this.value,
      prefixCls: prefixCls,
      prepend: true,
      append: true,
      slotReady: false,
      textareaStyles: {},
    };
  },
  computed: {
    wrapClasses() {
      return [
        `${prefixCls}_wrapper`,
        {
          [`${prefixCls}_wrapper_${this.size}`]: !!this.size,
          //     [`${prefixCls}-type`]: this.type,
          [`${prefixCls}_group`]: this.prepend || this.append,
          //     [`${prefixCls}-group-${this.size}`]: (this.prepend || this.append) && !!this.size,
          [`${prefixCls}_group_with_prepend`]: this.prepend,
          [`${prefixCls}_group_with_append`]: this.append,
          //     [`${prefixCls}-hide-icon`]: this.append  // #554
        },
      ];
    },
    inputClasses() {
      return [
        `${prefixCls}`,
        {
          // [`${prefixCls}_${this.size}`]: !!this.size,
          [`${prefixCls}_disabled`]: this.disabled,
        },
      ];
    },
    textareaClasses() {
      return [
        `${prefixCls}`,
        {
          [`${prefixCls}_disabled`]: this.disabled,
        },
      ];
    },
  },
  methods: {
    handleEnter(event) {
      this.$emit("on-enter", event);
    },
    handleKeydown(event) {
      this.$emit("on-keydown", event);
    },
    handleKeypress(event) {
      this.$emit("on-keypress", event);
    },
    handleKeyup(event) {
      this.$emit("on-keyup", event);
    },
    // handleIconClick (event) {
    //     this.$emit('on-click', event);
    // },
    handleFocus(event) {
      this.$emit("on-focus", event);
    },
    handleBlur(event) {
      this.$emit("on-blur", event);
      // if (!findComponentUpward(this, ['DatePicker', 'TimePicker', 'Cascader', 'Search'])) {
      this.dispatch("KlkFormItem", "on-form-blur", this.currentValue);
      // }
    },
    handleInput(event) {
      var value = event.target.value;
      if (this.number)
        value = Number.isNaN(Number(value)) ? value : Number(value);
      this.$emit("input", value);
      this.setCurrentValue(value);
      this.$emit("on-change", event);
    },
    handleChange(event) {
      this.$emit("on-input-change", event);
    },
    setCurrentValue(value) {
      if (value === this.currentValue) return;
      // this.$nextTick(() => {
      //     this.resizeTextarea();
      // });
      this.currentValue = value;
      // if (!findComponentUpward(this, ['DatePicker', 'TimePicker', 'Cascader', 'Search'])) {
      this.dispatch("KlkFormItem", "on-form-change", value);
      // }
    },
    // resizeTextarea () {
    //     const autosize = this.autosize;
    //     if (!autosize || this.type !== 'textarea') {
    //         return false;
    //     }
    //
    //     const minRows = autosize.minRows;
    //     const maxRows = autosize.maxRows;
    //
    //     this.textareaStyles = calcTextareaHeight(this.$refs.textarea, minRows, maxRows);
    // },
    focus() {
      if (this.type === "textarea") {
        this.$refs.textarea.focus();
      } else {
        this.$refs.input.focus();
      }
    },
    blur() {
      if (this.type === "textarea") {
        this.$refs.textarea.blur();
      } else {
        this.$refs.input.blur();
      }
    },
  },
  watch: {
    value(val) {
      this.setCurrentValue(val);
    },
  },
  mounted() {
    if (this.type !== "textarea") {
      this.prepend = this.$slots.prepend !== undefined;
      this.append = this.$slots.append !== undefined;
    } else {
      this.prepend = false;
      this.append = false;
    }
    this.slotReady = true;
    // this.resizeTextarea();
  },
};
</script>
