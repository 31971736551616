
<div class="common_header" v-if="should_show_common_header" :data-spm-page="`${getPageName}?trg=manual`">
  <div class="logo_title_wrapper">
    <img
      :src="headerLogo"
      class="logo"
      :class="{
        'kreaotr_logo' : isKreator
      }"
      alt=""
      @click="go_home"
    />
  </div>
  <div class="tabs_wrapper" v-if="user_info.status !== 0 && !isSignUpPage" :data-spm-module="'Header?trg=manual'">
    <klk-tabs
      type="text"
      @on-click="clickNav"
      v-model="router_name"
      style="display: inline-block"
    >
      <klk-tab-pane
        v-for="(router_info, name) in navRouters"
        :key="name"
        :data-spm-item="ihtNameMap[name]"
        :class="ihtNameMap[name]"
        :label="router_info.title"
        :name="name"
      ></klk-tab-pane>
    </klk-tabs>
    <div
      class="klk_tabs_tab influencer_program"
      :data-spm-item="'InfluencerProgram'"
      @click="go_influencer_program"
      v-show="!user_info.id"
    >
      {{ $t("COMMON_HEADER_influencer_program") }}
    </div>
  </div>
  <div class="header_right_wrapper" :data-spm-module="'Header'">
    <div class="notifications_wrapper" v-show="user_info.id && !isSignUpPage">
      <klk-poptip trigger="hover" class="poptip_wrapper">
        <div class="poptip_info" @click="jumpUrl">
          <klk-icon
            :data-spm-item="'Notification'"
            class="icon notification_icon"
            :type="total > 0 ? 'notifications_unread' : 'notifications'"
            size="20"
          ></klk-icon>
        </div>
        <div slot="content" class="poptip_content">
          <span :data-spm-item="'Notification'">{{ $t("27383") }}</span>
        </div>
      </klk-poptip>
    </div>
    <div class="lang_info_wrapper" :class="{ mr_0: isSignUpPage }">
      <klk-poptip trigger="hover" class="poptip_wrapper">
        <div class="poptip_info header_right_text">
          {{ lang_name }}
          <klk-icon
            class="klook-icon-arrow-down"
            type="arrow-down"
          ></klk-icon>
        </div>
        <div slot="content" class="poptip_content">
          <ul class="ul_content lang">
            <li
              :data-spm-item="`Lang?ext=${JSON.stringify({lang: item.value})}`"
              v-for="(item, index) in klk_lang_options"
              class="li_item"
              :key="index"
              :class="{ active: klk_lang === item.value }"
              @click="change_lang(item.value)"
            >
              {{ item.title }}
            </li>
          </ul>
        </div>
      </klk-poptip>
    </div>

    <div
      :data-spm-item="'SignUp'"
      class="sign_up_wrapper"
      v-show="!user_info.id && !isSignUpPage"
      @click="sign_up"
    >
      <span class="header_right_text">{{ $t("MULTIPLE_sign_up") }}</span>
    </div>
    <div
      class="log_in_wrapper"
      ref="login"
      :data-spm-item="'Login'"
      v-show="!user_info.id && !isSignUpPage"
      @click="log_in"
    >
      <span class="header_right_text">{{ $t("MULTIPLE_log_in") }}</span>
    </div>
    <div class="user_info_wrapper" v-show="user_info.id && !isSignUpPage">
      <klk-poptip trigger="hover" class="poptip_wrapper">
        <div class="poptip_info">
          <span class="header_right_text" v-if="!user_info.firstname">{{
              user_info.login_email
            }}</span>
          <span class="header_right_text" v-else
          >{{ user_info.firstname }} {{ user_info.lastname }}</span
          >
          <klk-icon
            class="klook-icon-arrow-down"
            type="arrow-down"
          ></klk-icon>
        </div>
        <div slot="content" class="poptip_content">
          <ul class="ul_content">
            <li
              :data-spm-item="'MyInfo'"
              class="li_item"
              @click="goto('my_account')"
              v-if="user_info.status === 1"
            >
              <klk-icon
                class="icon klook-icon-my-info"
                type="my-info"
              ></klk-icon>
              <span class="text">{{ $t("MULTIPLE_my_info") }}</span>
            </li>
            <li
              class="li_item"
              @click="logout"
              :data-spm-item="'Logout'"
            >
              <klk-icon
                class="icon klook-icon-logout"
                type="logout"
              ></klk-icon>
              <span class="text"> {{ $t("COMMON_HEADER_log_out") }}</span>
            </li>
          </ul>
        </div>
      </klk-poptip>
    </div>
  </div>
  <common-join-now-dialog-content v-if="!user_info.id">
  </common-join-now-dialog-content>
</div>
