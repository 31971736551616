
<div>
  <div class="forgot_form" :data-spm-module="'ForgotPassword'">
    <div class="klk_dialog_title">
      {{ $t("FORGOT_FORM_reset_password") }}
    </div>
    <div class="klk_dialog_body">
      <klk-form :rules="formRules" :model="form_data" ref="ref_form_forgot">
        <klk-form-item prop="email">
          <klk-input
            :placeholder="$t('FORGOT_FORM_please_enter_an_email_address')"
            v-on:input="forgot_error = ''"
            v-model="form_data.email"
          ></klk-input>
        </klk-form-item>
      </klk-form>
    </div>
    <div class="klk_dialog_prompt">
      {{ $t("FORGOT_FORM_reset_password_desc") }}
    </div>
    <div class="klk_dialog_prompt reset_text">
      <a
        :data-spm-item="'GetHelp'"
        target="_blank"
        href="https://www.klook.com/tetris/promo/aff-login-issue/"
      >
        {{ $t("99655") }}
      </a>
    </div>
    <div class="klk_dialog_footer">
      <p class="klk_error_tips" v-show="forgot_error !== ''">
        {{ forgot_error }}
      </p>
      <button
        :data-spm-item="'Submit'"
        type="button"
        class="theme_btn_base btn_full"
        name="button"
        @click="verify_forgot"
        :disabled="disabled_submit_btn"
      >
        {{ $t("MULTIPLE_submit") }}
      </button>
    </div>
  </div>
</div>

