// initial state
import { urlObj } from "@/comm/common_data";
import _extend from "lodash/extend";

var state = {
  prefer_currency: "",
  prefer_currency_history: [],
  currency_list: [],
};

// getters
var getters = {};

// actions
var actions = {
  loadUserInfo({ commit }) {
    return new Promise((resolve, reject) => {
      klook.ajaxGet(urlObj.get_user_detail_masked, (resp) => {
        if (resp.success) {
          let user_info = (resp.result && resp.result.user_info) || {};
          commit("set_prefer_currency", user_info.bill_currency);
          resolve(resp);
        } else {
          reject(resp);
          console.error(resp);
        }
      });
    });
  },
  loadBillingCurrencyHistory({ commit }) {
    return new Promise((resolve, reject) => {
      klook.ajaxGet(urlObj.payment_currency, (resp) => {
        if (resp.success) {
          let result = (resp && resp.result) || {};
          let { currency_history } = result;
          commit("set_prefer_currency_history", currency_history);
          if (currency_history.length) {
            let history = currency_history.map((list) => list.new_currency);
            commit("set_prefer_currency", currency_history[0]["new_currency"]);
            commit("set_currency_list", [...new Set(history)]);
          }
          resolve();
        } else {
          reject(resp);
        }
      });
    });
  },
};

// mutations
var mutations = {
  set_prefer_currency(state, prefer_currency) {
    state.prefer_currency = prefer_currency;
  },
  set_prefer_currency_history(state, history) {
    state.prefer_currency_history = history;
  },
  set_currency_list(state, history) {
    state.currency_list = history;
  },
};

export { state, getters, actions, mutations };
