
<div :class="className" v-show="show">
  <span class="icon">
    <!-- <i :class="type_icon"></i> -->
    <svg class="svg_wrapper alert_mark">
      <use
        v-if="type_icon == 'klook-icon-exclamation-mark'"
        xlink:href="#exclamation-mark"
      ></use>
      <use
        v-if="type_icon == 'klook-icon-check-mark'"
        xlink:href="#check-mark"
      ></use>
    </svg>
  </span>
  <span v-show="title" v-html="title"></span>
  <slot></slot>
</div>
