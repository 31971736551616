<template >
  <div :class="prefixCls" v-show="show"><slot></slot></div>
</template>
<script>
const prefixCls = "klk_tabs_tabpane";

export default {
  name: "KlkTabPane",
  props: {
    name: {
      type: String,
    },
    label: {
      type: [String, Function],
      default: "",
    },
  },
  data() {
    return {
      prefixCls: prefixCls,
      show: true,
      currentName: this.name,
    };
  },
  methods: {
    updateNav() {
      this.$parent.updateNav();
    },
  },
  watch: {
    name(val) {
      this.currentName = val;
      this.updateNav();
    },
    label() {
      this.updateNav();
    },
    disabled() {
      this.updateNav();
    },
  },
  mounted() {
    this.updateNav();
  },
};
</script>
