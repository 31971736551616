
<div class="confirm_user_info">
  <klk-dialog v-model="dialog_show" width="550px">
    <div class="klk_dialog_title">
      {{ $t("116183") }}
    </div>
    <div class="klk_dialog_body">
      <klk-form
        flex_row
        :rules="formRules"
        :model="formData"
        ref="update_user_info_form"
      >
        <div class="sub-desc-text">{{ $t("116188") }}</div>
        <div style="display: flex; width: 100%">
          <klk-form-item
            :label="$t('116180')"
            prop="prefer_lang"
            :required="true"
            style="margin-right: 20px"
          >
            <el-select filterable v-model="formData.prefer_lang">
              <el-option
                v-for="(item, key) in klk_lang_options"
                :key="key"
                :label="item.title"
                :value="item.value"
              ></el-option>
            </el-select>
          </klk-form-item>
          <klk-form-item
            :label="$t('85890')"
            prop="country_code"
            :required="true"
          >
            <el-select filterable v-model="formData.country_code">
              <el-option
                v-for="(value, key) in code_country_map"
                :key="key"
                :value="key"
                :label="value.name"
              ></el-option>
            </el-select>
          </klk-form-item>
        </div>
      </klk-form>
    </div>
    <div class="klk_dialog_footer">
      <el-button type="primary" @click="submit">
        {{ $t("MULTIPLE_confirm") }}</el-button
      >
    </div>
  </klk-dialog>
</div>
