import 'virtual:svg-icons-register'; //Here the svg sprite map has been generated
import _ from 'underscore';
import '../css/main.scss';
import Vue from 'vue';
import App from './app.vue';
import { store } from './store';
import { initMonitor } from '@klook/aff-monitor';
import { router } from './router.js';
import { i18n, loadLanguageAsync } from './i18n.js';
import klook from 'klook';
import { formatPriceThousands, isMobile, SUPPORT_CURRENCY_SYMBO } from './utils';
import { use } from 'echarts/core';
import ECharts from 'vue-echarts';
import { CanvasRenderer } from 'echarts/renderers';
import { BarChart, PieChart, LineChart } from 'echarts/charts';
import ElementUI from 'element-ui';
import {
  GridComponent,
  TitleComponent,
  TooltipComponent,
  LegendComponent,
  DataZoomComponent,
} from 'echarts/components';
import Cookies from 'js-cookie';
import inhouse from '@klook/inhouse-tracker';
import { isAdmin, isKreator } from '@/comm/user_role';
// import * as Sentry from '@klook/sentry-vue'

Vue.use(ElementUI);
Vue.component('v-chart', ECharts);

use([
  CanvasRenderer,
  BarChart,
  GridComponent,
  LineChart,
  PieChart,
  TooltipComponent,
  TitleComponent,
  LegendComponent,
  DataZoomComponent,
]);

// klook
window.klook = klook;
window._ = _;

initMonitor(Vue, 'portal');

// const store = createStore();

Vue.config.devtools = !klook.isProductionUrl();

Vue.filter('currencyToSymbol', (value) => {
  return SUPPORT_CURRENCY_SYMBO[value];
});

Vue.filter('formatPrice', (value: string | number) => {
  return formatPriceThousands(value);
});

Vue.prototype.$inhouse = inhouse;


const keplerId = Cookies.get('kepler_id');


inhouse.init({
  url:  window.KLK_KV_CONFIG.LOGQUERY_URL_V3,
  isDebugMode: KLK_ENV !== 'production', // default false, 测试环境传 true
  timeout: 1500,
  headers: {}, // 可选，自定义上报携带请求头
  autoPageView: false, // 是否自动上报 pageview
  props: {
    pageId: '',
    siteName: window.location.host,
    keplerId,
    deviceId: keplerId,
    platform: isMobile() ? 'mobile' : 'desktop',
    distinctId: keplerId,
    experiments: [],
    // UserAdmin: store.state.globalObjModule.v3UserInfo.klook_admin || '',
    siteLanguage: window.KLK_LANG,
    siteCurrency: window.USER_INFO.prefer_currency,
  },
});

inhouse.setSprops({
  loginStatus: Cookies.get('islogin') ? 1 : 0,
  UID: window.USER_INFO.id,
  UserProgram: Cookies.get('islogin') ? isKreator() ? 'Kreator' : 'Affiliate' : undefined,
  IsAdmin: Cookies.get('islogin') ? isAdmin() : undefined,
  UserAdmin: store.state.globalObjModule.v3UserInfo.klook_admin || '',
});
loadLanguageAsync(window.KLK_LANG || 'en').then(() => {
  const vueInstance = new Vue({
    store,
    i18n,
    router: router,
    el: '#app',
    render: function (h: any) {
      return h(App);
    },
  });
  window.$vueInstance = vueInstance;

  //  初始化 sentry
  // Sentry.init({
  //   Vue,
  //   dsn: "https://e584854ca61127311180e5aa5ae61a0f@o4506031422308352.ingest.us.sentry.io/4507452274900992",
  //   environment: process.env.APP_ENV, // 环境名称, 用于过滤日志
  //   // sampleRate 、tracesSampleRate 默认为0.1
  //   sampleRate: 1,
  //   tracesSampleRate: 1,
  //   integrations: [
  //     new Sentry.BrowserTracing({
  //       routingInstrumentation: Sentry.vueRouterInstrumentation(router),
  //     }),
  //   ],
  //   debug: KLK_ENV !== 'prod'
  // }),
  // Vue.prototype.$sentry = Sentry;
  // Sentry.configureMeta({
  //   keplerId: keplerId,
  //   language: USER_INFO?.prefer_lang,
  //   currency: USER_INFO?.prefer_currency,
  //   userGlobalId: USER_INFO?.id,
  // })

});
