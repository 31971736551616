
<klk-cast-feedback
  v-if="questionObj.question_show"
  ref="feedback"
  :visible="false"
  :question="questionObj.question"
  :feedbackText="$t('feedback')"
  :btnSubmitText="$t('submit')"
  :successText="$t('feedback_thanks')"
  :placeholder="$t('feedback_tell_us')"
  :hearFromYou="$t('hear_from_you')"
  :showClose="true"
  mainColor="#2FC48D"
  position="right"
  :delayShowTime="delayShowTime"
  :delayFadeTime="delayFadeTime"
  @handle-close="handleCloseFn"
  @handle-submit="handleSubmit(arguments)"
  @handle-open-feedback="handleOpenFeedback"
>
</klk-cast-feedback>
