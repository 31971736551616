<template >
  <ul class="group_tab">
    <li
      v-for="(item, index) in data_copy"
      :key="index"
      @click="tabTrigger(index)"
      :class="{ active_tab: item.selected }"
    >
      {{ item.label }}
    </li>
  </ul>
</template>

<script>
//eg : home_my_activity
export default {
  name: "KlkGroupTab",
  props: {
    data: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      data_copy: [],
    };
  },
  watch: {
    data: function (newVal) {
      this.data_copy = newVal.concat([]);
    },
  },
  created() {
    this.data_copy = this.data.concat([]);
  },
  methods: {
    tabTrigger(target_index) {
      this.data_copy = this.data_copy.map((item, index) => {
        if (target_index == index) {
          item.selected = true;
          item.on_click && item.on_click();
        } else {
          item.selected = false;
        }
        return item;
      });
    },
  },
};
</script>

<style lang="scss"></style>
