<template>
  <div class="pagination" v-show="show_pagination" :data-spm-module="'Pagination?trg=manual'">
    <select
      class="page_size_opts"
      v-model="perPage"
      v-show="show_page_size_opts"
    >
      <option
        v-for="(number, index) in page_size_opts"
        :key="index"
        :value="number"
      >
        {{ page_size_opts_label_array[index] }}
      </option>
    </select>
    <ul class="pagination_cur_page" :data-spm-item="`Page${curPage}?trg=manual`">
      <li :data-spm-item="'Prev'" class="prev" :class="!hasPrev() && 'disabled'" @click="prev()"></li>

      <li :class="1 === curPage && 'active'" @click="go(1)">1</li>

      <li
        class="more_pages"
        v-show="left_more_pages_show"
        @click="minusCurPage"
      >
        ···
      </li>

      <li
        v-for="number in pages"
        :key="number"
        :class="number == curPage && 'active'"
        @click="go(number)"
      >
        {{ number }}
      </li>

      <li class="more_pages" v-show="right_more_pages_show" @click="addCurpage">
        ···
      </li>

      <li
        :class="totalPages == curPage && 'active'"
        v-show="totalPages > 1"
        @click="go(totalPages)"
      >
        {{ totalPages }}
      </li>

      <li :data-spm-item="'Next'" class="next" :class="!hasNext() && 'disabled'" @click="next()"></li>
    </ul>
    <div class="go_to_page_wrapper" v-show="show_elevator">
      <span>{{ $t("PAGINATION_go_to_page") }}</span>
      <input
        class="page_elevator"
        :value="curPage"
        type="text"
        @keyup.enter="go($event.target.value)"
      />
      <span>{{ $t("PAGINATION_page") }}</span>
    </div>
  </div>
</template>
<script>
export default {
  name: "KlkPagination",
  props: {
    total: Number,
    page_size: Number,
    prop_current: Number,
    click_size: Number, //可点击的按钮数
    page_size_opts: {
      type: Array,
      default: () => [10, 15, 20, 25, 30, 50],
    },
    show_page_size_opts: {
      type: Boolean,
      default: () => true,
    },
    show_elevator: {
      type: Boolean,
      default: () => true,
    },
  },
  data: function () {
    return {
      curPage: this.prop_current || 1,
      perPage: this.page_size || 5, //5 items per page
      clickSize: 7, //jump btn numbers
      left_more_pages_show: false,
      right_more_pages_show: false,
      // show_pagination:true,//是否显示，根据规范，分页不大于1页的时候不显示
    };
  },
  created() {
    this.page_size_opts_label_array = this.page_size_opts.map((pageSize) =>
      this.$t("PAGINATION_x_items_per_page", [pageSize])
    );
  },
  watch: {
    total: function () {
      // this.totalPages = Math.ceil(this.total / this.perPage) || 1;
      this.show_pagination = this.totalPages > 1 ? true : false;
    },
    prop_current: function () {
      this.curPage = this.prop_current || 1;
    },
    perPage: function (newVal) {
      if (this.$parent.handlePageSizeChange) {
        this.$parent.handlePageSizeChange.call(this.$parent, newVal);
      }
      this.go(1);
      this.$emit("on_page_size_change", newVal);
    },
  },
  computed: {
    show_pagination: {
      get() {
        return this.totalPages > 1 ? true : false;
      },
      set(/* val */) {},
    },
    // get pages to show
    totalPages: function () {
      return Math.ceil(this.total / this.perPage) || 1;
    },
    pages: function () {
      /* eslint-disable */
      if (this.totalPages <= 0) {
        return [];
      }
      var clickSize = this.clickSize,
        totalPages = this.totalPages,
        curPage = this.curPage,
        pages = [],
        min = 1,
        max = totalPages;

      if (clickSize > totalPages) {
        clickSize = totalPages;
        min = 1;
        max = clickSize;
      }
      while (max >= min && max - min >= clickSize) {
        if (max - curPage >= curPage - min) max--;
        else if (max - curPage <= curPage - min) min++;
      }

      for (var i = min; i <= max; i++) {
        pages.push(i);
      }

      this.left_more_pages_show = pages[0] == 1 ? false : true;

      this.right_more_pages_show =
        pages[pages.length - 1] == this.totalPages ? false : true;

      pages.pop();
      pages.shift();
      return pages;
    },
  },

  methods: {
    minusCurPage: function () {
      this.curPage =
        this.curPage <= this.clickSize - 2
          ? 1
          : this.curPage - (this.clickSize - 2);
      this.$emit("current_change", this.curPage);
      setTimeout(() => {
        this.$inhouse.track('action', '.pagination_cur_page');
      });
    },
    addCurpage: function () {
      this.curPage =
        this.curPage + 5 > this.totalPages
          ? this.totalPages
          : this.curPage + (this.clickSize - 2);
      this.$emit("current_change", this.curPage);
    },
    next: function () {
      if (this.curPage < this.totalPages) {
        this.curPage++;
        this.$emit("current_change", this.curPage);
      }
    },

    prev: function () {
      if (this.curPage > 1) {
        this.curPage--;
        this.$emit("current_change", this.curPage);
      }
    },

    go: function (curPage) {
      if (!/^\d+$/.test(curPage)) return;
      curPage = parseInt(curPage);

      if (curPage < 1) {
        curPage = 1;
      } else if (curPage > this.totalPages) {
        curPage = this.totalPages;
      }
      // if (this.curPage == curPage) return;//trigger the same page still refresh
      this.curPage = curPage;
      this.$emit("current_change", this.curPage);
      setTimeout(() => {
        this.$inhouse.track('action', '.pagination_cur_page');
      })
    },

    hasNext: function () {
      return this.curPage < this.totalPages;

    },

    hasPrev: function () {
      return this.curPage > 1;
    },
  },
};
</script>
<style lang="scss">
.pagination {
  margin-top: 30px;
  display: inline-block;
  .page_size_opts {
    float: left;
    height: 36px;
    margin-right: 12px;
  }
  ul {
    float: left;
    li:nth-child(1) {
      margin-left: 0;
    }
    li {
      cursor: pointer;
      float: left;
      text-align: center;
      line-height: 34px;
      min-width: 36px;
      height: 36px;
      border-radius: 2px;
      background: #fff;
      font-size: 16px;
      color: #424242;
      padding: 0 10px;
      border: 1px solid #d1d1d1;
      margin-left: 12px;
      &.active {
        background: $theme_color;
        color: #fff;
        border-color: $theme_color;
      }
      &.more_pages {
        border: none;
        margin-left: 0;
        margin-right: -12px;
        transition: all 0.3s;
        font-size: 16px;
        &:hover {
          color: $theme_color;
          letter-spacing: 2px;
        }
      }
      &.prev::before,
      &.next::before {
        content: "";
        width: 10px;
        height: 10px;
        display: inline-block;
        border-top: 2px solid #888;
        border-left: 2px solid #888;
      }
      &.disabled {
        cursor: not-allowed;
      }
      &.disabled::before {
        border-color: #e0e0e0;
      }
      &.prev::before {
        transform: rotate(-45deg);
        margin-left: 6px;
      }
      &.next::before {
        transform: rotate(135deg);
        margin-right: 6px;
      }
    }
  }
  .go_to_page_wrapper {
    float: left;
    margin-left: 12px;
    .page_elevator {
      width: 40px;
      height: 36px;
      text-align: center;
      border-radius: 0;
      padding: 0 2px;
    }
  }
}
</style>
