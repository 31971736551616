<style lang="scss">
.login_form {
  $text-color: rgba(0, 0, 0, 0.85);

  .login_ways_wrapper {
    display: flex;
    justify-content: space-between;
    margin-top: 22px;

    .login_ways_icon {
      width: 51px;
      height: 44px;
    }
  }

  .klk_dialog_title {
    font-weight: 500;
    font-size: 30px;
    line-height: 40px;
    color: #333333;
  }

  .grecaptcha-badge {
    display: none;
  }

  .klk_dialog_body {
    .affiliate_agreement {
      margin-left: 8px;
      font-size: 14px;
      color: $text-color;
    }

    .password-info-wrapper {
      display: flex;
      justify-content: space-between;
      font-size: 14px;

      .remeber_me {
        color: $text-color;
      }

      .forgot {
        line-height: 26px;
        color: $text-color;
        cursor: pointer;

        &:hover {
          color: $theme_color;
        }
      }
    }
  }

  .klk_dialog_footer {
    .sign_up_wrapper {
      .btn_text {
        line-height: 60px;
        font-size: 14px;
      }
    }
  }

  .error_tips {
    margin: 0 40px;
    background: #fff1f0;
    word-wrap: break-word;
    border: 1px solid #ffccc7;
    box-sizing: border-box;
    padding: 9px 16px;
    margin-top: 24px;
    font-weight: normal;
    font-size: 14px;
    line-height: 22px;
    border-radius: 2px;
  }

  .klk_form_item_error_tip {
    color: #f5222d !important;
    margin-top: 4px;
  }

  #login_btn {
    line-height: 40px;
    margin-top: 24px;
  }

  .login_way_tips {
    width: 360px;
    height: 42px;
    line-height: 42px;
    cursor: pointer;
    text-align: center;
    background: #f2f2f2;
    border-radius: 2px;
    margin-top: 22px;
  }
}
</style>

<template>
  <div class="login_form" v-loading="is_logging_in" data-spm-page="LogInPopUp">
    <div class="klk_dialog_title">
      {{ $t("LOGIN_FORM_affiliate_log_in") }}
    </div>
    <p class="klk_dialog_prompt">
      {{ $t("can_log_with_klook") }}
    </p>
    <p class="error_tips" :data-spm-module="`Error?oid=error_${login_error_code}&ext=${encodeURIComponent(JSON.stringify({
          msg: login_error,
        }))}`" v-show="login_error !== ''">
      <span v-html="login_error" />
      <span
        @click="forgot_password"
        style="cursor: pointer; text-decoration: underline; color: #f5222d"
        v-if="showResetPassword"
      >{{ $t("FORGOT_FORM_reset_password") }}</span
      >
    </p>
    <div class="klk_dialog_body">
      <klk-form :rules="formRules" :model="form_data" ref="ref_form_login">
        <klk-form-item prop="email">
          <klk-input
            :placeholder="$t('MULTIPLE_email')"
            v-on:input="login_error = ''"
            v-model.trim="form_data.email"
          ></klk-input>
        </klk-form-item>
        <klk-form-item prop="password">
          <klk-input
            :placeholder="$t('MULTIPLE_password')"
            type="password"
            v-on:input="login_error = ''"
            v-model="form_data.password"
          ></klk-input>
        </klk-form-item>

        <klk-form-item prop="affiliate_agreement">
          <klk-checkbox v-model="form_data.affiliate_agreement">
            <span
              class="affiliate_agreement"
              v-html="read_affiliate_agreement"
            ></span>
            <a
              :data-spm-module="'AffiliateAgreement'"
              data-spm-virtual-item="__virtual"
              class="theme_a"
              style="font-size: 14px"
              target="_blank"
              :href="affiliate_agreement_href"
            >
              {{ $t("LOGIN_FORM_login_affiliate_agreement") }}</a
            >
          </klk-checkbox>
        </klk-form-item>
        <klk-form-item>
          <div
            class="iht_remember_me"
            :data-spm-module="'RememberMe?trg=manual'"
            :data-spm-virtual-item="`__virtual?ext=${JSON.stringify({isCheck: !form_data.remeber_me ? 'Check':
                'Uncheck'})}`">
          </div>
          <div class="password-info-wrapper">
            <div class="remeber_me">
              <klk-checkbox
                @on-change="$inhouse.track('action', '.iht_remember_me')"
                v-model="form_data.remeber_me">
                <span>
                {{ $t("LOGIN_FORM_remeber_me") }}
                </span>
              </klk-checkbox>
            </div>
            <div
              :data-spm-module="'ForgotPassword'"
              :data-spm-virtual-item="`__virtual`"
              class="forgot"
              @click="forgot_password">
              {{ $t("LOGIN_FORM_forgot_password") }}
            </div>
          </div>
        </klk-form-item>
      </klk-form>
    </div>
    <div class="klk_dialog_footer" id="klk_dialog_footer">
      <button
        :data-spm-module="'LoginIn'"
        :data-spm-virtual-item="`__virtual`"
        id="login_btn"
        type="button"
        class="theme_btn_base btn_full log_in g-recaptcha"
        name="button"
        data-sitekey="6LdxrlUUAAAAAKenOqfrpCwQ64JncIaQ300sju2m"
        @click="captchaStart"
      >
        {{ $t("MULTIPLE_log_in") }}
      </button>
      <div
        class="login_ways_wrapper"
        v-show="!showLoginWayTips"
        :data-spm-module="'3rdParty'"
        :data-spm-virtual-item="`__virtual`"
      >
        <div v-for="type in loginWays" @click="comingsoon(type)">
          <klk-icon class="login_ways_icon" :type="type"></klk-icon>
        </div>
      </div>
      <div class="login_way_tips" v-show="showLoginWayTips">
        {{ $t("89221") }}
      </div>
      <div class="sign_up_wrapper">
        <button
          :data-spm-module="'SignUp'"
          :data-spm-virtual-item="`__virtual`"
          type="text" class="btn_text" @click="sign_up">
          {{ $t("MULTIPLE_sign_up") }}
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { formRules, urlObj, FROM_LOGIN_KEY, SIGNUP_TYPE_KEY } from "@/comm/common_data";
import klook from "klook";
import Cookies from "js-cookie";
import {
  initCaptcha,
  getCaptcha,
  simpleCaptchaErrHandler,
} from "../../../../comm/captcha";
import Config from "@klook/aff-config";

export default {
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      loginWays: [ "wechat-new", "google", "facebook", "apple" ],
      read_affiliate_agreement: this.$t("LOGIN_FORM_read_affiliate_agreement"),
      lang_ali: {
        "zh-CN": "cn",
        en: "en",
        "zh-HK": "tw",
        "zh-TW": "tw",
      },
      affiliate_agreement_href: "",
      is_logging_in: this.loading,
      form_data: {
        email: "",
        password: "",
        affiliate_agreement: false,
        remeber_me: false,
      },
      login_error: "",
      login_error_code: '',
      errorCode: "",
      captcha_type: "", // 0:google验证 1:阿里无痕验证 2:阿里ie滑块验证 3: 极验 4: 谷歌V3
      captchaSettings: {},
      captchaInitSuccess: false,
      showLoginWayTips: false,
    };
  },
  computed: {
    showResetPassword() {
      return [ "10206", "10224", "10204" ].some((str) => {
        return this.errorCode.includes(str);
      });
    },
  },
  watch: {
    loading: function (newV) {
      this.is_logging_in = newV;
    },
  },
  methods: {
    comingsoon(type) {
      let map = {
        "wechat-new": "Wechat",
        google: "Google",
        facebook: "Facebook",
        apple: "Apple",
      };
      window.dataLayer.push({
        event: "trackEventCustom",
        eventCategory: "Log In",
        eventAction: "3rd-party",
        eventLabel: map[type],
      });
      this.showLoginWayTips = true;
      // this.$message({
      //   message: this.$t("89221"),
      //   type: 'success'
      // });
    },
    login(data = {}) {
      let obj = Object.assign(
        {},
        {
          captcha: {
            refer_url: location.href,
            ["X-Platform"]: "desktop",
            need_cookie: true,
            gateway: "klook",
            ...data,
          },
          email: this.form_data.email,
          password: klook.md5(this.form_data.password),
        }
      );
      this.is_logging_in = true;
      // 先调用c端login判断是否有c端账户
      klook.ajaxPostJSON(
        urlObj.user_login,
        obj,
        (res) => {
          this.is_logging_in = false;
          // 有c端用户再调用affiliate登录
          if (res.success) {
            window.dataLayer.push({
              event: "trackEventCustom",
              eventCategory: "Login Pop-up",
              eventAction: "Login",
            });
            if (this.form_data.remeber_me) {
              localStorage.setItem("remember_user_name", this.form_data.email);
            }
            let token = res.result && res.result.token;
            // this.$emit("old_get_user_info", token);
            klook.ajaxGet(
              urlObj.new_login,
              {
                // login_id: this.form_data.email,
              },
              (resp) => {
                if (resp.success) {
                  let result = (resp && resp.result) || {};
                  // 不存在affiliate用户 直接让它去填信息创建用户
                  if (result.secret) {
                    this.$store.commit("update_secret", result.secret || "");
                    this.$store.commit("change_join_now_type", "");
                    this.$router.push({
                      name: "choose_user_type",
                      query: {
                        email: this.form_data.email,
                        type: "Log_In",
                      },
                    });
                  } else {
                    // 正常登陆
                    // get deplay time from session // delay_show_time 单位为min
                    const delay_show_time =
                      result.feedback_csat_delay_show_time || 0;
                    const delay_fade_time =
                      result.feedback_csat_delay_fade_time || 0;
                    klook.cacheJS(
                      "delay_show_time",
                      delay_show_time,
                      delay_show_time * 60
                    );
                    klook.cacheJS(
                      "delay_fade_time",
                      delay_fade_time,
                      delay_fade_time * 60
                    );
                    localStorage.setItem(FROM_LOGIN_KEY, true);
                    sessionStorage.removeItem(SIGNUP_TYPE_KEY);
                    this.$emit("login_form_get_user_info");
                  }
                } else {
                  this.login_error_handle({
                    code: res.error.code,
                    message: resp.error.message || this.$t("MULTIPLE_client_network_failure")
                  })
                }
              },
              {
                ["Accept-Language"]: Config.USER_LANG_MAP[KLK_LANG],
                ["Token"]: token,
              }
            );
          } else {
            this.errorCode = res.error.code;
            if(this.errorCode === 'P0120') {
              this.login_error_handle({
                code: this.errorCode,
                message: this.$t('171802', { MULTILANG_URL_PLACEHOLDER: 'https://www.klook.com/tetris/promo/aff-3-party-login/' })
              })
            } else {
              this.login_error_handle(res.error);
            }
          }
        },
        {
          ["Accept-Language"]: Config.USER_LANG_MAP[KLK_LANG],
        }
      );
    },
    errorMessage(/* error */) {
      this.$Message({
        type: "warning",
        duration: 4000,
        content: this.$t("MULTIPLE_client_network_failure"),
        showClose: false,
        isCenter: true,
      });
    },
    captchaStart() {
      this.login_error = "";
      document.getElementById("klk_dialog_footer").style.marginTop = "0px";
      this.$refs["ref_form_login"].validate((valid) => {
        if (valid && this.captchaInitSuccess) {
          this.is_logging_in = true;
          getCaptcha(this.captchaSettings)
            .then((result) => {
              // result === false 表示 initCaptcha未成功
              this.is_logging_in = false;
              if (result === false) {
                return initCaptcha("login")
                  .then((captcha) => {
                    this.captchaInitSuccess = true;
                    this.captchaSettings = captcha;
                    return getCaptcha(captcha);
                  })
                  .catch((error) => {
                    this.errorMessage(error);
                  });
              }
              return result;
            })
            .then((result) => {
              this.login(result);
            })
            .catch((error) => {
              this.is_logging_in = false;
              this.errorMessage(error);
              simpleCaptchaErrHandler(error, this);
            });
        }
      });
    },
    login_error_handle(error) {
      this.is_logging_in = false;
      this.login_error = error?.message || ''
      this.login_error_code = error?.code || ''
    },
    sign_up() {
      window.dataLayer.push({
        event: "trackEventCustom",
        eventCategory: "Log In",
        eventAction: "Sign Up",
      });
      // this.$emit("login_form_sign_up");
      this.$store.commit("change_join_now_type", "signUp");
      if (this.$store.state.gtmEventModule.login_event_category === "Header") {
        this.$store.commit("change_signup_event_category", "Header");
      } else {
        this.$store.commit("change_signup_event_category", "Banner");
      }
    },
    forgot_password() {
      // Google Tag Manager
      window.dataLayer.push({
        event: "trackEventCustom",
        eventCategory: "Login Pop-up",
        eventAction: "Forgot Password",
      });
      this.$emit("login_form_forgot_password");
    },
    // 升级迁移接口
    captchaInit() {
      this.is_logging_in = true;
      initCaptcha("login")
        .then((captcha) => {
          this.is_logging_in = false;
          this.captchaInitSuccess = true;
          this.captchaSettings = captcha;
        })
        .catch((error) => {
          this.is_logging_in = false;
          this.errorMessage(error);
        });
    },
  },
  mounted() {
    const user_name = localStorage.getItem("remember_user_name");
    if (user_name) {
      this.form_data.email = user_name;
    }
  },
  created() {
    this.enterEvent = (e) => {
      if (e.code === "Enter") {
        this.captchaStart();
      }
    };
    document.addEventListener("keyup", this.enterEvent);
    this.affiliate_agreement_href =
      "https://affiliate.klook.com/" + KLK_LANG_PATH + "help/terms_conditions";
    this.formRules = formRules.call(this);
    // 初始化验证码, 加载 sdk
    this.captchaInit();
  },
  destroyed() {
    document.removeEventListener("keyup", this.enterEvent);
  },
};
</script>
