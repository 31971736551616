import Vue from "vue";
import VueI18n from '@klook/vue-i18n'
import I18n from "@klook/i18n";

// import VueI18n from "vue-i18n";
import locale from "element-ui/lib/locale";

Vue.use(VueI18n);

export const i18n = new VueI18n({
  locale: "en",
  messages: {},
  interpolation: {
    prefix: '{',
    postfix: '}',
    linkKeyWord: 'MULTILANG_URL_PLACEHOLDER'
  }
});
export const js_i18n = new I18n({
      locale: "en",
      messages: {},
      interpolation: {
        prefix: '{',
        postfix: '}',
        linkKeyWord: 'MULTILANG_URL_PLACEHOLDER'
      }
});

const modules = import.meta.glob("/../packages/locales/langs/*.json");
const elLangs = import.meta.globEager(
  "/../node_modules/element-ui/lib/locale/lang/*.js"
);
// console.log(modules);
export const loadLanguageAsync = (lang) => {
  // return import(`@klook/aff-locales/langs/${lang}.json`).then((msg) => {
  let key = `/../packages/locales/langs/${lang}.json`;
  let elKey = `/../node_modules/element-ui/lib/locale/lang/${(lang =
    lang === "zh-HK" ? "zh-TW" : lang)}.js`;
  const langJs = elLangs[elKey];
  locale.use(langJs["default"]);
  return modules[key]()
    .then((m) => m.default)
    .then((msg) => {
      i18n.options.messages = { [lang]: msg };
      i18n.options.locale = lang;
      document.querySelector("html").setAttribute("lang", lang);
      
      js_i18n.options.messages =  { [lang]: msg };
      js_i18n.options.locale = lang;
      return Promise.resolve();
    });
};
