
<div id="klk-cast-feedback" class="klk-cast-feedback" :style="style">
  <div v-if="!visible" class="klk-cast-icon" @click="openFeedbackFn">
    <img :src="genResourceFullPath('feedback/feedback.png')" alt="" />
    <span>{{ feedbackText }}</span>
  </div>

  <div v-if="remind" class="klk-cast-remind-feedback">
    {{ hearFromYou }}<i class="klk-feedback-triangle"></i>
  </div>
  <cast-context
    ref="cast"
    v-if="open"
    :width="width"
    :color="color"
    :question="question"
    :mainColor="mainColor"
    :btnRemindText="btnRemindText"
    :btnSubmitText="btnSubmitText"
    :showClose="showClose"
    :successText="successText"
    :position="position"
    :placeholder="placeholder"
    @submit="handleSubmit(arguments)"
    @handle-close="handleClose"
  >
  </cast-context>
</div>
