import Vue from "vue";
import KlkMessage from "./src/message.vue";
let MessageConstructor = Vue.extend(KlkMessage);
let instance;

const Message = function (options = {}) {
  if (options === "string") {
    options = {
      content: options,
    };
  }
  instance = new MessageConstructor({
    data: options,
  });
  const msgElement = instance.$mount().$el;
  document.body.appendChild(msgElement);
  instance.visible = true;
  return instance;
};

Message.info = (txt) => {
  Message({
    type: "info",
    content: txt,
  });
};

Message.success = (txt) => {
  Message({
    type: "success",
    content: txt,
  });
};

Message.warning = (txt) => {
  Message({
    type: "warning",
    content: txt,
  });
};

export { Message as KlkMessage };
