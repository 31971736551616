import Vue from "vue";
import VueRouter from "vue-router";
import Cookies from "js-cookie";
import { isAdmin, isKreator, isManager } from "@/comm/user_role";
import klkClientReport from "@/comm/client-report";
import { shouldEditWebsite, $t } from "@/comm/utils";
import _ from "underscore";
import inhouse from "@klook/inhouse-tracker";
import { isMobile } from "@/desktop/js/utils";
import { store } from "./store/index";

var is_signin = false;
if (window.USER_INFO && window.USER_INFO.id) {
  is_signin = true;
}

Vue.use(VueRouter);
// 避免路由重复报错
const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch((err) => err);
};

/**
 * 使用工厂模式 使之使用时才执行
 * 避免引入时即刻调用，造成多语言未初始化前执行 i18n.t 函数
 */
export const getNavRoutesUtil = () => {
  return {
    home: {
      path: "/home",
      title: $t("ROUTER_home"),
    },
    tools: {
      path: "/tools",
      title: $t("MULTIPLE_tools"),
    },
    help: {
      path: "/help",
      title: $t("ROUTER_help"),
    },
    contact_us: {
      path: "/contact_us",
      title: $t("ROUTER_contact_us"),
    },
    admin: {
      path: "/admin",
      title: $t("MULTIPLE_admin"),
    },
  };
};

export const NavRouterAfterSignin = () => {
  return {
    dashboard: {
      path: "/dashboard",
      title: $t("ROUTER_dashboard"),
    },
    my_ads: {
      path: "/my_ads",
      title: $t("ROUTER_my_ads"),
    },
    performance: {
      path: "/performance",
      title: $t("ROUTER_performance"),
    },
    my_account: {
      path: "/my_account",
      title: $t("ROUTER_my_account"),
    },
    help: {
      path: "/help",
      title: $t("ROUTER_help"),
    },
    admin: {
      path: "/admin",
      title: $t("MULTIPLE_admin"),
    },
    resource_hub: {
      path: "/resource_hub",
      title: $t("resource_hub"),
    },
  };
};

const getDynamicWidgets = (type) => {
  return `/my_ads/dynaminc_widgets`;
};
// exports.getNavRoutesUtil = NavRouter; //这个变量很容易导致循环依赖，所以先把它导出去
// exports.NavRouterAfterSignin = NavRouterAfterSignin;

const NameLinkMap = {
  root: "/",
};
_.extend(NameLinkMap, getNavRoutesUtil(), NavRouterAfterSignin());

//提供build chunk名称

//before signin
const Home = function () {
  return import(/* webpackChunkName:"home" */ "./pages/home");
};
const Tools = function () {
  return import(/* webpackChunkName:"tools" */ "./pages/tools");
};
const SignUpAuto = function () {
  return import(/* webpackChunkName:"signup_auto" */ "./pages/signup_auto");
};
const ActivationFeedback = function () {
  return import(
    /* webpackChunkName:"activation_feedback" */
    "./pages/activation_feedback"
    );
};
const ContactUs = function () {
  return import(/* webpackChunkName:"contact_us" */ "./pages/contact_us");
};
const Help = function () {
  return import(/* webpackChunkName:"help" */ "./pages/help");
};

//after signin
const Dashboard = function () {
  return import(/* webpackChunkName:"dashboard" */ "./pages/dashboard");
};

const KreatorDashboard = function () {
  return import(/* webpackChunkName:"kreator_dashboard" */ "./pages/dashboard/kreator_dashboard");
};

const Resendlink = function () {
  return import(/* webpackChunkName:"resendlink" */ "./pages/resendlink");
};

const MyAdsRoute = function () {
  return import(/* webpackChunkName:"my_ads" */ "./pages/my_ads");
};

const AdList = function () {
  return import(/* webpackChunkName:"ad_list" */ "./pages/my_ads/ad_list");
};

const AdTools = function () {
  return import(/* webpackChunkName:"ad_tools" */ "./pages/my_ads/ad_tools");
};
const TextLink = function () {
  return import(/* webpackChunkName:"text_link" */ "./pages/my_ads/ad_tools/text_link");
};

const DynamincWidgets = function () {
  return import(/* webpackChunkName:"dynamic_widgets" */ "./pages/my_ads/ad_tools/dynamic_widgets");
};

const MoreAdTools = function() {
  return  import (/* webpackChunkName:"more_ad_tools" */ "./pages/my_ads/ad_tools/more_ad_tools");
}

const HotelDeals = function () {
  return import(/* webpackChunkName:"hotel_deals" */ "./pages/my_ads/hotel_tools/hotel_tool_module/hotel_deals.vue");
};
const HotelDealsAds = function () {
  return import(/* webpackChunkName:"deals_ads_page" */ "./pages/my_ads/hotel_tools/hotel_tool_module/deals_ads_page.vue");
};

const AdPromoCode = function () {
  return import(/* webpackChunkName:"ad_promo_code" */ "./pages/my_ads/ad_tools/ad_tool_module/code_ad.vue");
};

const HotelTools = function () {
  return import(
    /* webpackChunkName:"hotel_tools" */ "./pages/my_ads/hotel_tools"
    );
};

const AdToolModule = function () {
  return import(
    /* webpackChunkName:"ad_tool_module" */
    "./pages/my_ads/ad_tools/ad_tool_module"
    );
};

const HotelToolModule = function () {
  return import(
    /* webpackChunkName:"hotel_tool_module" */
    "./pages/my_ads/hotel_tools/hotel_tool_module"
    );
};

var ChangeWebSiteName = function () {
  return import(
    /* webpackChunkName:"change_website_name" */
    "./pages/change_website_name"
    );
};
//Help
const Faqs = function () {
  return import(/* webpackChunkName:"faqs" */ "./pages/help/faqs");
};
const TermsConditions = function () {
  return import(
    /* webpackChunkName:"terms_conditions" */
    "./pages/help/terms_conditions"
    );
};
const CommissionRates = function () {
  return import(
    /* webpackChunkName:"commission_rates" */
    "./pages/help/commission_rates"
    );
};
const GiftCardActivity = function () {
  return import(
    /* webpackChunkName:"gift_card_activity" */
    "./pages/help/gift_card_activity"
    );
};
const SpecialActivity = function () {
  return import(
    /* webpackChunkName:"special_activity" */
    "./pages/help/special_activity"
    );
};

//performance
const PerformanceRoute = function () {
  return import(
    /* webpackChunkName:"performance_route" */
    "./pages/performance"
    );
};
const PerformanceOverview = function () {
  return import(
    /* webpackChunkName:"performance_overview" */
    "./pages/performance/performance_overview"
    );
};

const AdPerformance = function () {
  return import(
    /* webpackChunkName:"performance_overview" */
    "./pages/dashboard/components/ad_performance"
    );
};

const TicketList = function () {
  return import(
    /* webpackChunkName:"ticket_list" */
    "./pages/performance/ticket_list"
    );
};

const TicketStatusList = function () {
  return import(
    /* webpackChunkName:"ticket_list" */
    "./pages/performance/ticket_status_list"
    );
};

const MyAccount = function () {
  return import(/* webpackChunkName:"my_account" */ "./pages/my_account");
};

//admin
const AdminRoute = function () {
  return import(/* webpackChunkName:"admin_route" */ "./pages/admin");
};

const DynamicCommission = function () {
  return import(
    /* webpackChunkName:"dynamic_commission" */
    "./pages/admin/dynamic_commission"
    );
};

const DynamicCommissionRule = function () {
  return import(
    /* webpackChunkName:"dynamic_commission_rule" */
    "./pages/admin/dynamic_commission/rule/index.vue"
    );
};
const Expense = function () {
  return import(/* webpackChunkName:"expense" */ "./pages/admin/expense");
};
const AffiliateAdmin = function () {
  return import(
    /* webpackChunkName:"affiliate_admin" */
    "./pages/admin/affiliate_admin"
    );
};

const SpecialAllowList = () => {
  return import(
    /* webpackChunkName:"special_allow_list" */
    "./pages/admin/special_allow_list"
    );
};

const AdminSpecialCustomerAdList = function () {
  return import(
    /* webpackChunkName:"admin_ad_list" */
    "./pages/admin/affiliate_admin/ad_list"
    );
};

const AdminAdManagement = function () {
  return import(
    /* webpackChunkName:"admin_ad_management" */
    "./pages/admin/affiliate_admin/admin_ad_management"
    );
};
const AdminNetworkPublisherManagement = function () {
  return import(
    /* webpackChunkName:"admin_network_publisher_management" */
    "./pages/admin/affiliate_admin/admin_network_publisher_management"
    );
};

const InternalUser = function () {
  return import(
    /* webpackChunkName:"internal_user" */
    "./pages/admin/internal_user"
    );
};

const PaymentsAdmin = function () {
  return import(
    /* webpackChunkName:"payments_admin" */
    "./pages/admin/payments_admin"
    );
};
const PaymentsAdminHistory = function () {
  return import(
    /* webpackChunkName:"payments_admin_history" */
    "./pages/admin/payments_admin/history"
    );
};
const CampaignRule = function () {
  return import(
    /* webpackChunkName:"campaign_rule" */
    "./pages/admin/campaign_rule/index"
    );
};
const MonthlyReport = function () {
  return import(
    /* webpackChunkName:"monthly_report" */
    "./pages/admin/monthly_report"
    );
};

const UploadType = function () {
  return import(
    /* webpackChunkName:"upload_type" */
    "./pages/admin/system_admin/upload_type"
    );
};

const CoBrand = function () {
  return import(/* webpackChunkName:"co_brand" */ "./pages/my_ads/co_brand");
};

const CampaignPage = function () {
  return import(
    /* webpackChunkName:"campaign_page" */ "./pages/my_ads/campaign_page"
    );
};

const PartnerManagement = function () {
  return import(
    /* webpackChunkName:"partner_management" */
    "./pages/admin/partner_management"
    );
};

const KreatorManagement = function () {
  return import(
    /* webpackChunkName:"kreator_management_admin" */
    "./pages/admin/kreator_management_admin"
    );
};

const CoBrandManagement = function () {
  return import(
    /* webpackChunkName:"co_brand_management" */
    "./pages/admin/co_brand_management/co_brand_management.vue"
    );
};

const Notification = function () {
  return import(
    /* webpackChunkName:"notification" */
    "./pages/notification/notification.vue"
    );
};

const NotificationManagement = function () {
  return import(
    /* webpackChunkName:"notification_management" */
    "./pages/admin/notification_management/notification_management.vue"
    );
};

const DealsAds = function () {
  return import(
    /* webpackChunkName:"notification_management" */
    "./pages/admin/deals_management/deals_ads_page.vue"
    );
};

const DealsManagement = function () {
  return import(
    /* webpackChunkName:"notification_management" */
    "./pages/admin/deals_management/deals_management.vue"
    );
};

const adminRouterCheck = (next) => {
  if (isAdmin()) {
    next();
  } else {
    window.location.replace("/" + window.KLK_LANG_PATH);
  }
};

const AdDemo = function () {
  return import(
    /* webpackChunkName:"demo" */
    "./render/affiliate/ads_demo_page/ads_demo_page.vue"
    );
};

const ProductList = function () {
  return import(
    /* webpackChunkName:"product_list" */
    "./render/affiliate/product_listing_page/product_listing_page.vue"
    );
};

const TavelTrendsReport = function () {
  return import(
    /* webpackChunkName:"travel_trends_report" */
    "./pages/travel_trends_report"
    );
};

const ResourceHub = function () {
  return import(
    /* webpackChunkName:"resource_hub" */
    "./pages/resource_hub"
    );
};

const ChooseUserType = () =>
  import(
    /* webpackChunkName:"choose_user_type" */ "./pages/signup_auto/choose_user_type.vue"
    );

const routes = [
  {
    path: "/",
    component: is_signin ? Dashboard : Home,
    name: "home",
  },
  {
    path: "/ad_performance",
    component: AdPerformance,
    name: "ad_performance",
  },
  {
    path: NameLinkMap.home.path,
    redirect: "/",
  },
  {
    path: NameLinkMap.tools.path,
    component: Tools,
    name: "tools",
  },
  {
    path: "/activation_feedback/:activation_token",
    component: ActivationFeedback,
    name: "activation_feedback",
  },
  {
    path: "/activation_feedback/:activation_token/:source_type",
    component: ActivationFeedback,
    name: "activation_feedback_source",
  },
  {
    path: "/signup_auto",
    component: SignUpAuto,
    name: "signup_auto",
  },
  {
    path: "/choose_user_type",
    component: ChooseUserType,
    name: "choose_user_type",
  },
  {
    path: NameLinkMap.contact_us.path,
    component: ContactUs,
    name: "contact_us",
  },
  {
    path: NameLinkMap.help.path,
    component: Help,
    // name:'help',
    children: [
      {
        path: "",
        component: Faqs, //default
        name: "help",
      },
      {
        path: "terms_conditions",
        component: TermsConditions,
        name: "terms_conditions",
      },
      {
        path: "commission_rates",
        component: CommissionRates,
        name: "commission_rates",
      },
      {
        path: "gift_card_activity",
        component: GiftCardActivity,
        name: "gift_card_activity",
      },
      {
        path: "special_activity",
        component: SpecialActivity,
        name: "special_activity",
      },
    ],
    beforeEnter(to, from, next) {
      // kreator 进入help 页面时新开页面Tetris
      // 不进入 help，原因是原本 help信息是提供给affiliate的，与kreator 数据不一致，存在误导
      if (isKreator()) {
        const TETRIS_LINK = "https://www.klook.com/tetris/promo/kreator-faq/";
        if (from.name) {
          window.open(TETRIS_LINK);
        } else {
          window.location.href = TETRIS_LINK;
        }
      } else {
        next();
      }
    },
  },
  // after signin
  {
    path: NameLinkMap.dashboard.path,
    component: Dashboard,
    name: "dashboard",
  },
  {
    path: "/dashboard_kol",
    component: KreatorDashboard,
    name: 'dashboard_kol'
  },
  {
    path: "/notification/:id?",
    component: Notification,
    name: "notification",
  },
  {
    path: "/resendlink",
    component: Resendlink,
    name: "resendlink",
  },
  {
    path: NameLinkMap.my_ads.path,
    component: MyAdsRoute,
    // name:'my_ads_route',
    children: [
      {
        path: "",
        component: AdList, //default
        name: "my_ads",
      },
      {
        path: "ad_tools",
        component: AdTools,
        name: "ad_tools",
        children: [
          {
            path: ":ad_tool_type",
            component: AdToolModule,
            name: "ad_tool_module",
            beforeEnter(to, from, next) {
              const toolType = to.params.ad_tool_type;
              let queryType = "ttd";
              let name = "";
              if ([ "dynamic_widget", "dynamicWidgets" ].includes(toolType)) {
                name = "dynaminc_widgets";
              } else if ([ "link", "car_rental_link" ].includes(toolType)) {
                name = "text_links";
                toolType === "car_rental_link" ? queryType = "car_rental" : null;
              } else if (toolType === "code") {
                name = "ad_promo_code";
              }

              if (name) {
                next({
                  name,
                  query: {
                    type: queryType,
                    ...to.query
                  }
                });
              } else {
                next();
              }

            },
          },
        ],
      },
      {
        path: "text_links",
        component: TextLink,
        name: "text_links"
      },
      {
        path: "dynaminc_widgets",
        component: DynamincWidgets,
        name: "dynaminc_widgets"
      },
      {
        name: "more_ad_tools",
        path: "more_ad_tools",
        component: MoreAdTools
      },
      {
        path: "hotel_tools",
        component: HotelTools,
        name: "hotel_tools",
        children: [
          {
            path: ":ad_tool_type",
            component: HotelToolModule,
            name: "hotel_tool_module",
            beforeEnter(to, from, next) {
              const toolType = to.params.ad_tool_type;
              let name = '';
              if (toolType === "hotel_widget") {
                name = "dynaminc_widgets";
              } else if ([ "link" ].includes(toolType)) {
                name = "text_links";
              } else if (toolType === "deals") {
                name = "hotel_deals";
              }
              if (name) {
                next({
                  name,
                  query: {
                    type: "hotel",
                    ...to.query
                  }
                });
              } else {
                next();
              }
            },
          },
        ],
      },
      {
        path: "hotel_deals",
        name: "hotel_deals",
        component: HotelDeals,
      },
      {
        path: "hotel_deals_ads",
        name: "hotel_deals_ads",
        component: HotelDealsAds
      },
      {
        path: "ad_promo_code",
        name: "ad_promo_code",
        component: AdPromoCode
      },
      {
        path: "co_brand",
        component: CoBrand,
        name: "co_brand",
        meta: {
          toolTag: "other"
        }
      },
      {
        path: "campaign_page",
        component: CampaignPage,
        name: "campaign_page",
      },
    ],
  },
  {
    path: NameLinkMap.performance.path,
    component: PerformanceRoute,
    children: [
      {
        name: "performance",
        path: "",
        redirect: {
          name: "performance_overview",
        },
      },
      {
        path: "performance_overview",
        component: PerformanceOverview, //default
        name: "performance_overview",
      },
      // {
      //   path: "order_list",
      //   component: OrderList,
      //   name: "order_list",
      // },
      {
        path: "ticket_list",
        component: TicketList,
        name: "ticket_list",
      },
      {
        path: "ticket_status_list",
        component: TicketStatusList,
        name: "ticket_status_list",
      },
    ],
  },
  {
    path: NameLinkMap.my_account.path,
    component: MyAccount,
    name: "my_account",
  },
  {
    path: "/change_website_name",
    component: ChangeWebSiteName,
    name: "change_website_name",
  },
  // Admin Route for internal users
  {
    path: NameLinkMap.admin.path,
    component: AdminRoute,
    beforeEnter: (to, from, next) => {
      // 针对 admin 模块, 只支持英文
      if (KLK_LANG !== "en") {
        Cookies.set("locale", "", {
          path: "/",
        });
        window.location.replace(to.fullPath);
      }
      if (isAdmin() || isManager()) {
        next();
      } else {
        window.location.replace("/" + window.KLK_LANG_PATH);
      }
    },
    children: [
      {
        path: "",
        name: "admin",
        redirect: {
          name: "affiliate_admin",
        },
      },
      {
        path: "affiliate_admin",
        component: AffiliateAdmin, // default
        name: "affiliate_admin",
        children: [
          // {
          //   path: ":uid/admin_order_list",
          //   component: AdminOrderList,
          //   name: "admin_order_list", // 注意: name的改动会影响 performance下order_list文件 mounted 函数中uid的判断
          // },
          {
            path: ":uid/admin_ad_management",
            component: AdminAdManagement,
            name: "admin_ad_management",
          },
          {
            path: ":uid/ad_list",
            component: AdminSpecialCustomerAdList,
            name: "admin_special_customer_ad_list",
          },
          {
            path: ":uid/admin_dashboard",
            component: Dashboard,
            name: "admin_dashboard",
          },
          {
            path: ":uid/admin_dashboard_kol",
            component: KreatorDashboard,
            name: "admin_dashboard_kol",
          },
          {
            path: ":uid/admin_ad_performance",
            component: AdPerformance,
            name: "admin_ad_performance",
          },
          {
            path: ":uid/admin_network_publisher_management",
            component: AdminNetworkPublisherManagement,
            name: "admin_network_publisher_management",
          },
        ],
      },
      {
        path: "partner_management",
        component: PartnerManagement,
        name: "partner_management",
        children: [
          {
            path: "notification_management",
            component: NotificationManagement,
            name: "notification_management",
          },
          {
            path: "deals_management",
            component: DealsManagement,
            name: "deals_management",
          },
          {
            path: "deals_ads",
            component: DealsAds,
            name: "deals_ads",
          },
          {
            path: "co_brand_management",
            component: CoBrandManagement,
            name: "co_brand_management",
            meta: {
              // is_admin_route_shown: false,
            },
          },
          {
            path: "payments_admin",
            component: PaymentsAdmin,
            name: "payments_admin",
          },
          {
            path: "special_allow_list",
            component: SpecialAllowList,
            name: "special_allow_list",
          },
          {
            path: ":uid/history",
            component: PaymentsAdminHistory,
            name: "payments_admin_history",
          },
          {
            path: "monthly_report/:uid?/:admin?",
            component: MonthlyReport,
            name: "monthly_report",
          },
          {
            path: "upload_type",
            component: UploadType,
            name: "upload_type",
            beforeEnter(to, from, next) {
              adminRouterCheck(next);
            },
          },
          {
            path: "dynamic_commission",
            component: DynamicCommission, //default
            name: "dynamic_commission",
          },
          {
            path: "dynamic_commission_rule/:pageType",
            component: DynamicCommissionRule,
            name: "dynamic_commission_rule",

            meta: {
              is_admin_route_shown: false,
            },
          },
          {
            path: "dynamic_commission_rule/:pageType/:ruleId",
            component: DynamicCommissionRule,
            name: "dynamic_commission_rule_detail",
            meta: {
              is_admin_route_shown: false,
            },
          },
          {
            path: "dynamic_commission_rule/:pageType/:ruleId/:versionId",
            component: DynamicCommissionRule,
            name: "dynamic_commission_rule_history",
            meta: {
              is_admin_route_shown: false,
            },
          },
          {
            path: "campaign_rule/:campaign_id",
            component: CampaignRule,
            name: "campaign_rule",
            beforeEnter(to, from, next) {
              adminRouterCheck(next);
            },
          },
          {
            path: "expense",
            name: "expense",
            component: Expense,
            beforeEnter(to, from, next) {
              adminRouterCheck(next);
            },
          },
          {
            path: "internal_user",
            component: InternalUser,
            name: "internal_user",
            beforeEnter(to, from, next) {
              adminRouterCheck(next);
            },
          },

          {
            path: "kreator_admin",
            component: KreatorManagement,
            name: "kreator_admin"
          }
        ],
      }
    ],
  },
  // ad demo
  {
    path: "/demo",
    component: AdDemo,
    name: "demo",
  },
  // ProductList
  {
    path: "/product-list",
    component: ProductList,
    name: "product-list",
  },
  {
    path: "/travel-trends-report",
    component: TavelTrendsReport,
    name: "travel-trends-report",
  },
  {
    path: "/resource_hub",
    component: ResourceHub,
    name: "resource_hub",
  },
];

const router = new VueRouter({
  mode: "history",
  routes: routes,
  base: KLK_LANG_PATH,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else {
      return {
        x: 0,
        y: 0,
      };
    }
  },
});
router.beforeEach((to, from, next) => {
  const fullPath = to.fullPath;
  const path = to.path;
  const whitePath = [ "/change_website_name" ];
  if (to.path.indexOf("activation_feedback") > -1) {
    next();
  } else if (
    USER_INFO &&
    USER_INFO.id &&
    USER_INFO.status === 0 &&
    to.path &&
    ![ "/resendlink", "/contact_us" ].includes(to.path)
  ) {
    next("/resendlink");
  } else if (
    USER_INFO &&
    USER_INFO.id &&
    +USER_INFO.status === 1 &&
    !whitePath.includes(path) &&
    shouldEditWebsite()
  ) {
    // 已经登陆, 并且 path 不等于 /change_website_name 时, 进入 /change_website_name 页面
    next(`/change_website_name?redirect=${encodeURIComponent(fullPath)}`);
  } else {
    next();
  }
});

function getDomain() {
  return document.domain || location.hostname;
}

let trackPageRenderSuccessOnce = _.once((pathname) => {
  klkClientReport.reportPerformance({
    pageName: pathname
  });
});

router.afterEach((to /* , from */) => {
  // Google Tag Manager for custom dimension UID
  if (USER_INFO.id) {
    const data = {
      uid: USER_INFO.id.toString(),
      loginStatus: true,
    };
    if (!isAdmin()) {
      data.partnerProgram = isKreator() ? 'Kreator' : 'Affiliate';
    }

    window.dataLayer.push(data);
  } else {
    const data = {
      loginStatus: false,
    };
    window.dataLayer.push(data);
  }
  trackPageRenderSuccessOnce(to.name);
});
// exports.router = router;

export { router };
