<template>
  <li :class="[prefixCls + '_wrapper']" v-show="!hidden">
    <div :class="[prefixCls + '_title']">{{ label }}</div>
    <ul>
      <li :class="[prefixCls]" ref="options"><slot></slot></li>
    </ul>
  </li>
</template>
<script>
const prefixCls = "klk_select_group";

export default {
  name: "KlkOptionGroup",
  props: {
    label: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      prefixCls: prefixCls,
      hidden: false, // for search
    };
  },
  methods: {
    queryChange() {
      this.$nextTick(() => {
        const options = this.$refs.options.querySelectorAll(".klk_option");
        var hasVisibleOption = false;
        for (var i = 0; i < options.length; i++) {
          if (options[i].style.display !== "none") {
            hasVisibleOption = true;
            break;
          }
        }
        this.hidden = !hasVisibleOption;
      });
    },
  },
  mounted() {
    this.$on("on-query-change", () => {
      this.queryChange();
      return true;
    });
  },
};
</script>
