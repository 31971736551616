<template>
  <div :class="itemClasses">
    <div :class="headerClasses" @click="toggle">
      <!-- <Icon type="arrow-right-b"></Icon> -->
      <slot></slot>
      <svg class="svg_wrapper klook-icon-arrow-down">
        <use xlink:href="#arrow-down"></use>
      </svg>
    </div>
    <!-- <transition name="slide-toggle">
            <div :class="contentClasses" v-show="isActive">
                <div :class="boxClasses"><slot name="content"></slot></div>
            </div>
        </transition> -->
    <collapse-transition>
      <div :class="contentClasses" v-show="isActive">
        <div :class="boxClasses"><slot name="content"></slot></div>
      </div>
    </collapse-transition>
  </div>
</template>
<script>
import CollapseTransition from "../../base/collapse_transition";

const prefixCls = "klk_panel";

export default {
  name: "KlkPanel",
  props: {
    name: {
      type: String,
    },
  },
  components: {
    CollapseTransition,
  },
  data() {
    return {
      index: 0, // use index for default when name is null
      isActive: false,
    };
  },
  computed: {
    itemClasses() {
      return [
        `${prefixCls}`,
        {
          [`${prefixCls}_active`]: this.isActive,
        },
      ];
    },
    headerClasses() {
      return `${prefixCls}_header`;
    },
    contentClasses() {
      return `${prefixCls}_content`;
    },
    boxClasses() {
      return `${prefixCls}_content_box`;
    },
  },
  methods: {
    toggle() {
      this.$parent.toggle({
        name: this.name || this.index,
        isActive: this.isActive,
      });
    },
  },
};
</script>
