
<div :class="classes">
  <div ref="nav" :class="[prefixCls + '_nav']" :style="navStyle">
    <div class="klk_tabs_bar"></div>
    <div
      :class="tabCls(item)"
      v-for="(item, index) in navList"
      :key="index"
      @click="eventTargetType === 'click' && handleChange(index)"
      @mouseover="eventTargetType === 'hover' && handleChange(index)"
    >
      <nav-item :label="item.label" ></nav-item> 
    </div>
  </div>

  <div :class="contentClasses" v-show="showChildContent">
    <slot></slot>
  </div>
</div>
