import Vue from "vue";
import Vuex from "vuex";
import createPersistedState from "vuex-persistedstate";
import actions from "./actions";
import mutations from "./mutations";
import getters from "./getters";
import * as globalObjModule from "./modules/global_obj.js";
import * as joinNowModule from "./modules/join_now.js";
import * as accountModule from "./modules/account.js";
import * as gtmEventModule from "./modules/gtm_event.js";
import * as experimentModule from "./modules/experiment.js";

Vue.use(Vuex);

const createStore = function () {
  return new Vuex.Store({
    modules: {
      globalObjModule: globalObjModule,
      joinNowModule: joinNowModule,
      gtmEventModule: gtmEventModule,
      accountModule: accountModule,
      experimentModule: experimentModule,
    },
    actions,
    mutations,
    getters,
    plugins: [
      createPersistedState({
        paths: [ "joinNowModule.secret", "globalObjModule.clickNewGategoryFlags" ], // 持久化的store对象
        filter: (mutation) => {
          return [ "update_secret", "UPDATE_NEW_CATEGORY_FLAG" ].includes(mutation.type);
        },
      }),
    ],
  });
};

export const store = createStore();
