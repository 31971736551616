
<div class="login_form" v-loading="is_logging_in" data-spm-page="LogInPopUp">
  <div class="klk_dialog_title">
    {{ $t("LOGIN_FORM_affiliate_log_in") }}
  </div>
  <p class="klk_dialog_prompt">
    {{ $t("can_log_with_klook") }}
  </p>
  <p class="error_tips" :data-spm-module="`Error?oid=error_${login_error_code}&ext=${encodeURIComponent(JSON.stringify({
        msg: login_error,
      }))}`" v-show="login_error !== ''">
    <span v-html="login_error" />
    <span
      @click="forgot_password"
      style="cursor: pointer; text-decoration: underline; color: #f5222d"
      v-if="showResetPassword"
    >{{ $t("FORGOT_FORM_reset_password") }}</span
    >
  </p>
  <div class="klk_dialog_body">
    <klk-form :rules="formRules" :model="form_data" ref="ref_form_login">
      <klk-form-item prop="email">
        <klk-input
          :placeholder="$t('MULTIPLE_email')"
          v-on:input="login_error = ''"
          v-model.trim="form_data.email"
        ></klk-input>
      </klk-form-item>
      <klk-form-item prop="password">
        <klk-input
          :placeholder="$t('MULTIPLE_password')"
          type="password"
          v-on:input="login_error = ''"
          v-model="form_data.password"
        ></klk-input>
      </klk-form-item>

      <klk-form-item prop="affiliate_agreement">
        <klk-checkbox v-model="form_data.affiliate_agreement">
          <span
            class="affiliate_agreement"
            v-html="read_affiliate_agreement"
          ></span>
          <a
            :data-spm-module="'AffiliateAgreement'"
            data-spm-virtual-item="__virtual"
            class="theme_a"
            style="font-size: 14px"
            target="_blank"
            :href="affiliate_agreement_href"
          >
            {{ $t("LOGIN_FORM_login_affiliate_agreement") }}</a
          >
        </klk-checkbox>
      </klk-form-item>
      <klk-form-item>
        <div
          class="iht_remember_me"
          :data-spm-module="'RememberMe?trg=manual'"
          :data-spm-virtual-item="`__virtual?ext=${JSON.stringify({isCheck: !form_data.remeber_me ? 'Check':
              'Uncheck'})}`">
        </div>
        <div class="password-info-wrapper">
          <div class="remeber_me">
            <klk-checkbox
              @on-change="$inhouse.track('action', '.iht_remember_me')"
              v-model="form_data.remeber_me">
              <span>
              {{ $t("LOGIN_FORM_remeber_me") }}
              </span>
            </klk-checkbox>
          </div>
          <div
            :data-spm-module="'ForgotPassword'"
            :data-spm-virtual-item="`__virtual`"
            class="forgot"
            @click="forgot_password">
            {{ $t("LOGIN_FORM_forgot_password") }}
          </div>
        </div>
      </klk-form-item>
    </klk-form>
  </div>
  <div class="klk_dialog_footer" id="klk_dialog_footer">
    <button
      :data-spm-module="'LoginIn'"
      :data-spm-virtual-item="`__virtual`"
      id="login_btn"
      type="button"
      class="theme_btn_base btn_full log_in g-recaptcha"
      name="button"
      data-sitekey="6LdxrlUUAAAAAKenOqfrpCwQ64JncIaQ300sju2m"
      @click="captchaStart"
    >
      {{ $t("MULTIPLE_log_in") }}
    </button>
    <div
      class="login_ways_wrapper"
      v-show="!showLoginWayTips"
      :data-spm-module="'3rdParty'"
      :data-spm-virtual-item="`__virtual`"
    >
      <div v-for="type in loginWays" @click="comingsoon(type)">
        <klk-icon class="login_ways_icon" :type="type"></klk-icon>
      </div>
    </div>
    <div class="login_way_tips" v-show="showLoginWayTips">
      {{ $t("89221") }}
    </div>
    <div class="sign_up_wrapper">
      <button
        :data-spm-module="'SignUp'"
        :data-spm-virtual-item="`__virtual`"
        type="text" class="btn_text" @click="sign_up">
        {{ $t("MULTIPLE_sign_up") }}
      </button>
    </div>
  </div>
</div>
