<template>
  <li
    :class="classes"
    v-show="!hidden"
    @click="triggerSelect(value)"
    :value="value"
  >
    <slot>{{ showLabel }}</slot>
  </li>
</template>
<script>
import Emitter from "../../../mixins/emitter";
const prefixCls = "klk_option";
export default {
  name: "KlkOption",
  mixins: [Emitter],
  props: {
    value: {
      require: true,
    },
    label: {
      type: [String, Number],
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      selected: false,
      hidden: false,
      isFocus: false,
      searchLabel: "",
    };
  },
  computed: {
    classes() {
      return [
        prefixCls,
        {
          [`${prefixCls}_selected`]: this.selected,
          [`${prefixCls}_focus`]: this.isFocus,
        },
      ];
    },
    showLabel() {
      return this.label ? this.label : this.value;
    },
  },
  mounted() {
    this.updateSearchLabel();
    this.dispatch("KlkSelect", "append");
    this.$on("on-query-change", (val) => {
      this.queryChange(val);
    });
  },
  methods: {
    updateSearchLabel() {
      this.searchLabel = this.$el.textContent;
    },
    queryChange(query) {
      const parsedQuery = query
        .toString()
        .replace(/(\^|\(|\)|\[|\]|\$|\*|\+|\.|\?|\\|\{|\}|\|)/g, "\\$1");
      this.hidden = !new RegExp(parsedQuery, "i").test(this.searchLabel);
    },
    triggerSelect(value) {
      console.log(this.disabled);
      if (this.disabled) {
        return false;
      }
      this.dispatch("KlkSelect", "on-select-selected", value || this.value);
    },
  },
};
</script>
