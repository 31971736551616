import { urlObj } from "@/comm/common_data";
import klook from 'klook'
import Cookies from "js-cookie";
const SET_KEPLER_ID = "SET_KEPLER_ID";
const SET_EXPERIMENTS_HIT_LIST = "SET_EXPERIMENTS_HIT_LIST";
const SET_TINTED_LIST = "SET_TINTED_LIST";
const getExperimentsHitList = () => {
  return new Promise((resolve, reject) => {
    klook
      .ajaxGet(urlObj.kepler_experiments, (resp) => {
        resolve(resp);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

const state = () => ({
  keplerId: Cookies.get("kepler_id"),
  experimentsGroup: {},
  homeExpGroup: {},
  tintedList: "",
});

const mutations = {
  [SET_KEPLER_ID](state, id) {
    state.keplerId = id;
  },
  [SET_EXPERIMENTS_HIT_LIST](state, experimentsHitGroup) {
    // 重新结构化Store挂载的全局实验列表
    const tmpExperimentsGroup = {};
    experimentsHitGroup.map((exp) => {
      tmpExperimentsGroup[exp.experiment_name] = {
        id: exp.experiment_id,
        group: {
          id: exp.group_id,
          name: exp.group_name,
        },
        // 避免同一个实验多次发送埋点数据的状态标记位
        sendStatus: false,
      };
    });
    state.experimentsGroup = tmpExperimentsGroup;
  },
  [SET_TINTED_LIST](state, tintedList) {
    state.tintedList = tintedList;
  },
  SET_HOME_HOMEEXPGROUP(state) {
    let experimentName = "AFF_K0008_Homepage";
    const { [experimentName]: hitExp } = state.experimentsGroup || {};
    if (hitExp) {
      state.homeExpGroup = hitExp;
    }
  },
};

const actions = {
  async getExperimentsHitList({ commit }) {
    try {
      // 调用kepler 服务，获取命中实验
      const kepler_id = Cookies.get("kepler_id");
      if (kepler_id) {
        const res = await getExperimentsHitList();
        // 染色逻辑
        if (res && res.result) {
          const expsList = res.result.exps || [];
          const dyeList = [];
          expsList.forEach((item) => {
            if (item.need_dye) {
              dyeList.push(`${item.experiment_id}:${item.group_id}`);
            }
          });
          if (dyeList.length) {
            // 后端实验
            commit(SET_TINTED_LIST, JSON.stringify({ kepler: dyeList }));
          }
        }
        // 保存所有命中实验
        window.tintedList = JSON.stringify({ kepler: res.result.exps || [] });
        commit(SET_EXPERIMENTS_HIT_LIST, res.result.exps || []);
        commit("SET_HOME_HOMEEXPGROUP");
      } else {
        console.warn("缺少 kepler_id");
        commit(SET_EXPERIMENTS_HIT_LIST, []);
      }
    } catch (e) {
      commit(SET_EXPERIMENTS_HIT_LIST, []);
    }
  },
};

let getters = {
  isHomePageExperiment(state) {
    if (state.homeExpGroup.group) {
      let { id, name } = state.homeExpGroup.group;
      if (id === "4489" || name === "AFF_K0008_Homepage_Variant") {
        return true;
      }
    }
  },
};

export { state, getters, actions, mutations };
