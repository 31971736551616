
<div
  class="klk-switch"
  :class="{ 'is-disabled': disabled, 'status-on': value }"
  @click="handleClick"
>
  <!-- <span>
          {{  value ? onText: offText }}
      </span> -->
  <span class="klk-switch-circle"></span>
</div>
