import urlObj from "./url";
import formRules, { validateKolWebsiteUrl, DEMO_WEBSITE_URL } from "./form_rules";
import klook from "./core";
import { $t } from "./utils";

const requestWithCache = (function () {
  var result = {};
  return function (url, cacheKey) {
    cacheKey = cacheKey || url;
    return new Promise(function (resolve) {
      if (result[cacheKey]) {
        resolve(result[cacheKey]);
      } else {
        klook.ajaxGet(url, (resp) => {
          if (resp.success) {
            result[cacheKey] = resp.result;
            resolve(result[cacheKey]);
          } else {
            resolve(resp);
          }
        });
      }
    });
  };
})();

const SOURCE_TYPE_MAP = () => {
  return {
    0: "search_engine",
    1: "klook_website",
    2: "travel_websites",
    3: "facebook",
    4: "instagram",
    5: "affiliate_network",
    6: "recommendation_from_online_articles",
    7: "recommendation_from_a_friend",
    8: "contacted_by_klook_affiliates_team",
    "-1": "source_type_other",
  };
};

const WEBSITE_TYPE_MAP = () => [
  {
    value: "13",
    name: $t("COMMON_DATA_sitetype_affiliate_network"),
  },
  {
    value: "1",
    name: $t("COMMON_DATA_sitetype_airline"),
  },
  { value: "6", name: $t("COMMON_DATA_sitetype_coupon") },
  { value: "7", name: $t("COMMON_DATA_sitetype_ecommerce") },
  { value: "11", name: $t("COMMON_DATA_sitetype_finance") },
  { value: "5", name: $t("COMMON_DATA_sitetype_hotel") },
  { value: "14", name: $t("COMMON_DATA_sitetype_influencer") },
  { value: "8", name: $t("COMMON_DATA_sitetype_membership") },
  { value: "4", name: $t("COMMON_DATA_sitetype_news") },
  { value: "10", name: $t("COMMON_DATA_sitetype_discussion") },
  { value: "3", name: $t("COMMON_DATA_sitetype_ota") },
  { value: "9", name: $t("COMMON_DATA_sitetype_telecom") },
  { value: "12", name: $t("COMMON_DATA_sitetype_tourism") },
  { value: "0", name: $t("COMMON_DATA_sitetype_blog") },
  { value: "2", name: $t("COMMON_DATA_sitetype_trip_planning_tool") },
];




const FOLLWERS_LEVEL_MAP = () => ({
  "l1": `< 1,000`,
  "l2": '1,000 - 9,999',
  "l3": '10,000 - 49,999',
  "l4": '50,000 - 500,000',
  "l5": `> 500,000`
})

const UV_LEVEL_MAP = {
  1: "1-1000",
  2: "1,001-10,000",
  3: "10,001-100,000",
  4: "100,001-500,000",
  5: "500,001-1,000,000",
  6: "1,000,000+",
};

const AJAX_ERROR_CODE_OBJ = {
  success: 0,
  user_not_logged_in: -10001,
  param_invalid: -10002,
  user_already_exist: -10003,
  cant_find_user: -10004,
  need_auth: -10005,
  user_not_authed: -10006,
  auth_fail: -10007,
  admin_required: -10008,
  register_fail: -10009,
  ip_exceed_limit: -10010,
  email_exceed_limit: -10011,
  user_not_admin: -10012,
  user_declined: -10013,
};

// {
//    "city_id": 2,
//    "city_name": "Hong Kong",
//    "city_seo": "hong-kong"
// },
//range => areas => countries => cities
/*
    防止基础服务挂导致 RANGES = undefined 报错页面显示不出来
 */
window.RANGES = (window.RANGES && RANGES) || [];
const CITY_LIST = RANGES.reduce((res, cur) => {
  return res.concat(
    cur.countries.reduce((res2, cur2) => res2.concat(cur2.cities), [])
  );
}, []);

const COUNTRY_LIST = RANGES.reduce((res, cur) => {
  return res.concat(cur.countries);
}, []);

const ID_COUNTRY_MAP = {};
const ID_CITY_MAP = {};
RANGES.forEach((destination) => {
  destination.countries.forEach((country) => {
    ID_COUNTRY_MAP[country.country_id] = country;
    country.cities.forEach((city) => {
      ID_CITY_MAP[city.city_id] = city;
    });
  });
});

const CDN_PREFIX = "https://cdn.klook.com/s/dist_web/klook-affiliate-front";

// TODO:
var script_url_prefix = CDN_PREFIX;
// var script_url_prefix =
//   window.KLK_ENV && window.KLK_ENV == "prd"
//     ? CDN_PREFIX
//     : "//" + window.location.host;
var script_content_prefix = `(function(d, sc, u) { var s = d.createElement(sc), p = d.getElementsByTagName(sc)[0]; s.type = 'text/javascript'; s.async = true; s.src = u; p.parentNode.insertBefore(s,p); })(document, 'script', '${script_url_prefix}/s/dist/desktop/`;
const AD_SCRIPT = (function () {
  return script_content_prefix + `affiliate_base_v3.2.js')`;
})();

// 定义 widget 脚本类型， 传入 vertical 名称 和 对应 iframe 版本号
const AD_SCRIPT_WIDGET_TEMPLATE = (vertical, version) => {
  return `${script_content_prefix}${vertical}_v${version}.js')`;
};

const AD_SCRIPT_SEARCH_VERTICAL_TEMPLATE = function (version) {
  return script_content_prefix + `search_vertical_v${version}.js')`;
};

const AD_TYPE_LIST = [
  "link",
  "search",
  "activity",
  "banner",
  "code",
  "dynamic_widget",
  "static_widget",
];

const currencyDecimalplace = {
  CHF: 2,
  SGD: 2,
  AUD: 2,
  JOD: 2,
  USD: 2,
  EUR: 2,
  GBP: 2,
  MYR: 2,
  OMR: 2,
  NZD: 2,
  CAD: 2,
};

// payment_admin.vue 接口返回多语言字段的映射
const PAYMENTS_KEY_MAP = () => {
  return {
    alipay_id: $t("COMMON_DATA_alipay_id"),
    country_code: $t("MULTIPLE_country_code"),
    bank_name_branch_name: $t("MULTIPLE_bank_name_branch_name"),
    bank_name: $t("MULTIPLE_bank_name"),
    branch_code: $t("MULTIPLE_branch_code"),
    bank_account_name: $t("MULTIPLE_account_name"),
    bank_account_id: $t("MULTIPLE_account_number"),
    swift_code: $t("MULTIPLE_swift_code"),
    iban_number: $t("MULTIPLE_iban_number"),
    sort_code: $t("MULTIPLE_sort_code"),
    phone_number: $t("MULTIPLE_phone_number"),
    paypal_id: $t("COMMON_DATA_paypal_id"),
    bank_swift_code: $t("COMMON_DATA_bank_swift_code"),
    bank_other_info: $t("COMMON_DATA_bank_other_info"),
    bank_code: $t("MULTIPLE_bank_code"),
    bsb_code: $t("MULTIPLE_bsb_code"),
    ifsc_code: $t("MULTIPLE_ifsc_code"),
    routing_code: $t("MULTIPLE_routing_code"),
    bank_address: $t("108042")
  };
};

function generateActivityUrlByActivityId(activity_id) {
  var lang_path = KLK_LANG_PATH;

  return `https://www.klook.com/${lang_path}activity/${activity_id}-/`;
}

// affiliate type 五种类型, admin->affiliate admin模块下使用
const AFFILIATE_TYPE = () => {
  return {
    head: $t("COMMON_DATA_affiliate_type_head"),
    mid: $t("COMMON_DATA_affiliate_type_mid"),
    long: $t("COMMON_DATA_affiliate_type_long"),
    // key: $t("COMMON_DATA_affiliate_type_key"),
    network: $t("COMMON_DATA_affiliate_type_network"),
  };
};

// admin_edit_settings_dialog.vue. commission_model的映射
const COMMISSION_MODEL = () => {
  return {
    affiliate: $t("COMMON_DATA_affiliate"),
    tracking_only: $t("COMMON_DATA_tracking_only"),
  };
};

// GTM event tracking 埋点上报
const WIDGET_MAP_GOOGLE_TRACKING = {
  dynamicWidgets: "Dynamic Widgets",
  deals: "Deals Widgets",
  link: "Text Links",
  search: "Search Box",
  search_vertical: "Search Box",
  banner: "Static Banners",
  code: "Promo Code",
  activity: "Activity Banners",
  co_brand: "Co-brand",
  campaign_page: "Customized Landing Page",
};

// render广告页面 多语言映射
const RENDER_LANGUAGE_MAP = {
  "en-US": {
    activity_reviews: "Reviews",
    view_more: "View More",
    search: "Search",
    search_placeholder: "Search by destination or activity",
    search_title: "Discover and book amazing things to do at exclusive prices",
    banner_book_now: "BOOK NOW",
    discount: "%s% OFF",
  },
  "zh-CN": {
    activity_reviews: "评价",
    view_more: "查看更多",
    search: "搜索",
    search_placeholder: "输入目的地/景点/活动",
    search_title: "发现更好玩的世界，预订独一无二的旅行体验",
    banner_book_now: "立即预订",
    discount: "%s折",
  },
  "zh-TW": {
    activity_reviews: "評價",
    view_more: "查看更多",
    search: "搜索",
    search_placeholder: "輸入目的地/景點/活動",
    search_title: "發現更好玩的世界，預訂獨一無二的旅行體驗",
    banner_book_now: "立即預訂",
    discount: "%s折",
  },
  "zh-HK": {
    activity_reviews: "評論",
    view_more: "查看更多",
    search: "搜索",
    search_placeholder: "輸入目的地/景點/活動",
    search_title: "發現更好玩的世界，預訂獨一無二的旅行體驗",
    banner_book_now: "立即預訂",
    discount: "%s折",
  },
  ko: {
    activity_reviews: "리뷰",
    view_more: "더 보기",
    search: "검색",
    search_placeholder: "도시 또는 액티비티 검색",
    search_title: "더 넓은 세상을 경험하고, 잊지 못할 순간을 예약하세요.",
    banner_book_now: "지금 예약하기",
    discount: "%s% 할인",
  },
  th: {
    activity_reviews: "ความคิดเห็น",
    view_more: "ดูเพิ่มเติม",
    search: "ค้นหา",
    search_placeholder:
      "ค้นหาโดยใช้จุดหมายปลายทางหรือกิจกรรม ค้นหาด้วยจุดหมายปลายทางหรือกิจกรรม",
    search_title: "สำรวจและจองกิจกรรมที่น่าสนใจมากมายในราคาสุดพิเศษ",
    banner_book_now: "จองตอนนี้",
    discount: "ลด %s%",
  },
  vi: {
    activity_reviews: "đánh giá",
    view_more: "Xem thêm",
    search: "Tìm kiếm",
    search_placeholder: "Tìm theo điểm đến hoặc hoạt động",
    search_title:
      "Khám phá và đặt trước các hoạt động du lịch đặc sắc với giá độc quyền",
    banner_book_now: "Đặt ngay",
    discount: "GIÁM GIÁ %s%",
  },
  id: {
    activity_reviews: "ulasan",
    view_more: "Lihat Selengkapnya",
    search: "Cari",
    search_placeholder: "Cari berdasarkan destinasi atau aktivitas",
    search_title:
      "Temukan dan pesanlah aktivitas seru dengan harga yang eksklusif",
    banner_book_now: "PESAN SEKARANG",
    discount: "DISKON %s%",
  },
  ja: {
    activity_reviews: "件のクチコミ",
    view_more: "もっと見る",
    search: "検索",
    search_placeholder: "目的地・アクティビティから検索",
    search_title:
      "現地のワクワクを見つける手がかりに。旅を思いのままにアレンジしよう。",
    banner_book_now: "詳細を見る",
    discount: "%s% OFF",
  },
  fr: {
    activity_reviews: "La revue",
    view_more: "voir plus",
    search: "Search",
    search_placeholder: "Search by destination or activity",
    search_title: "Réservez des activités inoubliables aux meilleurs prix",
    banner_book_now: "BOOK NOW",
    discount: "%s% OFF",
  },
  de: {
    activity_reviews: "Rezension",
    view_more: "Mehr sehen",
    search: "Search",
    search_placeholder: "Search by destination or activity",
    search_title:
      "Entdecke und buche einzigartige Aktivitäten zu exklusiven Preisen",
    banner_book_now: "BOOK NOW",
    discount: "%s% OFF",
  },
  es: {
    activity_reviews: "Revisión",
    view_more: "ver más",
    search: "Search",
    search_placeholder: "Search by destination or activity",
    search_title: "Scopri e prenota attività straordinarie a prezzi esclusivi",
    banner_book_now: "BOOK NOW",
    discount: "%s% OFF",
  },
  ru: {
    activity_reviews: "отзыв",
    view_more: "узнать больше",
    search: "Search",
    search_placeholder: "Search by destination or activity",
    search_title:
      "Находите удивительные варианты досуга и бронируйте их по эксклюзивным ценам",
    banner_book_now: "BOOK NOW",
    discount: "%s% OFF",
  },
};

// searchBox 渲染页面埋点关系表
// id: 埋点数据
let common_obj = {
  event: "adDisplay",
  eventCategory: "My Ads",
  eventAction: "Partner Website",
  eventLabel: "Search Box",
};
const SEARCH_VERTICAL_FILLING_POINT_MAP = {
  11: {
    ...common_obj,
    verticalName: "Attractions & Shows",
  },
  12: {
    ...common_obj,
    verticalName: "Tours & Sightseeing",
  },
  13: {
    ...common_obj,
    verticalName: "Activities & Experiences",
  },
  14: {
    ...common_obj,
    verticalName: "Best Food & Must Eats",
  },
  21: {
    ...common_obj,
    verticalName: "JR Pass",
  },
  22: {
    ...common_obj,
    verticalName: "Europe Train",
  },
  23: {
    ...common_obj,
    verticalName: "China Train",
  },
  24: {
    ...common_obj,
    verticalName: "Taiwan Train",
  },
  25: {
    ...common_obj,
    verticalName: "Vietnam Train",
  },
  31: {
    ...common_obj,
    verticalName: "WiFi & SIM Card",
  },
  41: {
    ...common_obj,
    verticalName: "Transport & Travel Services",
  },
};

const IFRAME_SCRIPT = `
<script type="text/javascript">
  (function (d, sc, u) {
    var s = d.createElement(sc),
      p = d.getElementsByTagName(sc)[0];
    s.type = "text/javascript";
    s.async = true;
    s.src = u;
    p.parentNode.insertBefore(s, p);
  })(
    document,
    "script",
    "${location.protocol}//${location.host}/widget/fetch-iframe-init.js"
  );
</script>
`;

const  KREATOR_ACTION_LIST = {
  'Approved': 2,
  'Rejected': 3,
  'Pending': 1
}

const TOOL_CARD_CONFIG = () => ({
  "text_link": {
    title: $t("MULTIPLE_text_links"),
    desc:  $t("MULTIPLE_text_links_desc"),
    path: "/my_ads/text_links",
    bgColor: "#07B295",
    needSetType: true,
    gaLabel: "Text Links",
    ihtKey: 'TextLinks',
  },
  "dynamic_widgets": {
    title: $t("MULTIPLE_dynamic_widgets"),
    desc:  $t("dashboard_widgets_tip"),
    path: "/my_ads/dynaminc_widgets",
    bgColor: "#F7AD2D",
    needSetType: true,
    gaLabel: "Dynamic Widgets",
    ihtKey: "DynamicWidgets"
  },
  "promo_code": {
    title: $t("MULTIPLE_promo_codes"),
    desc:  $t("MULTIPLE_promo_codes_desc"),
    path: "/my_ads/ad_promo_code",
    bgColor:"#886FEC",
    gaLabel: "Promo Codes",
    ihtKey: 'PromoCodes',
  },
  "co_brand": {
    title: $t("MULTIPLE_BRAND"),
    desc: $t("DASHBOARD_dashboard_desc"),
    bgColor: "#1FACBE",
    path: "/my_ads/co_brand",
    gaLabel: "Co-branding",
    ihtKey: 'CoBranding',
  },
"search_vertical": {
    title: $t("MULTIPLE_search_box"),
    desc: $t("MULTIPLE_search_box_desc"),
    bgColor:  "#F6754C",
    path: "/my_ads/ad_tools/search_vertical",
    gaLabel: "Search Box",
    ihtKey: "SearchBox"
},
"static_banner": {
    title: $t("MULTIPLE_static_banners"),
    desc: $t("MULTIPLE_static_banners_desc"),
    bgColor:  "#4F95FF",
    path: "/my_ads/ad_tools/banner",
    gaLabel: "Static banners",
    ihtKey: "StaticBanners"
},
"activity_banner": {
    title: $t("MULTIPLE_activity_banners"),
    desc: $t("MULTIPLE_activity_banner_desc"),
    bgColor:  "#4C87E6",
    path: "/my_ads/ad_tools/activity",
    gaLabel: "Activity Banners",
    ihtKey: "ActivityBanners",
},
"hotel_deals": {
  title: $t("77359"),
  desc: $t("77360"),
  bgColor:  "#4C87E6",
  path: "/my_ads/hotel_deals",
  gaLabel: "Hotel deals",
  ihtKey: "HotelDeals",
},
"coming_soon": {
  title: $t("106921"),
  desc: $t("106922"),
  bgColor: "#E0E0E0",
  ihtKey: "StayTuned",
}

})

// home. 前缀标识 dashboard tab
// tool. 前缀标识 ad tool tab
const hasNewFlagKeys = [
  "home.car_rental.text_link",
  "tool.text_links.car_rental",
  "home.general.static_banner",
  "home.general.promo_code",
  "tool.more_ad_tools.static_banner",
  "tool.ad_promo_code",
  "tool.more_ad_tools.static_banner.Shinkansen",
];

const SIGNUP_TYPE_KEY = "signup_user_type";
const FROM_LOGIN_KEY = "from_login_flag";

const COMMISSION_COMMON_DATA  = {
  RULE_TYPE: [
    {label: 'Partner rule', value: 100},
    {label: 'Regional rule', value: 101},
    {label: 'System rule', value: 102}
  ],
  RULE_FRONT_LEVEL: [
    {
      label: 'Category rule',
      value: 'category',
      children: [1]
    },
    {
      label: 'SPU rule',
      value: 'spu_rule',
      children: [3, 2],
      childLabel: 'SPU type'
    },
    {
      label: 'Product type rule',
      value: 'product_type',
      children: [4],
      childLabel: 'Product type'
    }
  ],
  RULE_LEVEL: [
    {
      label: 'Category',
      value: 1
    },
    {
      label: 'SPU (TTD)',
      value: 3
    },
    {
      label: 'SPU (Hotel)',
      value: 2
    },
    {
      label: 'Product type (Stay+)',
      value: 4
    }],
  // index 对应为status 的枚举值
  // actionsOrder 将 ACTIONS_ARR 操作类型 index 放进数组，进行排序即可
  STATUS: [
    { label: 'Pending', actionsOrder: [0, 1, 4, 5, 6]},
    { label: 'Live', actionsOrder: [0, 1, 4, 2, 5]},
   { label: 'Terminated',actionsOrder: [0, 5, 6] },
   {label: 'Paused', actionsOrder: [3, 0, 1, 4, 5 ]},
   {label: 'Expired', actionsOrder: [0, 5]}
  ],

  ACTIONS_ARR: [
    {
      order: 0,
      label: "View"
    },
    {
      order: 1,
      label: "Edit"
    },
    {
      order: 2,
      label: "Pause",
      actionKey: 2
    },
    {
      order: 3,
      label: "Resume",
      actionKey: 3
    },
    {
      order: 4,
      label: "Terminate",
      actionKey: 1
    },
    {
      order: 5,
      label: "Clone"
    },
    {
      order: 6,
      label: "Delete",
      actionKey: 4
    }
  ],
  BOOKING_TYPE_LIST: [
    {
      label: "Stay+",
      value: 4
    }
  ],
  VERSION_STATUS: [
    {
      value: 0,
      label: "Invalid",
    },
    {
      value: 1,
      label: "Valid",
    }
  ],
  BASED_MODEL: [
    {
      value: 1,
      label: "AID-based",
    },
    {
      value: 2,
      label: "Kreator code-based",
    },
  ],
}

const ADMIN_KREATOR_COUPONS_DATA = {
  discountMapping: {
    new_user: "Discount for New User",
    exist_user: "Discount for Existing User",
  },
};

export {
  WEBSITE_TYPE_MAP,
  urlObj,
  formRules,
  CITY_LIST,
  ID_CITY_MAP,
  COUNTRY_LIST,
  ID_COUNTRY_MAP,
  AD_SCRIPT,
  AD_SCRIPT_WIDGET_TEMPLATE,
  AD_SCRIPT_SEARCH_VERTICAL_TEMPLATE,
  UV_LEVEL_MAP,
  AD_TYPE_LIST,
  requestWithCache,
  AJAX_ERROR_CODE_OBJ,
  generateActivityUrlByActivityId,
  CDN_PREFIX,
  PAYMENTS_KEY_MAP,
  AFFILIATE_TYPE,
  COMMISSION_MODEL,
  WIDGET_MAP_GOOGLE_TRACKING,
  SOURCE_TYPE_MAP,
  RENDER_LANGUAGE_MAP,
  SEARCH_VERTICAL_FILLING_POINT_MAP,
  currencyDecimalplace,
  IFRAME_SCRIPT,
  FOLLWERS_LEVEL_MAP,
  KREATOR_ACTION_LIST,
  DEMO_WEBSITE_URL,
  validateKolWebsiteUrl,
  TOOL_CARD_CONFIG,
  hasNewFlagKeys,
  SIGNUP_TYPE_KEY,
  FROM_LOGIN_KEY,
  COMMISSION_COMMON_DATA,
  ADMIN_KREATOR_COUPONS_DATA,
};
