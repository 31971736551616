
<div id="klk-faq-feedback" class="klk-faq-feedback">
  <div class="klk-faq-feedback-wrapper">
    <span
      @click="handleHelpfulFn(1)"
      :class="isHelpful == 1 ? 'helpful' : ''"
    >
      <img :src="getCurrentIcon(1)" alt="" /> {{ helpfulText }}
    </span>
    <span
      @click="handleHelpfulFn(2)"
      :class="isHelpful == 2 ? 'not-helpful' : ''"
    >
      <img :src="getCurrentIcon(2)" alt="" /> {{ nothelpfulText }}
    </span>
  </div>
  <!-- 只有在unhelpful & unsubmit -->
  <template v-if="isHelpful === 2 && !isSubmit">
    <textarea
      class="klk-faq-feedback-context"
      v-model="feedbackContext"
      :placeholder="placeholder"
      :rows="4"
      :maxlength="250"
    ></textarea>
    <div class="klk-faq-feedback-footer">
      <span class="confirm" :style="style" @click="submitData">
        {{ btnSubmitText }}
      </span>
    </div>
  </template>
  <div class="klk-faq-feedback-back">
    <span v-if="isHelpful === 1">{{ helpfulFeedback }}</span>
    <span v-if="isHelpful === 2 && isSubmit">{{ nothelpfulFeedback }}</span>
  </div>
</div>
