
<div :class="classes" :style="itemStyles">
  <!-- <label :class="[prefixCls + '-label']" :for="labelFor" :style="labelStyles" v-if="label || $slots.label"><slot name="label">{{ label }}</slot></label> -->
  <!-- <div class="klk_input_label"><slot name="label">{{ label }}</slot></div> -->
  <div class="klk_input_label">
    <slot name="label"
      ><span class="required_mark" v-show="!!required">*</span
      >{{ label }}</slot
    >
  </div>
  <div :class="[prefixCls + '_content']" :style="contentStyles">
    <slot></slot>
    <!-- <transition name="fade"> -->
    <div :class="[prefixCls + '_error_tip']" v-if="validateState === 'error'">
      {{ validateMessage }}
    </div>
    <!-- </transition> -->
  </div>
</div>
