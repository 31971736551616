<style lang="scss">
.common_header {
  background: #fff;
  width: 100%;
  height: 64px;
  position: fixed;
  top: 0;
  z-index: 11;
  border-bottom: 1px solid #ebebeb;
  display: flex;

  .logo_title_wrapper {
    width: 240px;
    flex: 0 0 240px;
    padding-left: 25px;
    @media (min-width: 1400px) {
      width: 300px;
      flex: 0 0 300px;
      padding-left: 40px;
    }
    display: flex;
    align-items: center;

    .logo {
      width: 190px;
      //height: 24px;
      cursor: pointer;

      &.kreaotr_logo {
        width: 107px;
      }
    }
  }

  .tabs_wrapper {
    overflow-x: auto;
    overflow-y: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;

    .klk_tabs {
      display: inline-block;
    }

    .klk_tabs_tab {
      padding: 0 12px !important;
      @media (min-width: 1400px) {
        padding: 0 20px !important;
      }
    }

    .influencer_program {
      display: inline-block;
      color: #666;
      font-weight: 500;
      font-size: 14px;
      cursor: pointer;
    }
  }

  .header_right_wrapper {
    flex: 1 1 auto;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding-left: 20px;
    padding-right: 60px;
    text-overflow: ellipsis;
    white-space: nowrap;
    height: 64px;
    color: #666666;

    .klk_svg {
      width: 10px;
      height: 10px;
    }

    .lang_info_wrapper,
    .log_in_wrapper,
    .sign_up_wrapper {
      position: relative;
      display: inline-block;
      margin-right: 40px;
      line-height: 18px;
    }

    .user_info_wrapper {
      display: inline-block;
    }

    .header_right_text {
      font-size: 14px;
      color: #666666;
      font-weight: 500;
      cursor: pointer;
      line-height: 18px;
    }

    .poptip_wrapper {
      .klk_svg_arrow-down {
        width: 16px;
        height: 16px;
        vertical-align: text-bottom;
      }

      .poptip_info {
        position: relative;
        display: inline-block;
      }

      .poptip_content {
        width: 140px;

        .ul_content {
          margin-left: -10px;
          margin-right: -10px;
          text-align: left;

          .li_item {
            line-height: 40px;
            padding-left: 16px;
            font-size: 14px;
            color: #333333;
            cursor: pointer;
            vertical-align: top;

            &.active,
            &:hover {
              background: #f5f5f5;
            }

            .icon {
              width: 24px;
              height: 24px;
              vertical-align: text-bottom;
            }

            .text {
              vertical-align: super;
            }
          }

          &.lang {
            text-align: center;

            .li_item {
              padding-left: 0;
            }
          }
        }
      }
    }

    .notifications_wrapper {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-right: 40px;
      margin-top: 1px;

      svg {
        width: 20px !important;
        height: 20px !important;
      }

      .poptip_info {
        display: flex;
        align-items: center;
      }

      .poptip_content {
        text-align: center;

        a {
          text-decoration: underline;

          &:hover {
            cursor: pointer;
          }
        }
      }
    }
  }

  .mr_0 {
    margin-right: 0 !important;
  }
}
</style>

<template>
  <div class="common_header" v-if="should_show_common_header" :data-spm-page="`${getPageName}?trg=manual`">
    <div class="logo_title_wrapper">
      <img
        :src="headerLogo"
        class="logo"
        :class="{
          'kreaotr_logo' : isKreator
        }"
        alt=""
        @click="go_home"
      />
    </div>
    <div class="tabs_wrapper" v-if="user_info.status !== 0 && !isSignUpPage" :data-spm-module="'Header?trg=manual'">
      <klk-tabs
        type="text"
        @on-click="clickNav"
        v-model="router_name"
        style="display: inline-block"
      >
        <klk-tab-pane
          v-for="(router_info, name) in navRouters"
          :key="name"
          :data-spm-item="ihtNameMap[name]"
          :class="ihtNameMap[name]"
          :label="router_info.title"
          :name="name"
        ></klk-tab-pane>
      </klk-tabs>
      <div
        class="klk_tabs_tab influencer_program"
        :data-spm-item="'InfluencerProgram'"
        @click="go_influencer_program"
        v-show="!user_info.id"
      >
        {{ $t("COMMON_HEADER_influencer_program") }}
      </div>
    </div>
    <div class="header_right_wrapper" :data-spm-module="'Header'">
      <div class="notifications_wrapper" v-show="user_info.id && !isSignUpPage">
        <klk-poptip trigger="hover" class="poptip_wrapper">
          <div class="poptip_info" @click="jumpUrl">
            <klk-icon
              :data-spm-item="'Notification'"
              class="icon notification_icon"
              :type="total > 0 ? 'notifications_unread' : 'notifications'"
              size="20"
            ></klk-icon>
          </div>
          <div slot="content" class="poptip_content">
            <span :data-spm-item="'Notification'">{{ $t("27383") }}</span>
          </div>
        </klk-poptip>
      </div>
      <div class="lang_info_wrapper" :class="{ mr_0: isSignUpPage }">
        <klk-poptip trigger="hover" class="poptip_wrapper">
          <div class="poptip_info header_right_text">
            {{ lang_name }}
            <klk-icon
              class="klook-icon-arrow-down"
              type="arrow-down"
            ></klk-icon>
          </div>
          <div slot="content" class="poptip_content">
            <ul class="ul_content lang">
              <li
                :data-spm-item="`Lang?ext=${JSON.stringify({lang: item.value})}`"
                v-for="(item, index) in klk_lang_options"
                class="li_item"
                :key="index"
                :class="{ active: klk_lang === item.value }"
                @click="change_lang(item.value)"
              >
                {{ item.title }}
              </li>
            </ul>
          </div>
        </klk-poptip>
      </div>

      <div
        :data-spm-item="'SignUp'"
        class="sign_up_wrapper"
        v-show="!user_info.id && !isSignUpPage"
        @click="sign_up"
      >
        <span class="header_right_text">{{ $t("MULTIPLE_sign_up") }}</span>
      </div>
      <div
        class="log_in_wrapper"
        ref="login"
        :data-spm-item="'Login'"
        v-show="!user_info.id && !isSignUpPage"
        @click="log_in"
      >
        <span class="header_right_text">{{ $t("MULTIPLE_log_in") }}</span>
      </div>
      <div class="user_info_wrapper" v-show="user_info.id && !isSignUpPage">
        <klk-poptip trigger="hover" class="poptip_wrapper">
          <div class="poptip_info">
            <span class="header_right_text" v-if="!user_info.firstname">{{
                user_info.login_email
              }}</span>
            <span class="header_right_text" v-else
            >{{ user_info.firstname }} {{ user_info.lastname }}</span
            >
            <klk-icon
              class="klook-icon-arrow-down"
              type="arrow-down"
            ></klk-icon>
          </div>
          <div slot="content" class="poptip_content">
            <ul class="ul_content">
              <li
                :data-spm-item="'MyInfo'"
                class="li_item"
                @click="goto('my_account')"
                v-if="user_info.status === 1"
              >
                <klk-icon
                  class="icon klook-icon-my-info"
                  type="my-info"
                ></klk-icon>
                <span class="text">{{ $t("MULTIPLE_my_info") }}</span>
              </li>
              <li
                class="li_item"
                @click="logout"
                :data-spm-item="'Logout'"
              >
                <klk-icon
                  class="icon klook-icon-logout"
                  type="logout"
                ></klk-icon>
                <span class="text"> {{ $t("COMMON_HEADER_log_out") }}</span>
              </li>
            </ul>
          </div>
        </klk-poptip>
      </div>
    </div>
    <common-join-now-dialog-content v-if="!user_info.id">
    </common-join-now-dialog-content>
  </div>
</template>

<script>
import { urlObj, SIGNUP_TYPE_KEY } from "@/comm/common_data";
import { getNavRoutesUtil, NavRouterAfterSignin } from "../router.js";
import Cookies from "js-cookie";
import CommonJoinNowDialogContent from "./common_join_now_dialog_content";
import { isAdmin, isManager } from "../../../comm/user_role";
import klook from "klook";
import { isKreator } from "@/comm/user_role";
import logoImg from "../../imgs/logo.png";
import _ from "underscore";
import { adListPathMap, ihtNameMap } from "@/desktop/js/utils";

export default {
  name: "CommonHeader",
  props: [ "is_signin", "total" ],
  components: {
    CommonJoinNowDialogContent,
  },
  data() {
    return {
      ihtNameMap: ihtNameMap,
      adListPathMap: adListPathMap,
      should_show_common_header: true,
      navRouters: {},
      nav_name_list: _.keys(getNavRoutesUtil()).concat(
        _.keys(NavRouterAfterSignin())
      ),
      klk_lang: KLK_LANG,
      klk_lang_options: LANG_OPTIONS,
      user_info: USER_INFO,
      influencer_program_list: {
        en: "https://docs.google.com/forms/d/e/1FAIpQLSdL2Ih8naxwUEQDs4Zl5GPtbJYR1sdD7azMwVEi9vDiX85ReQ/viewform",
        "zh-CN": "https://jinshuju.net/f/sPCSJL",
        "zh-TW":
          "https://docs.google.com/forms/d/e/1FAIpQLSdL2Ih8naxwUEQDs4Zl5GPtbJYR1sdD7azMwVEi9vDiX85ReQ/viewform",
        "zh-HK":
          "https://docs.google.com/forms/d/e/1FAIpQLSdL2Ih8naxwUEQDs4Zl5GPtbJYR1sdD7azMwVEi9vDiX85ReQ/viewform",
        ko: "https://docs.google.com/forms/d/e/1FAIpQLSdL2Ih8naxwUEQDs4Zl5GPtbJYR1sdD7azMwVEi9vDiX85ReQ/viewform",
        th: "https://docs.google.com/forms/d/e/1FAIpQLSdL2Ih8naxwUEQDs4Zl5GPtbJYR1sdD7azMwVEi9vDiX85ReQ/viewform",
        vi: "https://docs.google.com/forms/d/e/1FAIpQLSdL2Ih8naxwUEQDs4Zl5GPtbJYR1sdD7azMwVEi9vDiX85ReQ/viewform",
        id: "https://docs.google.com/forms/d/e/1FAIpQLSdL2Ih8naxwUEQDs4Zl5GPtbJYR1sdD7azMwVEi9vDiX85ReQ/viewform",
        ja: "https://docs.google.com/forms/d/e/1FAIpQLSdL2Ih8naxwUEQDs4Zl5GPtbJYR1sdD7azMwVEi9vDiX85ReQ/viewform",
      },
      user_type: "",
    };
  },
  methods: {
    getNavRoutes() {
      let routes = getNavRoutesUtil();
      if (this.isHomePageExperiment) {
        delete routes.tools;
      }
      return routes;
    },
    jumpUrl() {
      window.dataLayer.push({
        event: "notification",
        eventCategory: "Header",
        eventAction: "Notification Click",
      });
      this.$router.push({
        name: "notification",
      });
    },
    clickNav(name) {
      this.$inhouse.track('action', `.${ihtNameMap[name]}`);

      this.goto(name);
    },
    goto(name) {
      if (name === "resource_hub") {
        window.dataLayer.push({
          event: "trackEventCustom",
          eventCategory: "Header",
          eventAction: "Partner Resource Hub Click",
        });
      }
      let params = {
        name,
      };
      if (name === "admin") {
        window.location.assign("/admin/affiliate_admin");
      } else {
        this.$router.push(params);
      }
    },
    go_home() {
      if (this.user_info.status === 1) {
        this.$router.push({
          name: "dashboard",
        });
      } else {
        this.$router.push({
          name: "home",
        });
      }
    },
    go_influencer_program() {
      // Google Tag Manager for "Click Influencer Program" Event
      window.dataLayer.push({
        event: "influencer",
        eventCategory: "Header",
        eventAction: "Click Influencer Program",
      });
      // window.open(this.influencer_program_list[this.klk_lang]);
      window.open("https://www.klook.com/tetris/promo/kreator-program/");
    },
    change_lang(lang) {
      // Google Tag Manager for "Click Influencer Program" Event
      window.dataLayer.push({
        event: "language",
        eventCategory: "Header",
        eventAction: "Language Click",
        eventLabel: lang,
      });
      Cookies.set("locale", lang === "en" ? "" : lang, {
        path: "/",
      });
      let queryStr = Object.keys(this.$route.query).map((q) => {
        return `${q}=${this.$route.query[q]}`;
      });
      queryStr.length && (queryStr = "?" + queryStr.join("&"));
      let path = this.$route.path + queryStr;
      lang === "en"
        ? window.location.replace(path)
        : window.location.replace("/" + lang + path);
    },
    sign_up() {
      // Google Tag Manager for "Click Influencer Program" Event
      window.dataLayer.push({
        event: "signUp",
        eventCategory: "Header",
        eventAction: "Sign Up",
      });
      // window.location.replace("/" + window.KLK_LANG_PATH + "signup_auto");
      this.$store.commit("change_join_now_type", "signUp");
      this.$store.commit("change_login_event_category", "Header");
    },
    log_in() {
      // Google Tag Manager for "Click Influencer Program" Event
      window.dataLayer.push({
        event: "login",
        eventCategory: "Header",
        eventAction: "Login",
      });
      this.$store.commit("change_join_now_type", "login");
      this.$store.commit("change_login_event_category", "Header");
    },
    logout() {
      klook.ajaxGet(urlObj.logout, (resp) => {
        if (resp.success) {
          // csrf-token 失效
          Cookies.remove("CSRF-TOKEN", {
            path: "/",
          });
          window.location.replace("/" + window.KLK_LANG_PATH + "home");
        } else {
          console.error(resp);
        }
      });
    },
    getUrlParam(name) {
      const reg = new RegExp(`(^|&)${name}=([^&]*)(&|$)`);
      const r = window.location.search.substr(1).match(reg);
      if (r !== null) return r[2];
      return null;
    },
  },
  computed: {
    getPageName() {
      console.log('this.$route', this.$route);
      if(this.$route.matched.length && this.$route.matched[0]['path'] === '/admin') {
        return 'Admin'
      }
      return this.ihtNameMap[this.$route.name] || this.adListPathMap[this.$route.fullPath];
    },
    isSignUpPage() {
      return (
        this.$route.path === "/signup_auto" ||
        this.$route.path === "/choose_user_type"
      );
    },
    isKreator() {
      return isKreator() || this.user_type === "kreator";
    },
    isHomePageExperiment() {
      return this.$store.getters.isHomePageExperiment;
    },
    lang_name() {
      var self = this;
      var lang_name;
      this.klk_lang_options.forEach((item) => {
        if (self.klk_lang === item.value) {
          lang_name = item.title;
        }
      });
      return lang_name;
    },
    router_name: {
      //主要解决children route 还能锁定正确的banner
      get() {
        var path_name_list = this.$route.path.split("/");
        var matched_name_list = _.filter(this.nav_name_list, (nav_name) => {
          return path_name_list.indexOf(nav_name) !== -1;
        });
        if (
          this.$route.path.indexOf("/my_ads/ad_tools") > -1 &&
          this.$route.query.uid
        ) {
          matched_name_list = [ "admin" ];
        }
        var resp = matched_name_list.pop();
        return resp || (this.is_signin ? "dashboard" : "home");
      },
      set() {
      },
    },

    headerLogo() {
      return this.isKreator ? 'https://res.klook.com/image/upload/v1702363907/Affiliate/web/kreator_logo.png' : logoImg;
    }
  },
  watch: {
    $route(to, from) {
      const isFromAdmin = from.path.startsWith("/admin");
      const isToAdmin = to.path.startsWith("/admin");
      // const toSignUpAuto = to.path.startsWith("/signup_auto");
      const toActivationFeedback = to.path.startsWith("/activation_feedback");
      const fromSignUpAuto = from.path.startsWith("/signup_auto");
      const fromActivationFeedback = from.path.startsWith(
        "/activation_feedback"
      );
      if (toActivationFeedback) {
        this.should_show_common_header = false;
      }
      if (fromSignUpAuto || fromActivationFeedback) {
        this.should_show_common_header = true;
      }
      if (!isFromAdmin && isToAdmin) {
        // 别的页面跳到 admin 下
        this.klk_lang_options = LANG_OPTIONS.filter(
          (item) => item.value === "en"
        );
      } else if (isFromAdmin && !isToAdmin) {
        // 从 admin 跳到别的路由
        this.klk_lang_options = LANG_OPTIONS;
      }
      this.$nextTick(() => {
        this.user_type = sessionStorage.getItem(SIGNUP_TYPE_KEY);
      });
    },
  },
  mounted() {
    const redirect = this.getUrlParam("redirect");
    if (!USER_INFO.id && redirect) {
      this.$refs["login"].click();
    }
  },
  created() {
    this.navRouters = this.is_signin
      ? NavRouterAfterSignin()
      : this.getNavRoutes();
    if (!isAdmin() && !isManager()) {
      delete this.navRouters.admin;
    }
  },
};
</script>
