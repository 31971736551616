/**
 * @file 所有 user_role 相关的字段方法从此文件获取，后续会补充更多
 * @param {int} USER_INFO.gid 1-Administrator 2-Affiliate Manager 3-Affiliate Publisher
 * @param user_info
 * @param gid
 */

const checkGid = (user_info, gid) => {
  if (user_info && user_info.gid && Array.isArray(user_info.gid)) {
    return user_info.gid.includes(gid);
  } else {
    return false;
  }
};

const isAdmin = (user_info = USER_INFO) => checkGid(user_info, 1);

const isManager = (user_info = USER_INFO) => checkGid(user_info, 2);

const isPublisher = (user_info = USER_INFO) => checkGid(user_info, 3);
const isKreator = (user_info = USER_INFO) => {
  return [2, 1].includes(+user_info.kreator?.status);
};

const { id: uid, email } = window.USER_INFO || {};

export { isAdmin, isManager, isPublisher, isKreator, uid, email };
