
<div :class="itemClasses">
  <div :class="headerClasses" @click="toggle">
    <!-- <Icon type="arrow-right-b"></Icon> -->
    <slot></slot>
    <svg class="svg_wrapper klook-icon-arrow-down">
      <use xlink:href="#arrow-down"></use>
    </svg>
  </div>
  <!-- <transition name="slide-toggle">
          <div :class="contentClasses" v-show="isActive">
              <div :class="boxClasses"><slot name="content"></slot></div>
          </div>
      </transition> -->
  <collapse-transition>
    <div :class="contentClasses" v-show="isActive">
      <div :class="boxClasses"><slot name="content"></slot></div>
    </div>
  </collapse-transition>
</div>
