
<div class="pagination" v-show="show_pagination" :data-spm-module="'Pagination?trg=manual'">
  <select
    class="page_size_opts"
    v-model="perPage"
    v-show="show_page_size_opts"
  >
    <option
      v-for="(number, index) in page_size_opts"
      :key="index"
      :value="number"
    >
      {{ page_size_opts_label_array[index] }}
    </option>
  </select>
  <ul class="pagination_cur_page" :data-spm-item="`Page${curPage}?trg=manual`">
    <li :data-spm-item="'Prev'" class="prev" :class="!hasPrev() && 'disabled'" @click="prev()"></li>

    <li :class="1 === curPage && 'active'" @click="go(1)">1</li>

    <li
      class="more_pages"
      v-show="left_more_pages_show"
      @click="minusCurPage"
    >
      ···
    </li>

    <li
      v-for="number in pages"
      :key="number"
      :class="number == curPage && 'active'"
      @click="go(number)"
    >
      {{ number }}
    </li>

    <li class="more_pages" v-show="right_more_pages_show" @click="addCurpage">
      ···
    </li>

    <li
      :class="totalPages == curPage && 'active'"
      v-show="totalPages > 1"
      @click="go(totalPages)"
    >
      {{ totalPages }}
    </li>

    <li :data-spm-item="'Next'" class="next" :class="!hasNext() && 'disabled'" @click="next()"></li>
  </ul>
  <div class="go_to_page_wrapper" v-show="show_elevator">
    <span>{{ $t("PAGINATION_go_to_page") }}</span>
    <input
      class="page_elevator"
      :value="curPage"
      type="text"
      @keyup.enter="go($event.target.value)"
    />
    <span>{{ $t("PAGINATION_page") }}</span>
  </div>
</div>
