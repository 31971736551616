<template >
  <div
    class="klk-switch"
    :class="{ 'is-disabled': disabled, 'status-on': value }"
    @click="handleClick"
  >
    <!-- <span>
            {{  value ? onText: offText }}
        </span> -->
    <span class="klk-switch-circle"></span>
  </div>
</template>

<script>
export default {
  name: "KlkSwitch",
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    onText: {
      type: String,
      default: "ON",
    },
    offText: {
      type: String,
      default: "OFF",
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    handleClick: function () {
      if (this.disabled) {
        return;
      }
      this.$emit("input", !this.value);
      this.$emit("change", !this.value);
    },
  },
};
</script>

<style lang="scss">
.klk-switch {
  display: inline-block;
  width: 42px;
  border-radius: 12px;
  height: 16px;
  position: relative;
  background: rgb(218, 218, 218);
  cursor: pointer;
  transition: background 0.3s ease;
  vertical-align: middle;

  span {
    display: inline-block;
  }

  .klk-switch-circle {
    position: absolute;
    top: -4px;
    width: 24px;
    height: 24px;
    background: #fff;
    border-radius: 100%;
    left: 0;
    transition: left 0.3s ease;
    border: 1px solid rgb(218, 218, 218);
  }
}
.is-disabled {
  cursor: not-allowed;
}
.status-on {
  background: $theme_color;

  .klk-switch-circle {
    left: 20px;
    border-color: $theme_color;
  }
}
</style>
