
<div
  :class="classes"
  @mouseenter="handleMouseenter"
  @mouseleave="handleMouseleave"
  v-clickoutside="handleClose"
>
  <div
    :class="[prefixCls + '_rel']"
    ref="reference"
    @click="handleClick"
    @mousedown="handleFocus(false)"
    @mouseup="handleBlur(false)"
  >
    <slot></slot>
  </div>
  <!-- <transition name="fade"> -->
  <div
    :class="popperClasses"
    :style="styles"
    ref="popper"
    v-show="visible"
    @mouseenter="handleMouseenter"
    @mouseleave="handleMouseleave"
  >
    <div :class="[prefixCls + '_content']">
      <div ref="up_arrow" :class="[prefixCls + '_arrow']"></div>
      <!-- <div :class="[prefixCls + '_inner']" v-if="confirm">
                      <div :class="[prefixCls + '_body']">
                          <i class="ivu-icon ivu-icon-help-circled"></i>
                          <div :class="[prefixCls + '-body-message']"><slot name="title">{{ title }}</slot></div>
                      </div>
                      <div :class="[prefixCls + '-footer']">
                          <i-button type="text" size="small" @click.native="cancel">{{ localeCancelText }}</i-button>
                          <i-button type="primary" size="small" @click.native="ok">{{ localeOkText }}</i-button>
                      </div>
                  </div> -->
      <div :class="[prefixCls + '_inner']" v-if="!confirm">
        <div :class="[prefixCls + '_title']" v-if="showTitle" ref="title">
          <slot name="title">
            <div :class="[prefixCls + '_title_inner']">{{ title }}</div>
          </slot>
        </div>
        <div :class="[prefixCls + '_body']">
          <div :class="[prefixCls + '_body_content']">
            <slot name="content">
              <div :class="[prefixCls + '_body_content_inner']">
                {{ content }}
              </div>
            </slot>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- </transition> -->
</div>
