
<div class="sign_up_dialog" v-loading="loading" data-spm-page="SignUpPopUp">
  <div class="klk_dialog_title">
    {{ $t("MULTIPLE_sign_up") }}
  </div>
  <div class="instantly">{{ $t("set_up_account_instantly") }}</div>
  <div class="sign_up_tips">
    <div>{{ $t("has_klook") }}</div>
    <div>{{ $t("can_use_email") }}</div>
  </div>
  <div class="error_tips" :data-spm-module="`Error?oid=error_${login_error_code}&ext=${encodeURIComponent(JSON.stringify({
        msg: login_error,
      }))}`" v-show="login_error !== ''">
    {{ login_error }}
  </div>
  <div class="sign_up_form">
    <klk-form :rules="formRules" :model="form_data" ref="sign_up_form">
      <klk-form-item prop="email" style="margin-top: 0">
        <el-autocomplete
          style="width: 100%;"
          :placeholder="$t('MULTIPLE_email')"
          :trigger-on-focus="false"
          :fetch-suggestions="querySearch"
          v-model.trim="form_data.email"
        >
        </el-autocomplete>
      </klk-form-item>
      <klk-form-item prop="sign_up_password">
        <el-input
          :placeholder="$t('MULTIPLE_password')"
          type="password"
          v-model="form_data.sign_up_password"
        ></el-input>
      </klk-form-item>
    </klk-form>
  </div>
  <div class="sign_up_btns">
    <el-button
      type="primary"
      :data-spm-module="'SignUp'"
      :data-spm-virtual-item="`__virtual`"
      style="width: 100%;margin-top: 10px;"
      @click="sign_up">
      {{ $t("MULTIPLE_sign_up") }}
    </el-button>
    <div
      :data-spm-module="'LoginIn'"
      :data-spm-virtual-item="`__virtual`"
      class="login_button"
      @click="loginIn">
      {{ $t("MULTIPLE_log_in") }}
    </div>
  </div>
</div>
