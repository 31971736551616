import { MaskUtil } from '@klook/klk-kcrypto';
 
const MOBILE_KEYS = ["alipay_id", "alipay_id_confirm", "phone_number"];
const EMAIL_KEYS = ["login_id", "email", "login_email", "paypal_id_confirm", "paypal_id"];
const FIRST_NAME_KEYS = ['firstname'];
const FULLNAME_KEYS = ['bank_account_name', 'id_passport_name'];
const BANK_ACCOUNT_KEYS = ['bank_account_id'];
const ID_NUMBER_KEYS = ['id_passport_number'];
const BIRTHDAY_KEYS = ['date_of_birth'];
const ADDRESS_KEYS = ['residential_address'];

const isObject = val => Object.prototype.toString.call(val) === '[object Object]';

const deepResolveParams = (obj) => {
    const resolveParam = obj;
    Object.keys(resolveParam).forEach((key) => {
        const value = resolveParam[key];

        if(isObject(value)) {
            deepResolveParams(value)
            return;
        }
        if(!value){
            // do nothing
        } else if (MOBILE_KEYS.includes(key)) {
            resolveParam[key] = MaskUtil.mobile(value)
        } else if(EMAIL_KEYS.includes(key)) {
            resolveParam[key] = MaskUtil.email(value);
        } else if (FIRST_NAME_KEYS.includes(key)) {
            resolveParam[key] = MaskUtil.first_name(value)
        } else if(FULLNAME_KEYS.includes(key)) {
            resolveParam[key] = MaskUtil.full_name(value);
        } else if (ID_NUMBER_KEYS.includes(key)) {
            resolveParam[key] = MaskUtil.id_number(value)
        } else if (BANK_ACCOUNT_KEYS.includes(key)) {
            resolveParam[key] = MaskUtil.bank_account_no(value);
        } else if (BIRTHDAY_KEYS.includes(key)) {
            resolveParam[key] = MaskUtil.birthday(value);
        } else if(ADDRESS_KEYS.includes(key)) {
            resolveParam[key] = MaskUtil.address(value);
        } 
    }) 
}
const resolveParams = (params) => {
    if(!params) return params;

    const resolveParam = JSON.parse(JSON.stringify(params));
    deepResolveParams(resolveParam);

    return resolveParam;
}

export const batchResolveParams = function (params) {
    const resolveParam = Object.assign({}, params);
    if(resolveParam.data) {
        resolveParam.data = resolveParams(resolveParam.data); 
    }

    if(resolveParam.params) {
        resolveParam.params = resolveParams(resolveParam.params);  
    }
    return resolveParam;
}