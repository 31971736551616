
<div class="klk_rate">
  <div class="klk_rate_icon">
    <span class="klk_rate_dark">
      <!-- <svg class="svg_wrapper">
                  <use xlink:href="#star"></use>
              </svg>
              <svg class="svg_wrapper">
                  <use xlink:href="#star"></use>
              </svg>
              <svg class="svg_wrapper">
                  <use xlink:href="#star"></use>
              </svg>
              <svg class="svg_wrapper">
                  <use xlink:href="#star"></use>
              </svg>
              <svg class="svg_wrapper">
                  <use xlink:href="#star"></use>
              </svg> -->
      <svg v-for="n in 5" :key="n" class="svg_wrapper svg_star_wrapper">
        <use xlink:href="#star"></use>
      </svg>
    </span>
    <span class="klk_rate_light" :style="'width:' + light_width + 'px'">
      <svg v-for="n in 5" class="svg_wrapper svg_star_wrapper" :key="n">
        <use xlink:href="#star"></use>
      </svg>
      <!-- <svg class="svg_wrapper">
                  <use xlink:href="#star"></use>
              </svg>
              <svg class="svg_wrapper">
                  <use xlink:href="#star"></use>
              </svg>
              <svg class="svg_wrapper">
                  <use xlink:href="#star"></use>
              </svg>
              <svg class="svg_wrapper">
                  <use xlink:href="#star"></use>
              </svg>
              <svg class="svg_wrapper">
                  <use xlink:href="#star"></use>
              </svg> -->
    </span>
  </div>
  <div class="klk_rate_text">{{ text }}</div>
</div>
