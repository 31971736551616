
<ul :class="[prefixCls + '-list']">
  <li
    v-for="(file, i) in files"
    :class="fileCls(file)"
    @click="handleClick(file)"
    :key="i"
  >
    <!-- <span @click="handlePreview(file)">
              <Icon :type="format(file)"></Icon> {{ file.name }}
          </span> -->
    <!-- <Icon
              type="ios-close-empty"
              :class="[prefixCls + '-list-remove']"
              v-show="file.status === 'finished'"
              @click.native="handleRemove(file)"></Icon> -->
    <!-- <transition name="fade">
              <i-progress
                  v-if="file.showProgress"
                  :stroke-width="2"
                  :percent="parsePercentage(file.percentage)"
                  :status="file.status === 'finished' && file.showProgress ? 'success' : 'normal'"></i-progress>
          </transition> -->
  </li>
</ul>
