
<div
  id="klk-cast-feedback-content"
  class="klk-cast-feedback-content"
  :style="autoStyle"
>
  <div v-if="showClose" class="klk-cast-close" @click="handleCloseFn()">
    <img :src="genResourceFullPath('feedback/icon_close.png')" alt="" />
  </div>
  <div v-if="!isSubmit">
    <p class="klk-cast-title">{{ question }}</p>
    <div class="klk-cast-wrapper">
      <span v-for="(item, index) in imgList" :key="index">
        <img
          @click="hanleCastFn(item.value)"
          :src="getCurrentIcon(index + 1)"
          alt=""
        />
      </span>
    </div>
    <template v-if="score">
      <textarea
        class="klk-cast-context"
        v-model="feedbackContext"
        :placeholder="placeholder"
        :rows="4"
      ></textarea>
      <div
        :class="{
          'klk-cast-content-footer': true,
          'button-flex': btnRemindText,
        }"
      >
        <span
          v-if="btnRemindText"
          class="fd-btn-close"
          @click="handleCloseFn()"
        >
          {{ btnRemindText }}
        </span>
        <span :style="canSubmit ? btnColor : ''" @click="submitData">
          {{ btnSubmitText }}
        </span>
      </div>
    </template>
  </div>
  <div v-if="isSubmit" class="klk-cast-success">
    <img :src="genResourceFullPath('feedback/success.png')" alt="" />
    <span>{{ successText }}</span>
  </div>
</div>
