import("./gt.js");
import { urlObj } from "../common_data.js";
import { getGeetestLang } from "./gt_lang.js";
import klook from "klook";
const reCAPTCHA_KEY = "6LdVIZ8UAAAAALTPJ7j1VbcjQ7jN6fm2Lr5o98ko";

/**
 *
 * @param {*} action 用于区分使用场景；比如登陆、注册
 */
const initCaptcha = (action) => {
  return new Promise((resolve, reject) => {
    //   captcha_init: "/v3/affsrv/user/account/captcha_init",
    klook.ajaxPostJSON(urlObj.captcha_init, { action, data: "" }, (resp) => {
      if (!resp || !resp.success) {
        reject(Promise.reject(resp && resp.error));
        return;
      }
      const { captcha_type, need_captcha, captcha_seq_no } = resp.result;
      let site_key =
        (resp.result &&
          resp.result.google_v3 &&
          resp.result.google_v3.site_key) ||
        reCAPTCHA_KEY;
      if (!need_captcha) {
        resolve({ captcha_seq_no, need_captcha });
      }
      if (captcha_type === "geetest") {
        const lang = getGeetestLang();
        const options = Object.assign(
          {
            offline: false,
            new_captcha: true,
          },
          resp.result["geetest"],
          {
            lang,
            captcha_seq_no,
            product: "bind",
            pure: 1,
            api_server: "api-na.geetest.com", // web 端新增参数, 和官网文档不一样,以我们提供的文档为主
          }
        );
        resolve(
          new Promise((resolve) => {
            window.initGeetest(options, (captchaObj) => {
              captchaObj.onReady(() =>
                resolve({
                  captchaObj,
                  captcha_seq_no,
                  type: "geetest",
                  geetest_id: options.gt,
                  geetest_offline: options.offline,
                })
              );
            });
          })
        );
      }
      if (captcha_type === "google_v3") {
        klook.loadScript(
          `https://www.google.com/recaptcha/api.js?render=${site_key}`
        );
      }
      resolve({ action, captcha_seq_no, type: captcha_type, site_key });
    });
  });
};

// orion 接口地址 https://orion.klook.io/interface/interfaces/detail/6/57/4559/show
// 极验内部文档地址 https://docs.google.com/spreadsheets/d/1IpqTarbHahjF6z2-ZbJL-p7dmx_olw3L8T-qhq1DbQU/edit#gid=153900163
// 极验官方文档地址 https://docs.geetest.com/install/deploy/client/web
const getCaptcha = (captcha) => {
  return new Promise((resolve, reject) => {
    if (!captcha) {
      resolve(false);
      return;
    }
    if (captcha.need_captcha === false) {
      resolve({ captcha_seq_no: captcha.captcha_seq_no });
      return;
    }
    if (captcha.type === "geetest") {
      const { captchaObj, captcha_seq_no, geetest_id, geetest_offline } =
        captcha;
      if (!captchaObj || !captchaObj.onReady) {
        return Promise.reject({
          message: "Something was wrong with geetest init",
        });
      }
      captchaObj
        .onSuccess(() => {
          const { geetest_challenge, geetest_validate, geetest_seccode } =
            captchaObj.getValidate();
          resolve({
            geetest_challenge,
            geetest_validate,
            geetest_seccode,
            geetest_id,
            captcha_seq_no,
            geetest_offline,
            captcha_type: 3, // 0:Google v2, 1: Ali验证码 ， 2:Ali滑动验证码， 3 :极验， 4 :谷歌v3
          });
        })
        .onError((error) => {
          reject({
            message:
              (error && error.user_error) || "Something was wrong with geetest",
          });
        })
        .onClose(() => {
          reject();
        });
      // 出现形式为 bind 时候, 手动调用来验证
      captchaObj.verify();
      return;
    }
    if (captcha.type === "google_v3") {
      const { action, captcha_seq_no, site_key } = captcha;
      const fn = (cb = resolve) => {
        window.grecaptcha.ready(() => {
          window.grecaptcha.execute(site_key, { action }).then((token) => {
            cb({ captcha_seq_no, captcha_type: 4, recaptcha_v3: token }); // 0:Google v2, 1: Ali验证码 ， 2:Ali滑动验证码， 3 :极验， 4 :谷歌v3
          });
        });
      };
      if (!window.grecaptcha) {
        window.onloadCallback = fn;
        klook.loadScript(
          `https://www.google.com/recaptcha/api.js?render=${site_key}&onload=onloadCallback`
        );
        return;
      }
      fn();
      return;
    }
    resolve(captcha);
  });
};

/**
 * @param {*} error
 * @param {*} that
 */
const simpleCaptchaErrHandler = (error, that) => {
  if (!error || !that) {
    return;
  }
  const { captchaObj } = that.captchaSettings || {};

  if (captchaObj && typeof captchaObj.reset === "function") {
    captchaObj.reset();
  }

  if (+error.code === 10129) {
    // 表示captcha_seq_no失效，需要重新调用initCaptcha获取
    that.captchaSettings = "";
  }
};

export { initCaptcha, getCaptcha, simpleCaptchaErrHandler };
