const stringify = require('./stringify');
const Logquery = require('@klook/logquery').default;

/**
 * 日志上报
 * https://logquery.klook.io/v1/logqueryserv/#/
 * https://logquery.dev.klook.io/v1/logqueryserv/#/
*/
const defaultOptions = {
  url:
    process.env.NODE_ENV === 'production'
      ? 'https://log.klook.com/v2/frontlogsrv/log/web'
      : 'https://dev-frontsrv-new.dev.klook.io/v2/frontlogsrv/log/web',
  headers: { 'X-Platform': 'desktop' },
  queue: {
    interval: 5000,
    size: 15,
  },
};

const logquery = new Logquery(defaultOptions);

logquery.handlerError = (error, customInfo) => {
  error = { ...error, customInfo };
  console.log(">>>>>>>>>>>>>>>>>logquery.handlerError>>>>>>>>>>>>>>>");
  console.log(error);
  logquery.service({
    isMasked: true,
    timestamp: Date.now(),
    level: (customInfo && customInfo.level) || 'E',
    message: stringify(error,null,2),
    tag: 'affiliate/Error',
  });
}

module.exports = logquery;
