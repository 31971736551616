import DOMPurify from "dompurify";
import _debounce from "lodash/debounce";
import urlObj from "./url";
import Cookies from "js-cookie";
import config from "@klook/aff-config";
import logger from "@klook/aff-logger";
import klkClientReport from "./client-report";
import { router } from "../desktop/js/router";
import { v4 as uuidv4 } from "uuid";
import axios from "axios";
import qs from "qs";
import { batchResolveParams } from "./kcrypto";

axios.defaults.headers["Content-Type"] = "application/x-www-form-urlencoded";
axios.defaults.headers["Accept"] = "application/json; charset=utf-8";
axios.defaults.timeout = 60 * 2 * 1000;


var klook = (function () {
  const _debounceShowGoogleAuthExpiredDialog = _debounce(showGoogleAuthExpiredDialog, 1000);
  axios.interceptors.request.use(
    async function (config) {
      // Do something before request is sent
      const { url, headers } = config;
      // 这里需要对 widget 的渲染做兼容
      window.KLK_LANG ? (config.url = addLangToUrl(url)) : null;
      if (!headers["Accept-Language"] && window.KLK_LANG) {
        headers["Accept-Language"] = getAPILangByUrl(url);
      }
      // 设置公共头部字段
      headers["X-Klook-Kepler-Id"] = Cookies.get("kepler_id");
      headers["X-Klook-Request-Id"] = uuidv4();
      if(isAdminApi(url)) {
        headers['x-auth-returnurl'] = `${window.location.origin}/admin_google_login_success`;
        config.validateStatus = function (status) {
          return (status >= 200 && status < 300) || status == 401; // default： 200 <= status <300；add 401 for admin google auth
        };
      }
      
      // set token
      if (!isOutsideApi(url) && !isWhiteList(url)) {
        try {
          let token = await getTokenByCookie(url);
          headers["X-CSRF-Token"] = `JWT ${token || ""}`;
        } catch (e) {
          // 捕获到错误之后, 清除 sess 和 token
          logout();
          return;
        }
      }
      return config;
    },
    function (error) {
      // Do something with request error
      return Promise.reject(error);
    }
  );

  axios.interceptors.response.use(
    async function (response) {
      const { data, status, config} = response;
      klkClientReport.reportRequestSuccess(
        {
          pageName: window.location.pathname,
        },
        config,
        response
      );

      if (isAdminApi(config.url) && status === 401 && data.result?.login_url) {
        _debounceShowGoogleAuthExpiredDialog(data.result.login_url);
        return Promise.reject({message: "Google login status has expired"});
      }
      if (status === 307) {
        window.location.reload();
        return;
      }
      let error = data.error || {};
      if ((!data.success && error.code === "P0101") || status === 307) {
        logout();
      }
      return response;
    },
    function (error) {
      const {config, response} = error
      klkClientReport.reportRequestError({
        pageName: window.location.pathname,
      }, config, response, error)
      // Do something with request error
      return Promise.reject(error);
    }
  );

  var AJAX_ERROR_CODE_OBJ = {
    success: 0,
    user_not_logged_in: "P0101",
    param_invalid: "P0102",
    user_already_exist: "P0103",
    cant_find_user: "P0104",
    need_auth: "P0105",
    user_not_authed: "P0106",
    auth_fail: "P0107",
    admin_required: "P0108",
    register_fail: "P0109",
    ip_exceed_limit: "P0110",
    email_exceed_limit: "P0111",
    user_not_admin: "P0112",
    user_declined: "P0113",
    user_status_error: "P0114",
    user_already_activated: "P0117",
    user_activation_expired: "P0118",
    wrong_password: "P0119",
    csat_feedback_error: "P0101",
    faq_feedback_error: "P0102",
  };

  function isiOS() {
    var u = navigator.userAgent;
    return !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/);
  }

  function isIeBrowser() {
    return !!window.ActiveXObject || "ActiveXObject" in window;
  }

  const ieDown = (url) => {
    window.open(url);
  };

  const downloadFile = async (fileName, url) => {
    if(isAdminApi(url)) {
      await checkGoogleAuthStatus();
    }
    if (isIeBrowser()) {
      ieDown(url);
    } else {
      const aLink = document.createElement("a");
      const evt = document.createEvent("MouseEvents");
      evt.initMouseEvent(
        "click",
        true,
        false,
        window,
        0,
        0,
        0,
        0,
        0,
        false,
        false,
        false,
        false,
        0,
        null
      );
      aLink.download = fileName;
      aLink.href = url;
      aLink.dispatchEvent(evt);
    }
  };
  /* function isSuperAdmin() {
    return +USER_INFO.is_super_admin === 1;
  } */

  function save_local(key, value, expires) {
    if (window.localStorage) {
      window.localStorage.setItem(key, value);
    } else {
      Cookies.set(key, value, {
        path: "/",
        expires: expires || 365,
      });
    }
  }

  function remove_local(key) {
    if (window.localStorage) {
      window.localStorage.removeItem(key);
    }
    Cookies.remove(key, {
      path: "/",
    });
  }

  function get_local(key) {
    if (window.localStorage) {
      return window.localStorage.getItem(key);
    }
    return Cookies.get(key);
  }

  function urlParam(name, value, url) {
    if (typeof value == "undefined") {
      return (
        decodeURIComponent(
          (new RegExp("[?|&]" + name + "=" + "([^&;]+?)(&|#|;|$)").exec(
            url || location.search
          ) || [undefined, ""])[1].replace(/\+/g, "%20")
        ) || null
      );
    } else {
      url = url || window.location.href;
      if (url.includes("lang=")) {
        return url;
      }
      name = name.toString();
      value = encodeURIComponent(value.toString());
      var r = new RegExp("(^|\\W)" + name + "=[^&]*", "g");
      var vUrl = url.split("#");
      vUrl[0] = vUrl[0].match(r)
        ? vUrl[0].replace(r, "$1" + name + "=" + value)
        : vUrl[0] +
          (vUrl[0].indexOf("?") === -1 ? "?" : "&") +
          name +
          "=" +
          value;
      return vUrl.join("#");
    }
  }

  var CODE_CLIENT_ERROR = 990001;

  function addLangToUrl(url) {
    //KLK_LANG need to be render in the layout
    return urlParam("lang", window.KLK_LANG && KLK_LANG.replace("-", "_"), url);
  }

  function isOutsideApi(url) {
    return Object.keys(urlObj.klook_c_related_url_map)
      .map((url_key) => urlObj.klook_c_related_url_map[url_key])
      .some(function (cUrl) {
        return url.indexOf(cUrl) !== -1;
      });
  }

  function isAdminApi(api) {
    const regExp = new RegExp("/v[1-9]/affsrv/admin");
    const isCheckGoogle = api.includes("/admin/node/pong");
    return regExp.test(api) || isCheckGoogle;
  }

  function getAPILangByUrl(url) {
    if (isOutsideApi(url)) {
      return getCorrectAPILang();
    }
    let innerAPILang = config.SUPPORT_LANGS[KLK_LANG].name || KLK_LANG;
    return innerAPILang;
  }

  function getCorrectAPILang() {
    if (KLK_LANG == "en") {
      return "en_US";
    }
    return KLK_LANG.replace("-", "_");
  }

  function fetchToken() {
    return new Promise((resolve, reject) => {
      ajaxGet(urlObj.csrf_token, null, (res) => {
        // 请求发出之后，改变状态
        if (!res.success) {
          reject();
        } else {
          let data = Cookies.get("CSRF-TOKEN");
          if (data) {
            resolve(data);
          } else {
            reject();
          }
        }
      });
    });
  }

  async function getTokenByCookie(url) {
    // 1. 先获取cookie中token
    let token = Cookies.get("CSRF-TOKEN");
    let islogin = Cookies.get("islogin");
    // 已登陆情况下，如果已经过期，则重新获取，过滤自己本身的请求, 当过期时间到达时，浏览器并发请求需要控制发请求次数
    if (window.USER_INFO && window.USER_INFO.id) {
      // 判断已经登陆还需要判断 islogin 的值
      if (islogin) {
        if (!token && url !== urlObj.csrf_token) {
          token = await fetchToken();
        }
        return token;
      } else {
        throw new Error("未登陆");
      }
    }
  }

  const logout = () => {
    try{
      Cookies.remove("CSRF-TOKEN", "", {
        path: "/",
      });
      Cookies.remove("islogin", "", {
        path: "/",
      });
    } catch (e) {
      console.log(e);
    }

    window.location.replace("/" + window.KLK_LANG_PATH + "home");
  };

  const isWhiteList = (url) => {
    const whiteList = [
      urlObj.aff_user_register,
      urlObj.email_validation,
      urlObj.user_activation(""),
      urlObj.session,
      urlObj.get_country_list,
    ];
    const arr = whiteList.filter((item) => url.startsWith(item));
    return arr.length > 0;
  };

  var ajaxBase = async function (method, url, data, fn, headers) {
    if (typeof data == "function") {
      headers = fn;
      fn = data;
      data = undefined;
    }

    var params = {
      method: method.toLowerCase(),
      url: url,
      startTime: Date.now(),
      headers: headers || {},
    };

    if (data) {
      const isJSON = (params.headers["Content-Type"] || "").includes(
        "application/json"
      );
      const isGET = params.method === "get";
      // axios 中 GET 的参数数据是放在 params 的
      if (isGET) {
        params.params = data;
      } else {
        // axios 如果是以 x-www-form-urlencoded 的方式发起请求，需要对数据 stringify
        params.data = isJSON ? data : qs.stringify(data);
      }
    }

    // TODO: 这个是干嘛的？？
    // 广告渲染和 affiliate portal 都会调用该文件, 会把 router 也打包进来
    // 因此在涉及到页面 html 模版中，都需要和 affiliate portal 的渲染保持一致, 否则会报错【涉及一些全局变量】。
    // 对于广告渲染页面没有router, 因此这里也需要做兼容
    params.pathName =
      (router.app && router.app._route.name) || window.location.pathname;
    return axios(params)
      .then((res) => res.data)
      .then((res) => {
        if (fn) {
          onSuccess(fn, res, params);
        } else return res;
      })
      .catch((err) => {
        if (fn) {
          onError(fn, err, params);
        } else throw err;
      });
  };

  function onSuccess(fn, resp, params) {

    //如果没有message返回默认的message
    resp.error = resp.error || {};
    if (resp.error.code == AJAX_ERROR_CODE_OBJ.user_declined) {
      if (
        params.url.indexOf(urlObj.user_info) != -1 ||
        params.url.indexOf(urlObj.session) != -1
      ) {
        fn(resp);
      } else {
        Cookies.set("sess", "", {
          path: "/",
          expires: new Date(Date.now() - 10000),
        });
        location.href = ["/", KLK_LANG_PATH, "/home"].join("");
      }
    } else if (+resp.error.code === AJAX_ERROR_CODE_OBJ.user_not_logged_in) {
      Cookies.set("sess", "", {
        path: "/",
        expires: new Date(Date.now() - 10000),
      });
      // csrf-token 失效
      Cookies.remove("CSRF-TOKEN", "", {
        path: "/",
      });
      // 需要用户跳转到登陆页面
      window.location.replace("/" + window.KLK_LANG_PATH + "home");
    } else {
      fn(resp);
    }
  }

  function onError(fn, error, params) {
    const data = batchResolveParams(params);

    logger.portal.alert.ajax(error || new Error("onError"), {
      params: data,
    });

    fn({
      success: false,
      error: {
        code: CODE_CLIENT_ERROR,
        message: (error && error.message) || "MULTIPLE_client_network_failure",
      },
    });
  }

  var ajaxGet = function (url, data, fn, headers) {
    return ajaxBase("GET", url, data, fn, headers);
  };

  var ajaxPut = function (url, data, fn, headers) {
    return ajaxBase("PUT", url, data, fn, headers);
  };

  // 因为 affiliate 大多数请求都是以 x-www-form-urlencoded 的方式发起
  // 所以涉及需要以 JSON 请求时，需要单独指定头部
  var ajaxPutJSON = function (url, data, fn, headers) {
    return ajaxBase(
      "PUT",
      url,
      data,
      fn,
      Object.assign(headers || {}, {
        "Content-Type": "application/json",
      })
    );
  };

  var ajaxPostJSON = function (url, data, fn, headers) {
    return ajaxBase(
      "POST",
      url,
      data,
      fn,
      Object.assign(headers || {}, {
        "Content-Type": "application/json",
      })
    );
  };

  var ajaxPost = function (url, data, fn, headers) {
    return ajaxBase("POST", url, data, fn, headers);
  };

  function strformat() {
    var format = /\{([\d\w\.]+)\}/g;
    var args = Array.prototype.slice.call(arguments),
      v;
    var str = args.shift() + "";
    if (args.length == 1 && typeof args[0] == "object") {
      args = args[0];
    }
    format.lastIndex = 0;
    return str.replace(format, function (m, n) {
      v = args[n];
      return v === undefined ? m : v;
    });
  }

  function loadScript(url, success) {
    var head =
      document.getElementsByTagName("head")[0] || document.documentElement;
    var script = document.createElement("script");

    script.src = url;

    // Handle Script loading
    var done = false;

    // Attach handlers for all browsers
    script.onload = script.onreadystatechange = function () {
      if (
        !done &&
        (!this.readyState ||
          this.readyState === "loaded" ||
          this.readyState === "complete")
      ) {
        done = true;

        if (typeof success === "function") {
          success();
        }

        // Handle memory leak in IE
        script.onload = script.onreadystatechange = null;
        if (head && script.parentNode) {
          head.removeChild(script);
        }
      }
    };

    // Use insertBefore instead of appendChild  to circumvent an IE6 bug.
    // This arises when a base node is used (#2709 and #4378).
    head.insertBefore(script, head.firstChild);
  }

  function checkEmail(email) {
    var reg =
      /^[a-zA-Z0-9_-]+(\.([a-zA-Z0-9_-])+)*@[a-zA-Z0-9_-]+[.][a-zA-Z0-9_-]+([.][a-zA-Z0-9_-]+)*$/;
    return reg.test(email);
  }

  function checkPwd(pwd) {
    //The password must be 8-20 bits, including at least one letter and number，这个是后来加上的规则，之前是只要包含letter和number就好了
    var letterReg = /[a-zA-Z]/g,
      numReg = /[0-9]/g;

    return (
      pwd.length >= 8 &&
      pwd.length <= 20 &&
      letterReg.test(pwd) &&
      numReg.test(pwd)
    );
  }

  function formatPriceThousands(price) {
    price = (price || "0").toString();
    var tmp;

    if (price.indexOf(".") < 0) {
      tmp = price.replace(/(?=(?!(\b))(\d{3})+$)/g, ",");
    } else {
      price = price.split(".");
      tmp =
        price[0].toString().replace(/(?=(?!(\b))(\d{3})+$)/g, ",") +
        "." +
        price[1];
    }

    return tmp;
  }

  function transfer_cent_to_usd_with_formate_thousand(money) {
    //or tansfer 人民币分 to 人民币    资金从数据库出来显示前端之前的处理
    return formatPriceThousands(transfer_cent_to_usd(money)); //所有资金类都做千分位处理
  }

  function accMul(arg1, arg2) {
    var m = 0,
      s1,
      s2;
    try {
      s1 = arg1.toString();
    } catch (e) {
      s1 = 0;
    }
    try {
      s2 = arg2.toString();
    } catch (e) {
      s2 = 0;
    }
    try {
      m += s1.split(".")[1].length;
    } catch (e) {}
    try {
      m += s2.split(".")[1].length;
    } catch (e) {}
    return (
      (Number(s1.replace(".", "")) * Number(s2.replace(".", ""))) /
      Math.pow(10, m)
    );
  }

  function accAdd(arg1, arg2) {
    var r1, r2, m;
    try {
      r1 = arg1.toString().split(".")[1].length;
    } catch (e) {
      r1 = 0;
    }
    try {
      r2 = arg2.toString().split(".")[1].length;
    } catch (e) {
      r2 = 0;
    }
    m = Math.pow(10, Math.max(r1, r2));
    return (accMul(arg1, m) + accMul(arg2, m)) / m;
  }

  function transfer_cent_to_usd(money) {
    // return money / 100;
    return accMul(money, 0.01);
  }

  function transfer_usd_to_cent(money) {
    // or tranfer 人民币 to 人民币分    资金从前端传给后端之前的处理
    // return money * 100;
    return accMul(money, 100);
  }

  function adsEvent(type, data) {
    klook.ajaxPostJSON(
      urlObj.ads_event,
      {
        type,
        data: data || "",
      },
      null,
      {
        "X-iframe-Data": JSON.stringify({
          type,
          data: data || "",
        }),
      }
    );
  }

  function getUrlParam(name) {
    const reg = new RegExp(`(^|&)${name}=([^&]*)(&|$)`);
    const r = (window.location.search).substr(1).match(reg);
    if (r !== null) return r[2];
    return null;
  }

  function ads_monitor(prod, receiveMessageCallback) {
    const sendParentMessage = () => {
      const reg = new RegExp(`(^|&)renderId=([^&]*)(&|$)`);
      const r = window.location.search.substr(1).match(reg);
      if (r) {
        window.parent.postMessage(
          {
            type: "iframeRender",
            content: {
              renderID: r[2],
            },
          },
          "*"
        );
      }
    };

    const receiveMessage = (event) => {
      let data;
      if (event.data.type === `${prod}_onload`) {
        data = DOMPurify.sanitize(JSON.stringify(event.data.content));
        receiveMessageCallback && receiveMessageCallback(data);
      }
    };

    const main = () => {
      window.addEventListener("message", receiveMessage, false);
      sendParentMessage();
    };
    main();
  }

  /**
   * cacheJS
   * @param {*} key 缓存的key
   * @param {*} value  value
   * @param {*} seconds 缓存的时间
   * @returns 过期为'' 没有过期返回value
   */
  function cacheJS(key, value, seconds = 0) {
    var timestamp = Date.parse(new Date()) / 1000;
    if (key && value) {
      //设置缓存
      var expire = timestamp + seconds;
      value = value + "|" + expire;
      localStorage.setItem(key, value);
    } else if (key) {
      //获取缓存
      var val = localStorage.getItem(key) || "";
      var tmp = val ? val.split("|") : [];
      if (!tmp[1] || timestamp >= tmp[1]) {
        localStorage.setItem(key, "");
        return "";
      } else {
        return tmp[0];
      }
    } else {
      console.warn("need key");
    }
  }

  /**
   * 判断是否产线url
   */
  function isProductionUrl() {
    return document && document.domain === "affiliate.klook.com";
  }

  function getWebLang() {
    return LANG_OPTIONS.find((lang) => {
      return window.location.pathname.split("/").includes(lang.value);
    });
  }
  
  function showGoogleAuthExpiredDialog(loginUrl) {
    window.$vueInstance.$msgbox(
      {
        message: `Google login status has expired, please <span class="theme_a" style="cursor:pointer" onClick="klook.handleClickLogin('${loginUrl}')">log in again</span>`,
        title: "Google login status has expired",
        customClass: "google-auth-expired-dialog",
        dangerouslyUseHTMLString: true,
        showConfirmButton: false,
        closeOnClickModal: false,
      }
    );
  }
  
  function checkGoogleAuthStatus () {
      // 前置请求 admin 接口，先走鉴权逻辑再触发下载
      return klook.ajaxGet('/admin/node/pong');
  }

  function handleClickLogin (loginUrl) {
    const newWin = window.open(loginUrl, "_blank");
  
    window.addEventListener("message", (evt) => {
      if (evt.data.type === "google_login_success") {
        window.$vueInstance.$msgbox.close();
      }
    });
  }

  return {
    accMul: accMul,
    getWebLang: getWebLang,
    isProductionUrl: isProductionUrl,
    accAdd: accAdd,
    transfer_cent_to_usd: transfer_cent_to_usd,
    transfer_cent_to_usd_with_formate_thousand:
      transfer_cent_to_usd_with_formate_thousand,
    transfer_usd_to_cent: transfer_usd_to_cent,
    formatPriceThousands: formatPriceThousands,
    urlParam: urlParam,
    loadScript: loadScript,
    getTokenByCookie: getTokenByCookie,
    ajaxGet: ajaxGet,
    ajaxPut: ajaxPut,
    ajaxPutJSON: ajaxPutJSON,
    save_local: save_local,
    get_local: get_local,
    remove_local: remove_local,
    ajaxPost: ajaxPost,
    strformat: strformat,
    ajaxPostJSON: ajaxPostJSON,
    isiOS: isiOS,
    isIeBrowser: isIeBrowser,
    checkEmail: checkEmail,
    checkPwd: checkPwd,
    downloadFile: downloadFile,
    AJAX_ERROR_CODE_OBJ: AJAX_ERROR_CODE_OBJ,
    adsEvent,
    getUrlParam,
    ads_monitor,
    cacheJS,
    checkGoogleAuthStatus,
    isAdminApi,
    handleClickLogin
  };
})();

klook.md5 = (function () {
  function md5cycle(x, k) {
    var a = x[0],
      b = x[1],
      c = x[2],
      d = x[3];

    a = ff(a, b, c, d, k[0], 7, -680876936);
    d = ff(d, a, b, c, k[1], 12, -389564586);
    c = ff(c, d, a, b, k[2], 17, 606105819);
    b = ff(b, c, d, a, k[3], 22, -1044525330);
    a = ff(a, b, c, d, k[4], 7, -176418897);
    d = ff(d, a, b, c, k[5], 12, 1200080426);
    c = ff(c, d, a, b, k[6], 17, -1473231341);
    b = ff(b, c, d, a, k[7], 22, -45705983);
    a = ff(a, b, c, d, k[8], 7, 1770035416);
    d = ff(d, a, b, c, k[9], 12, -1958414417);
    c = ff(c, d, a, b, k[10], 17, -42063);
    b = ff(b, c, d, a, k[11], 22, -1990404162);
    a = ff(a, b, c, d, k[12], 7, 1804603682);
    d = ff(d, a, b, c, k[13], 12, -40341101);
    c = ff(c, d, a, b, k[14], 17, -1502002290);
    b = ff(b, c, d, a, k[15], 22, 1236535329);

    a = gg(a, b, c, d, k[1], 5, -165796510);
    d = gg(d, a, b, c, k[6], 9, -1069501632);
    c = gg(c, d, a, b, k[11], 14, 643717713);
    b = gg(b, c, d, a, k[0], 20, -373897302);
    a = gg(a, b, c, d, k[5], 5, -701558691);
    d = gg(d, a, b, c, k[10], 9, 38016083);
    c = gg(c, d, a, b, k[15], 14, -660478335);
    b = gg(b, c, d, a, k[4], 20, -405537848);
    a = gg(a, b, c, d, k[9], 5, 568446438);
    d = gg(d, a, b, c, k[14], 9, -1019803690);
    c = gg(c, d, a, b, k[3], 14, -187363961);
    b = gg(b, c, d, a, k[8], 20, 1163531501);
    a = gg(a, b, c, d, k[13], 5, -1444681467);
    d = gg(d, a, b, c, k[2], 9, -51403784);
    c = gg(c, d, a, b, k[7], 14, 1735328473);
    b = gg(b, c, d, a, k[12], 20, -1926607734);

    a = hh(a, b, c, d, k[5], 4, -378558);
    d = hh(d, a, b, c, k[8], 11, -2022574463);
    c = hh(c, d, a, b, k[11], 16, 1839030562);
    b = hh(b, c, d, a, k[14], 23, -35309556);
    a = hh(a, b, c, d, k[1], 4, -1530992060);
    d = hh(d, a, b, c, k[4], 11, 1272893353);
    c = hh(c, d, a, b, k[7], 16, -155497632);
    b = hh(b, c, d, a, k[10], 23, -1094730640);
    a = hh(a, b, c, d, k[13], 4, 681279174);
    d = hh(d, a, b, c, k[0], 11, -358537222);
    c = hh(c, d, a, b, k[3], 16, -722521979);
    b = hh(b, c, d, a, k[6], 23, 76029189);
    a = hh(a, b, c, d, k[9], 4, -640364487);
    d = hh(d, a, b, c, k[12], 11, -421815835);
    c = hh(c, d, a, b, k[15], 16, 530742520);
    b = hh(b, c, d, a, k[2], 23, -995338651);

    a = ii(a, b, c, d, k[0], 6, -198630844);
    d = ii(d, a, b, c, k[7], 10, 1126891415);
    c = ii(c, d, a, b, k[14], 15, -1416354905);
    b = ii(b, c, d, a, k[5], 21, -57434055);
    a = ii(a, b, c, d, k[12], 6, 1700485571);
    d = ii(d, a, b, c, k[3], 10, -1894986606);
    c = ii(c, d, a, b, k[10], 15, -1051523);
    b = ii(b, c, d, a, k[1], 21, -2054922799);
    a = ii(a, b, c, d, k[8], 6, 1873313359);
    d = ii(d, a, b, c, k[15], 10, -30611744);
    c = ii(c, d, a, b, k[6], 15, -1560198380);
    b = ii(b, c, d, a, k[13], 21, 1309151649);
    a = ii(a, b, c, d, k[4], 6, -145523070);
    d = ii(d, a, b, c, k[11], 10, -1120210379);
    c = ii(c, d, a, b, k[2], 15, 718787259);
    b = ii(b, c, d, a, k[9], 21, -343485551);

    x[0] = add32(a, x[0]);
    x[1] = add32(b, x[1]);
    x[2] = add32(c, x[2]);
    x[3] = add32(d, x[3]);
  }

  function cmn(q, a, b, x, s, t) {
    a = add32(add32(a, q), add32(x, t));
    return add32((a << s) | (a >>> (32 - s)), b);
  }

  function ff(a, b, c, d, x, s, t) {
    return cmn((b & c) | (~b & d), a, b, x, s, t);
  }

  function gg(a, b, c, d, x, s, t) {
    return cmn((b & d) | (c & ~d), a, b, x, s, t);
  }

  function hh(a, b, c, d, x, s, t) {
    return cmn(b ^ c ^ d, a, b, x, s, t);
  }

  function ii(a, b, c, d, x, s, t) {
    return cmn(c ^ (b | ~d), a, b, x, s, t);
  }

  function md51(s) {
    // Converts the string to UTF-8 "bytes" when necessary
    if (/[\x80-\xFF]/.test(s)) {
      s = unescape(encodeURI(s));
    }
    // var txt = "";
    var n = s.length,
      state = [1732584193, -271733879, -1732584194, 271733878],
      i;
    for (i = 64; i <= s.length; i += 64) {
      md5cycle(state, md5blk(s.substring(i - 64, i)));
    }
    s = s.substring(i - 64);
    var tail = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
    for (i = 0; i < s.length; i++)
      tail[i >> 2] |= s.charCodeAt(i) << (i % 4 << 3);
    tail[i >> 2] |= 0x80 << (i % 4 << 3);
    if (i > 55) {
      md5cycle(state, tail);
      for (i = 0; i < 16; i++) tail[i] = 0;
    }
    tail[14] = n * 8;
    md5cycle(state, tail);
    return state;
  }

  function md5blk(s) {
    /* I figured global was faster. */
    var md5blks = [],
      i; /* Andy King said do it this way. */
    for (i = 0; i < 64; i += 4) {
      md5blks[i >> 2] =
        s.charCodeAt(i) +
        (s.charCodeAt(i + 1) << 8) +
        (s.charCodeAt(i + 2) << 16) +
        (s.charCodeAt(i + 3) << 24);
    }
    return md5blks;
  }

  var hex_chr = "0123456789abcdef".split("");

  function rhex(n) {
    var s = "",
      j = 0;
    for (; j < 4; j++)
      s += hex_chr[(n >> (j * 8 + 4)) & 0x0f] + hex_chr[(n >> (j * 8)) & 0x0f];
    return s;
  }

  function hex(x) {
    for (var i = 0; i < x.length; i++) x[i] = rhex(x[i]);
    return x.join("");
  }

  var md5 = function (s) {
    return hex(md51(s));
  };

  /* this function is much faster, so if possible we use it. Some IEs are the
      only ones I know of that need the idiotic second function, generated by an
      if clause.  */
  function add32(a, b) {
    return (a + b) & 0xffffffff;
  }

  if (md5("hello") != "5d41402abc4b2a76b9719d911017c592") {
    /* eslint-disable */
    function add32(x, y) {
      var lsw = (x & 0xffff) + (y & 0xffff),
        msw = (x >> 16) + (y >> 16) + (lsw >> 16);
      return (msw << 16) | (lsw & 0xffff);
    }
  }
  return md5;
})();

export default klook;
