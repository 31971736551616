
<div class="forgot_success">
  <div class="title" style="text-align: center">
    {{ $t("FORGOT_SUCCESS_thank_you") }}
  </div>
  <div class="klk_dialog_body">
    <p class="forgot_success_text">
      {{ $t("FORGOT_SUCCESS_thank_you_desc") }}
    </p>
  </div>
  <div class="klk_dialog_footer">
    <button
      type="button"
      class="theme_btn_base"
      name="button"
      @click="forgot_success"
    >
      {{ $t("MULTIPLE_ok") }}
    </button>
  </div>
</div>
