import Vue from "vue";
import KlkToast from "./toast.vue";
let ToastConstructor = Vue.extend(KlkToast);
let instance;

const Toast = function (options = {}) {
  if (options === "string") {
    options = {
      content: options,
    };
  }
  instance = new ToastConstructor({
    data: options,
  });
  const msgElement = instance.$mount().$el;
  document.body.appendChild(msgElement);
  instance.visible = true;
  return instance;
};

export { Toast as KlkToast };
