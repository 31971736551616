<style lang="scss">
.klk_collapse {
  width: 100%;
  font-size: 14x;
  .klk_panel {
    &.klk_panel_active {
      .klook-icon-arrow-down {
        transform: rotate(180deg);
      }
      .klk_panel_header {
        box-shadow: none;
      }
    }
    .klk_panel_header {
      position: relative;
      line-height: 1.4;
      padding: 20px 30px 20px 0;
      text-align: left;
      background: #fff;
      box-shadow: inset 0 -1px 0 0 #e0e0e0;
      color: #333333;
      cursor: pointer;
      .klook-icon-arrow-down {
        width: 16px;
        height: 16px;
        position: absolute;
        top: 50%;
        right: 8px;
        line-height: 1;
        margin-top: -8px;
        color: #80848f;
        transition: all 0.3s ease-in-out;
      }
    }
    .klk_panel_content {
      background: #f5f5f5;
      color: #666;
      .klk_panel_content_box {
        padding: 16px;
        line-height: 1.5;
      }
    }
  }
}
</style>

<template>
  <div :class="classes">
    <slot></slot>
  </div>
</template>

<script>
const prefixCls = "klk_collapse";

export default {
  name: "KlkCollapse",
  props: {
    accordion: {
      type: Boolean,
      default: false,
    },
    value: {
      type: [Array, String],
    },
  },
  data() {
    return {
      currentValue: this.value,
    };
  },
  computed: {
    classes() {
      return `${prefixCls}`;
    },
  },
  mounted() {
    this.setActive();
  },
  methods: {
    setActive() {
      const activeKey = this.getActiveKey();

      this.$children.forEach((child, index) => {
        const name = child.name || index.toString();
        let isActive = false;

        if (self.accordion) {
          isActive = activeKey === name;
        } else {
          isActive = activeKey.indexOf(name) > -1;
        }

        child.isActive = isActive;
        child.index = index;
      });
    },
    getActiveKey() {
      let activeKey = this.currentValue || [];
      const accordion = this.accordion;

      if (!Array.isArray(activeKey)) {
        activeKey = [activeKey];
      }

      if (accordion && activeKey.length > 1) {
        activeKey = [activeKey[0]];
      }

      for (let i = 0; i < activeKey.length; i++) {
        activeKey[i] = activeKey[i].toString();
      }

      return activeKey;
    },
    toggle(data) {
      const name = data.name.toString();
      let newActiveKey = [];

      if (this.accordion) {
        if (!data.isActive) {
          newActiveKey.push(name);
        }
      } else {
        let activeKey = this.getActiveKey();
        const nameIndex = activeKey.indexOf(name);

        if (data.isActive) {
          if (nameIndex > -1) {
            activeKey.splice(nameIndex, 1);
          }
        } else {
          if (nameIndex < 0) {
            activeKey.push(name);
          }
        }

        newActiveKey = activeKey;
      }

      this.currentValue = newActiveKey;
      this.$emit("input", newActiveKey);
      this.$emit("on-change", newActiveKey);
    },
  },
  watch: {
    value(val) {
      this.currentValue = val;
    },
    currentValue() {
      this.setActive();
    },
  },
};
</script>
