<template >
  <ul class="klk_week_list">
    <li class="week_wrapper" v-for="(item, i) in week_list" :key="i">
      <label>
        <input :key="item.id" :value="item.id" type="checkbox" v-model="days" />
        <span>{{ item.name }}</span>
      </label>
    </li>
  </ul>
</template>
<script>
export default {
  name: "KlkWeekList",
  props: {
    value: {
      default: () => [],
    },
    standard: {
      //iso week begin with Monday but ODBC bengin with Sunday
      default: () => "iso",
    },
  },
  data() {
    return {
      days: [],
    };
  },
  watch: {
    days: function (newVal /* , oldVal */) {
      this.$emit("input", newVal);
    },
  },
  created() {
    this.days = this.value.concat([]);
    this.week_list = this.get_week_list_by_standard();
  },

  methods: {
    get_week_list_by_standard() {
      var week = [
        {
          id: 1,
          name: this.tips.monday,
        },
        {
          id: 2,
          name: this.tips.tuesday,
        },
        {
          id: 3,
          name: this.tips.wednesday,
        },
        {
          id: 4,
          name: this.tips.thursday,
        },
        {
          id: 5,
          name: this.tips.friday,
        },
        {
          id: 6,
          name: this.tips.saturday,
        },
        {
          id: 7,
          name: this.tips.sunday,
        },
      ];

      return this.standard == "iso"
        ? week
        : week.map((item) => {
            item.id = (item.id % 7) + 1;
            return item;
          });
    },
  },
};
</script>
<style lang="scss">
.klk_week_list {
  li {
    display: inline-block;
    margin-left: 10px;
    input {
      width: 16px;
      height: 16px;
    }
  }
  label {
    cursor: pointer;
  }
}
</style>
