<style lang="scss">
.forgot_form {
  .klk_dialog_footer {
    .btn_full {
      margin: 16px 0 40px;
    }
  }

  .reset_text {
    cursor: pointer;
    text-decoration: underline;
    margin-top: 6px;
    a {
      color: #2fc48d;
    }
  }
}
</style>

<template>
  <div>
    <div class="forgot_form" :data-spm-module="'ForgotPassword'">
      <div class="klk_dialog_title">
        {{ $t("FORGOT_FORM_reset_password") }}
      </div>
      <div class="klk_dialog_body">
        <klk-form :rules="formRules" :model="form_data" ref="ref_form_forgot">
          <klk-form-item prop="email">
            <klk-input
              :placeholder="$t('FORGOT_FORM_please_enter_an_email_address')"
              v-on:input="forgot_error = ''"
              v-model="form_data.email"
            ></klk-input>
          </klk-form-item>
        </klk-form>
      </div>
      <div class="klk_dialog_prompt">
        {{ $t("FORGOT_FORM_reset_password_desc") }}
      </div>
      <div class="klk_dialog_prompt reset_text">
        <a
          :data-spm-item="'GetHelp'"
          target="_blank"
          href="https://www.klook.com/tetris/promo/aff-login-issue/"
        >
          {{ $t("99655") }}
        </a>
      </div>
      <div class="klk_dialog_footer">
        <p class="klk_error_tips" v-show="forgot_error !== ''">
          {{ forgot_error }}
        </p>
        <button
          :data-spm-item="'Submit'"
          type="button"
          class="theme_btn_base btn_full"
          name="button"
          @click="verify_forgot"
          :disabled="disabled_submit_btn"
        >
          {{ $t("MULTIPLE_submit") }}
        </button>
      </div>
    </div>
  </div>

</template>

<script>
import klook from "klook";
import { formRules, urlObj } from "@/comm/common_data";
import {
  getCaptcha,
  initCaptcha,
  simpleCaptchaErrHandler,
} from "@/comm/captcha";

export default {
  data() {
    return {
      form_data: {
        email: "",
      },
      captchaInitSuccess: false,
      captchaSettings: {},
      forgot_error: "",
    };
  },
  methods: {
    captchaInit() {
      initCaptcha("forget_password")
        .then((captcha) => {
          this.captchaInitSuccess = true;
          this.captchaSettings = captcha;
        })
        .catch((error) => {
          this.errorMessage(error);
        });
    },
    async getCaptchaData() {
      if (this.captchaInitSuccess) {
        return getCaptcha(this.captchaSettings)
          .then((result) => {
            if (result === false) {
              return initCaptcha("login")
                .then((captcha) => {
                  this.captchaInitSuccess = true;
                  this.captchaSettings = captcha;
                  return getCaptcha(captcha);
                })
                .catch((error) => {
                  this.errorMessage(error);
                });
            }
            return result;
          })
          .then((captchaData) => {
            return captchaData;
          })
          .catch((error) => {
            this.errorMessage(error);
            simpleCaptchaErrHandler(error, this);
          });
      }
    },
    verify_forgot() {
      // Google Tag Manager
      window.dataLayer.push({
        event: "trackEventCustom",
        eventCategory: "Forgot Password Pop-up",
        eventAction: "Forgot Password",
      });
      this.$refs["ref_form_forgot"].validate(async (valid /* , message */) => {
        if (valid && this.captchaInitSuccess) {
          let captchaData = await this.getCaptchaData();
          if (captchaData) {
            this.submit_forget_info(captchaData);
          }
        }
      });
    },
    submit_forget_info(captchaData) {
      let obj = Object.assign(
        {},
        {
          captcha: {
            gateway: "klook",
            ...captchaData,
            refer_url: location.href,
            ["X-Platform"]: "desktop",
            need_cookie: true,
          },
          email: this.form_data.email,
        }
      );
      klook.ajaxPostJSON(urlObj.user_forget_password, obj, (res) => {
        if (res.success) {
          this.$emit("forgot_form_success");
        } else {
          this.forgot_error = res.error.message;
        }
      });
    },
  },
  computed: {
    disabled_submit_btn() {
      return this.form_data.email.trim().length === 0;
    },
  },
  created() {
    this.formRules = formRules.call(this);
    this.captchaInit();
  },
};
</script>
