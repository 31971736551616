const stringify = require('./stringify');

const isBrowser = typeof window !== 'undefined';

// 报错来源
const FROM_TYPES = {
  // Affiliate 网站
  portal: 'PORTAL',
  // Affiliate 网站 Node Server
  portal_node: 'PORTAL_NODE',
  // widget 投放所在网站
  widget_site: 'WIDGET_SITE',
  // widget 所在 iframe
  widget_iframe: 'WIDGET_IFRAME',
};

// 错误类型
const ERR_TYPES = {
  // onerror 全局报错
  global: 'global',
  // 运行时报错 (try...catch)
  runtime: 'runtime',
  // promise reject
  unhandledrejection: 'unhandledRejection',
  unhandledRejection: 'unhandledRejection', // Node
  // 网络请求
  ajax: 'ajax',
  // Vue errorHandler
  vue: 'vue',
  // Node: uncaughtException
  uncaughtException: 'uncaughtException',
  // 业务自定义错误
  custom: 'custom'
};

let sendPost;

function initSendPost(fn) {
  sendPost = fn;
}

/**
 * 发送日志到 xxx
 * @param log xxx
 * @param from 来源
 * @param logType 日志类型
 * @return xxx
 */
function sendLog() {
  // TODO
}

/**
 * 发送错误警告到 lark
 * @param err 标准错误
 * @param from 来源
 * @param errType 错误类型
 * @return xxx
 */
const sentAlerts = {};

function sendAlert(err, params = {}, from, errType) {
  if (!sendPost) return;
  err = transformError(err, params);
  if (!err) return;
  const timestamp = +new Date();
  const postData = {
    from,
    errType,
    level: "E",
    time: new Date(),
    timestamp,
    ...(isBrowser ? {
      href: window.location.href,
      referer: window.document.referrer,
      agent: window.navigator.userAgent,
    } : {
      node: process.version,
    }),
    msg: err.message,
    stack: err.stack,
    params: stringify(params, null, 2)
  };

  const key = getObjKey(postData);
  if (sentAlerts[key]) {
    // 避免发送频繁 1min
    if (timestamp - sentAlerts[key] < 60 * 1000) return;
  } else sentAlerts[key] = timestamp;

  let name = '';
  if (/^PORTAL$/i.test(from)) {
    name = 'Affiliate.Error';
  } else if (!/^PORTAL_NODE$/i.test(from)) {
    // widget
    name = 'Affiliate.Widget.Error';
  }
  postData.name = name;
  sendPost(postData);
}

function transformError(err, params) {
  if (typeof err === 'string') return new Error(err);
  if (typeof err !== 'object') return;
  if (err instanceof Error) return err;
  else {
    const e = new Error(err.message || 'UnformatableErr');
    if (err.stack) e.stack = err.stack;
    params.unformatableErr = err;
    return e;
  }
}

// shorthand methods
// for example: logger.alert.global(err, params, from)
Object.keys(ERR_TYPES).forEach(type => {
  sendAlert[type] = function (err, params, from) {
    return sendAlert(err, params, from, type);
  };
});

// ...from
const from = {};
// shorthand methods
// for example: logger.portal.alert.runtime(err, params)
Object.keys(FROM_TYPES).forEach(fromType => {
  const alert = {};
  Object.keys(ERR_TYPES).forEach(errType => {
    alert[errType] = function (err, params) {
      return sendAlert(err, params, fromType, errType);
    };
  });
  from[fromType] = {alert};
});

// 生成 32 位的 hash
function stringHashCode() {
  let hash = 0;
  if (this.length === 0) return hash;
  for (let i = 0; i < this.length; i++) {
    const c = this.charCodeAt(i);
    hash = (hash << 5) - hash + c;
    hash = hash & hash; // Convert to 32bit integer
  }
  return hash;
}

// 为 Obj 生成唯一的 key, 来判断这个 key 是否重复发送过
function getObjKey(errorObj) {
  let strs = [];
  const keys = Object.keys(errorObj).sort();
  for (let i in keys) {
    strs.push(i + "=" + errorObj[i]);
  }
  return stringHashCode.call(strs.join(""));
}

module.exports = {
  initSendPost,
  log: sendLog,
  alert: sendAlert,
  ...from,
  FROM_TYPES,
  ERR_TYPES,
};
