<template>
  <div
    :class="classes"
    v-clickoutside="hideMenu"
    @keydown.down="handleFocus"
    :style="{ width: width, height }"
  >
    <div
      :style="{ height }"
      :tabindex="filterable ? -1 : 0"
      class="select_action_wrapper"
      @click="toggleMenu"
      ref="select_action_wrapper"
    >
      <div
        :style="{ height }"
        :class="[prefixCls + '_tag_wrapper']"
        v-for="(item, index) in selectedMultiple"
        :key="index"
      >
        <span :class="[prefixCls + '_tag_text']">{{ item.label }}</span>
        <span class="remove_tag" @click.stop="removeTag(index)">x</span>
      </div>
      <div
        :style="{ height }"
        :class="[prefixCls + '_placeholder']"
        v-show="showPlaceholder && !filterable && !remote"
      >
        {{ placeholder }}
      </div>
      <div
        :style="{ lineHeight: height }"
        :class="[prefixCls + '_selected_label']"
        v-show="!showPlaceholder && !filterable && !remote"
      >
        {{ label }}
      </div>
      <input
        :style="{
          width: width,
          height,
        }"
        :disabled="disabled"
        ref="query_input"
        :class="[prefixCls + '_input']"
        @blur="handleBlur"
        @focus="inputFocus"
        v-on:input="handleQuery"
        v-model="query"
        type="text"
        :placeholder="placeholder"
        v-show="filterable || remote"
      />
      <klk-icon
        v-if="isFocus && searchMod"
        class="klook-icon-search"
        type="search"
      ></klk-icon>
      <klk-icon
        v-else
        class="klook-icon-arrow-down"
        type="arrow-down"
      ></klk-icon>
    </div>
    <transition name="zoom-in">
      <div :class="dropdownCls" v-show="dropdown_visible" ref="dropdown">
        <ul v-show="no_data" class="klk_option_no_data">
          <li></li>
        </ul>
        <ul v-show="remote_loading" class="klk_option_remote_loading">
          <li>loading...</li>
        </ul>
        <ul v-show="!no_data && !remote_loading">
          <slot></slot>
        </ul>
      </div>
    </transition>
  </div>
</template>
<script>
//todo multiselect
import Emitter from "../../../mixins/emitter";
import clickoutside from "../../../directives/clickoutside";
import { createPopper } from "@popperjs/core";

function debounce(fn) {
  let waiting;
  return function () {
    if (waiting) return;
    waiting = true;
    const context = this,
      args = arguments;
    const later = function () {
      waiting = false;
      fn.apply(context, args);
    };
    this.$nextTick(later);
  };
}

const prefixCls = "klk_select";
export default {
  mixins: [Emitter],
  directives: {
    clickoutside,
  },
  name: "KlkSelect",
  props: {
    value: {
      type: [String, Number, Array], //multiple的时候是array
      default: "",
    },
    size: {
      validator(value) {
        return ["small", "large", "default"].indexOf(value) != -1;
      },
    },
    filterable: {
      type: Boolean,
      default: false,
    },
    multiple: {
      type: Boolean,
      default: false,
    },
    placeholder: {
      default: () => "",
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    width: {
      type: String,
    },
    height: {
      type: String,
    },
    remote: {
      type: Boolean,
      default: false,
    },
    searchMod: {
      type: Boolean,
      default: false,
    },
    remoteMethod: {
      type: Function,
    },
  },
  data() {
    return {
      prefixCls: prefixCls,
      model: this.value,
      label: "",
      query: "",
      options: [],
      optionInstances: [],
      dropdown_visible: false,
      no_data: false,
      focusIndex: -1,
      selectedMultiple: [],
      append: true,
      prepend: true,
      isFocus: false,
      remote_loading: false,
      popperInstance: {},
    };
  },
  computed: {
    classes() {
      return [
        prefixCls,
        {
          [`${prefixCls}_dropdown_visible`]: this.dropdown_visible,
          [`${prefixCls}_multiple`]: this.multiple,
          [`${prefixCls}_disabled`]: this.disabled,
          [`${prefixCls}_${this.size}`]: !!this.size,
          [`${prefixCls}_group`]: this.prepend || this.append,
        },
      ];
    },
    dropdownCls() {
      return {
        ["dropdown_wrapper"]: true,
      };
    },
    showPlaceholder() {
      var status = false;

      if (typeof this.model === "string") {
        if (this.model === "" && this.label == "") {
          //有可能值为空，但是却是选择了label
          status = true;
        }
      } else if (Array.isArray(this.model)) {
        if (!this.model.length) {
          status = true;
        }
      } else if (this.model === null) {
        status = true;
      }

      return status;
    },
  },
  watch: {
    dropdown_visible(newVal) {
      if (!newVal) {
        if (this.filterable) {
          this.$refs.query_input.blur();
          //reset options visible
          setTimeout(() => {
            this.broadcastQuery("");
          }, 500);
        }
      }
      this.adjustDropdownCoordinates();
    },
    value(newVal) {
      this.updateOptions();
      this.$nextTick(() => {
        this.model = newVal;
      });
    },
    model(newVal) {
      this.$emit("input", newVal);
      this.$nextTick(() => {
        //wait for children options mounted stable (case:children append and delete may happen)
        this.modelChanged();
        this.$emit("on-change", this.model); //first time emit
        this.dispatch("KlkFormItem", "on-form-change", this.model);
        // this.updateOptions();
      });
    },
  },
  mounted() {
    this.prepend = this.$slots.prepend !== undefined;
    this.append = this.$slots.append !== undefined;

    this.updateOptions();
    this.modelChanged();
    document.addEventListener("keydown", this.handleKeydown);

    this.$on("append", this.debouncedAppendRemove());
    this.$on("remove", this.debouncedAppendRemove());

    this.$on("on-select-selected", (newVal) => {
      if (this.model === newVal) {
        this.hideMenu();
      } else if (this.model != newVal) {
        if (this.multiple) {
          const index = this.model.indexOf(newVal);
          if (index >= 0) {
            //反选
            this.removeTag(index);
          } else {
            this.model.push(newVal);
          }
        } else {
          this.model = newVal;
          this.dropdown_visible = false;
        }
        // this.$nextTick(() => { //wait for v-model update complete then emit change event
        //     this.$emit('on-change', this.model);
        //
        // })
      }
    });

    let dropdown_ele = this.$refs.dropdown,
      select_action_wrapper_ele = this.$refs.select_action_wrapper;
    this.popperInstance = createPopper(
      select_action_wrapper_ele,
      dropdown_ele,
      {
        placement: "bottom",
      }
    );
  },
  destroyed() {
    this.popperInstance.destroy();
  },
  methods: {
    handleFocus() {
      if (!this.dropdown_visible) this.toggleMenu();
    },
    debouncedAppendRemove() {
      //when child changed

      // return debounce(function() {
      //     if (!this.remote) {
      //         // this.modelToQuery();
      //         this.$nextTick(() => this.broadcastQuery(''));
      //     } else {
      //         this.findChild((child) => {
      //             child.updateSearchLabel(); // #1865
      //             child.selected = this.multiple ? this.model.indexOf(child.value) > -1 : this.model === child.value;
      //         });
      //     }
      //     this.slotChange();
      //     this.updateOptions(true);
      // });
      return debounce(() => {
        this.slotChange();
        this.updateOptions();
        if (!this.remote) {
          this.modelChanged();
        }
      });
    },
    adjustDropdownCoordinates() {
      /*var visible_length = 0,
        dropdown_ele = this.$refs.dropdown,
        select_action_wrapper_ele = this.$refs.select_action_wrapper,
        futureDropdownHeight;

      this.findChild((child) => {
        child.hidden ? visible_length : ++visible_length;
      });

      futureDropdownHeight =
        visible_length * 40 > 200 ? 200 : visible_length * 40;
      console.log(
        futureDropdownHeight,
        window.innerHeight,
        select_action_wrapper_ele.getBoundingClientRect()
      );
      if (
        futureDropdownHeight +
          select_action_wrapper_ele.getBoundingClientRect().bottom >
        window.innerHeight
      ) {
        //overflow visible viewport bottom
        dropdown_ele.style.bottom =
          select_action_wrapper_ele.getBoundingClientRect().bottom -
          select_action_wrapper_ele.getBoundingClientRect().top +
          "px";
      } else {
        dropdown_ele.style.bottom = "auto";
      }*/
    },
    removeTag(index) {
      if (this.disabled) {
        return false;
      }
      this.model.splice(index, 1);
    },
    handleQuery() {
      if (this.remote) {
        this.$nextTick(() => {
          const shouldCallRemoteMethod = this.remoteMethod;
          if (shouldCallRemoteMethod) {
            this.dropdown_visible = true;
            this.remote_loading = true;
            const promise = this.remoteMethod(this.query);
            if (promise && promise.then) {
              promise.then((/* res */) => {
                this.remote_loading = false;
              });
            } else {
              this.remote_loading = false;
            }
          }
        });
      } else {
        this.broadcastQuery(this.query);
        this.$nextTick(() => {
          var no_data = true;
          this.findChild((child) => {
            if (!child.hidden) {
              no_data = false;
            }
          });
          this.no_data = no_data;
        });
      }
    },
    handleBlur() {
      this.isFocus = false;
      if (this.remote) return;
      var model = this.model;
      if (model !== "") {
        this.setLabel();
      }
      this.$emit("on-blur");
    },
    inputFocus() {
      this.isFocus = true;
      this.$emit("on-focus");
    },
    hideMenu() {
      this.dropdown_visible = false;
    },
    handleKeydown(e) {
      if (this.dropdown_visible) {
        const keyCode = e.keyCode;
        // Esc slide-up
        if (keyCode === 27) {
          e.preventDefault();
          this.hideMenu();
        }
        // next
        if (keyCode === 40) {
          e.preventDefault();
          this.navigateOptions("next");
        }
        // prev
        if (keyCode === 38) {
          e.preventDefault();
          this.navigateOptions("prev");
        }
        // enter
        if (keyCode === 13) {
          e.preventDefault();

          this.findChild((child) => {
            if (child.isFocus) {
              child.triggerSelect();
            }
          });
        }
      }
    },
    navigateOptions(direction) {
      var child_status = {
        disabled: false,
        hidden: false,
      };
      if (direction == "next") {
        this.focusIndex =
          this.focusIndex >= this.options.length ? 1 : this.focusIndex + 1;
      } else if (direction == "prev") {
        this.focusIndex =
          this.focusIndex <= 1 ? this.options.length : this.focusIndex - 1;
      }

      let find_deep = false; //to avoid possible infinite navigate loop

      this.findChild((child) => {
        if (child.index == this.focusIndex) {
          child_status.disabled = child.disabled;
          child_status.hidden = child.hidden;
          if (!child.hidden && !child.disabled) {
            child.isFocus = true;
          }
        } else {
          child.isFocus = false;
        }

        if (!child.hidden && !child.disabled) {
          find_deep = true;
        }
      });
      this.resetScrollTop();
      //keep going if child disabled or hidden
      if ((child_status.disabled || child_status.hidden) && find_deep) {
        this.navigateOptions(direction);
      }
    },
    resetScrollTop() {
      const index = this.focusIndex - 1;
      var bottomOverflowDistance =
        this.optionInstances[index].$el.getBoundingClientRect().bottom -
        this.$refs.dropdown.getBoundingClientRect().bottom;
      var topOverflowDistance =
        this.optionInstances[index].$el.getBoundingClientRect().top -
        this.$refs.dropdown.getBoundingClientRect().top;
      if (bottomOverflowDistance > 0) {
        this.$refs.dropdown.scrollTop += bottomOverflowDistance;
      }
      if (topOverflowDistance < 0) {
        this.$refs.dropdown.scrollTop += topOverflowDistance;
      }
    },
    broadcastQuery(val) {
      this.broadcast("KlkOption", "on-query-change", val);
      this.broadcast("KlkOptionGroup", "on-query-change", val);
    },
    findChild(cb) {
      //prepare for nested search mainly for option_group
      const find = function (child) {
        const name = child.$options.name;

        if (name === "KlkOption") {
          cb(child);
        } else if (child.$children.length) {
          //mainly for option-group wrap around option and init optionInstances to avoid later search child
          child.$children.forEach((innerChild) => {
            find(innerChild);
          });
        }
      };

      if (this.optionInstances.length) {
        this.optionInstances.forEach((child) => {
          find(child);
        });
      } else {
        this.$children.forEach((child) => {
          find(child);
        });
      }
    },
    toggleMenu() {
      if (this.disabled) {
        return false;
      }
      this.dropdown_visible = !this.dropdown_visible;
      if (this.dropdown_visible == true) {
        this.focusIndex = 0;
      }
      this.popperInstance.update();
    },
    // use when slot changed
    slotChange() {
      this.options = [];
      this.optionInstances = [];
    },
    updateOptions() {
      var index = 1,
        options = [];
      this.findChild((child) => {
        options.push({
          value: child.value,
          label:
            child.label === undefined ? child.$el.textContent : child.label,
        });
        child.index = index++;
        this.optionInstances.push(child);
      });
      this.options = options;
    },
    isMultipleSelect() {
      return this.multiple && Array.isArray(this.model);
    },
    updateMultipleSelected() {
      if (this.isMultipleSelect()) {
        var selected = [];

        for (var i = 0; i < this.model.length; i++) {
          const model = this.model[i];

          for (var j = 0; j < this.options.length; j++) {
            const option = this.options[j];

            if (model === option.value) {
              selected.push({
                value: option.value,
                label: option.label,
              });
            }
          }
        }

        const selectedArray = [];
        const selectedObject = {};
        selected.forEach((item) => {
          if (!selectedObject[item.value]) {
            selectedArray.push(item);
            selectedObject[item.value] = 1;
          }
        });

        this.selectedMultiple = selected;
        this.broadcastQuery("");
      }
    },
    modelChanged() {
      if (this.multiple) {
        this.updateMultipleSelected();
        this.updateChild();
      } else {
        this.setLabel();
        this.updateChild();
      }
    },
    setLabel() {
      var selectedOption = "";
      this.options.forEach((option) => {
        if (option.value === this.model) {
          selectedOption = option;
        }
      });
      if (selectedOption) {
        this.label = selectedOption.label;
      } else {
        this.label = "";
      }
      if (this.filterable || this.remote) {
        this.setQuery();
      }
    },
    setQuery(value) {
      this.query = value || this.label; // 手动选的时候是label  remote模式放上去的时候直接放model
      if (this.remote) {
        this.query = this.query || this.model;
      }
    },
    updateChild() {
      this.findChild((child) => {
        if (this.isMultipleSelect()) {
          if (this.model.indexOf(child.value) != -1) {
            child.selected = true;
          } else {
            child.selected = false;
          }
        } else {
          if (this.model === child.value) {
            child.selected = true;
          } else {
            child.selected = false;
          }
        }
      });
    },
  },
};
</script>
<style lang="scss">
.klk_select {
  width: 140px;
  position: relative;
  display: inline-block;
  outline: none;

  .zoom-in-enter-active,
  .zoom-in-leave-active {
    transform: scaleY(1);
    opacity: 1;
    transition: opacity 300ms cubic-bezier(0.23, 1, 0.72, 1);
    transform-origin: center top;
  }

  .zoom-in-leave-to,
  .zoom-in-enter {
    transform: scaleY(0);
    opacity: 0;
  }

  &.klk_select_disabled {
    .select_action_wrapper {
      background: #f3f3f3;
      opacity: 1;
      cursor: not-allowed;
      color: #999;
    }
    .klook-icon-arrow-down {
      color: #999;
    }
  }
  &.klk_select_small {
    .select_action_wrapper {
      min-height: 32px;
      line-height: 32px;
    }
  }
  .select_action_wrapper {
    background: #fff;
    border-radius: 2px;
    border: 1px solid #e0e0e0;
    text-align: left;
    height: 40px;
    line-height: 40px;
    cursor: pointer;
    padding: 0 12px;
    font-size: 14px;
    outline: none;
    &:focus,
    &:hover {
      border-color: $theme_color;
    }
    .klk_select_tag_wrapper {
      margin: 3px 4px 2px 0;
      display: inline-block;
      height: 22px;
      line-height: 22px;
      margin: 2px 4px 2px 0;
      padding: 0 12px;
      border: 1px solid #e9eaec;
      border-radius: 2px;
      background: #f7f7f7;
      font-size: 12px;
      vertical-align: middle;
      opacity: 1;
      overflow: hidden;
      .remove_tag {
        display: inline-block;
        font-size: 14px;
        cursor: pointer;
        margin-left: 8px;
        color: #666;
        opacity: 0.66;
        position: relative;
        top: 1px;
      }
    }
    .klk_select_placeholder {
      color: #bbbec4;
      display: block;
    }
    .klk_select_selected_label {
      height: 39px;
      display: block;
      padding-right: 16px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
    .klk_select_input {
      line-height: 38px;
      width: 100%;
      display: inline-block;
      outline: 0;
      border: none;
      box-sizing: border-box;
      color: #495060;
      background-color: transparent;
      position: relative;
      padding: 0 40px 0 0;
      font-size: 16px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }
  .klook-icon-search {
    transition: none !important;
  }
  .klook-icon-search,
  .klook-icon-arrow-down {
    width: 16px;
    height: 16px;
    position: absolute;
    top: 50%;
    right: 12px;
    line-height: 1;
    margin-top: -7px;
    // color: #80848f;
    transition: all 0.5s ease-in-out;
  }
  .dropdown_wrapper {
    max-height: 360px;
    width: 100%;
    overflow: auto;
    background: #fff;
    padding: 4px 0;
    z-index: 100;
    text-align: center;
    position: absolute;
    border-radius: 2px;
    box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.24), 0 0 4px 0 rgba(0, 0, 0, 0.12);
    ul {
      li {
        // display: block;
        display: table; //to avoid cut off background

        margin: 0;
        text-align: left;
        width: 100%;
        min-width: 100%;
        line-height: 30px;
        padding: 0 16px;
        white-space: nowrap;
        font-size: 14px;

        &.klk_option:hover {
          background: #f5f5f5;
          color: #000;
          cursor: pointer;
        }
        &.klk_option_focus {
          background: #f5f5f5;
        }
        &.klk_option_selected {
          background: $theme_color;
          color: #fff;
        }
        &.klk_select_group_wrapper {
          padding: 0;

          .klk_select_group_title {
            font-size: 14px;
            color: #999;
            padding-left: 6px;
          }
          li.klk_select_group {
            padding: 0;
          }
        }
      }
      &.klk_option_no_data,
      &.klk_option_remote_loading {
        width: 100%;
        li {
          color: #bbbec4;
          text-align: center;
        }
      }
    }
  }

  &.klk_select_dropdown_visible {
    .klook-icon-arrow-down {
      transform: rotate(180deg);
    }
  }
  &.klk_select_multiple {
    .select_action_wrapper {
      padding: 0 4px;
    }
    ul {
      li {
        &.klk_option_selected {
          background: #fff;
          color: $theme_color;
          &.klk_option_focus {
            background: #f5f5f5;
          }
        }
      }
    }
  }
}
</style>
