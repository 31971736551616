// initial state
var state = {
  type: "",
  secret: "",
  tetrisKreator: false,
};

// getters
var getters = {};

// actions
var actions = {};

// mutations
var mutations = {
  change_join_now_type(state, type) {
    state.type = type;
    // state.type = 'signUp';
  },
  update_tetris_kreator(state, value) {
    state.tetrisKreator = value;
  },
  update_secret(state, secret) {
    state.secret = secret;
  },
};

export { state, getters, actions, mutations };
