<template>
  <div class="confirm_user_info">
    <klk-dialog v-model="dialog_show" width="550px">
      <div class="klk_dialog_title">
        {{ $t("116183") }}
      </div>
      <div class="klk_dialog_body">
        <klk-form
          flex_row
          :rules="formRules"
          :model="formData"
          ref="update_user_info_form"
        >
          <div class="sub-desc-text">{{ $t("116188") }}</div>
          <div style="display: flex; width: 100%">
            <klk-form-item
              :label="$t('116180')"
              prop="prefer_lang"
              :required="true"
              style="margin-right: 20px"
            >
              <el-select filterable v-model="formData.prefer_lang">
                <el-option
                  v-for="(item, key) in klk_lang_options"
                  :key="key"
                  :label="item.title"
                  :value="item.value"
                ></el-option>
              </el-select>
            </klk-form-item>
            <klk-form-item
              :label="$t('85890')"
              prop="country_code"
              :required="true"
            >
              <el-select filterable v-model="formData.country_code">
                <el-option
                  v-for="(value, key) in code_country_map"
                  :key="key"
                  :value="key"
                  :label="value.name"
                ></el-option>
              </el-select>
            </klk-form-item>
          </div>
        </klk-form>
      </div>
      <div class="klk_dialog_footer">
        <el-button type="primary" @click="submit">
          {{ $t("MULTIPLE_confirm") }}</el-button
        >
      </div>
    </klk-dialog>
  </div>
</template>
<script>
import {
  urlObj,
  formRules,
  requestWithCache,
  FROM_LOGIN_KEY,
} from "@/comm/common_data";
export default {
  props: {
    dialog_show: {
      default: false,
    },
  },

  data() {
    return {
      formData: {
        country_code: USER_INFO.country_code,
        prefer_lang: USER_INFO.prefer_lang,
      },
      klk_lang_options: LANG_OPTIONS,
      code_country_map: [],
      formRules: {},
    };
  },
  created() {
    this.loadData();
    localStorage.removeItem(FROM_LOGIN_KEY);
    this.formRules = formRules.call(this);
  },
  methods: {
    submit() {
      this.$refs["update_user_info_form"].validate((valid) => {
        if (valid) {
          this.update_user_info();
        }
      });
    },
    update_user_info() {
      klook.ajaxPostJSON(urlObj.update_user_info, this.formData, (res) => {
        if (res.success) {
          this.$message.success({
            message: "updated success",
            type: "success",
          });
          setTimeout(() => {
            window.open(
              "https://www.klook.com/tetris/promo/aff-2023wrappedup/"
            );
            this.$emit("on-close");
          }, 1500);
        }
      });
    },
    loadData() {
      requestWithCache(urlObj.get_all_country_list).then(
        (data) => (this.code_country_map = data.country_list)
      );
    },
  },
};
</script>
<style  lang="scss" scoped>
.confirm_user_info {
  z-index: 2002;
}
</style>