
<div :class="prop_class">
  <transition :name="transition_names[0]">
    <div class="klk_modal_mask" v-cloak v-show="visible"></div>
  </transition>
  <transition :name="transition_names[1]">
    <div
      class="klk_dialog_container"
      v-show="visible"
      @click="hideDialogFromMask()"
    >
      <div class="klk_dialog" :style="mainStyles">
        <div
          @click="closeDialogFromIcon"
          v-show="close_icon"
          class="klk_dialog_close"
        >
          <svg class="svg_wrapper">
            <use xlink:href="#close"></use>
          </svg>
        </div>
        <slot></slot>
      </div>
    </div>
  </transition>
</div>
