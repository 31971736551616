<style lang="scss">
.common_join_now_dialog_content {
  .dialog_wrapper {
    .klk_dialog {
      .klk_form {
        .klk_form_item {
          margin-top: 16px;
          .klk_form_item_content {
            margin: 0;
            input {
              height: 44px;
              padding: 13px 12px;
            }
          }
        }
        .klk_form_item_error_tip {
          position: inherit;
          line-height: 19px;
          height: 19px;
          padding-top: 0;
        }
      }
      .klk_dialog_prompt {
        margin-top: 12px;
        padding: 0 40px;
        font-size: 14px;
      }
      .klk_error_tips {
        line-height: 1.4;
        margin-bottom: -15px;
        text-align: left;
        font-size: 14px;
        color: #ff5722;
      }
      .klk_dialog_footer {
        margin-bottom: 0;
        padding: 0 40px;
        .btn_full {
          width: 100% !important;
        }
      }
      .klk_dialog_body {
        margin: 0 40px;
      }
    }
  }
}
</style>

<template>
  <div class="common_join_now_dialog_content">
    <klk-dialog
      width="440px"
      class="dialog_wrapper"
      v-model="dialog_show"
      :close_icon="close_icon"
      @close="close_dialog_fn"
    >
      <login-form
        v-if="dialog_type === 'login'"
        ref="login_form"
        @login_form_sign_up="signup_auto_login_form"
        @login_form_forgot_password="change_type('forgot')"
        @login_form_get_user_info="get_user_info"
        :loading="loading"
      >
      </login-form>
      <forgot-form
        :data-spm-page="'LogInPopUp'"
        v-if="dialog_type === 'forgot'"
        @forgot_form_success="change_type('forgot_success')"
      >
      </forgot-form>
      <sign-up-form
        @login_form_sign_up="signup_auto_login_form"
        :loading="loading"
        v-if="dialog_type === 'signUp'"
      ></sign-up-form>
      <forgot-success
        v-if="dialog_type === 'forgot_success'"
        @forgot_success_close="change_type('')"
      >
      </forgot-success>
    </klk-dialog>
  </div>
</template>

<script>
import LoginForm from "./login_form/login_form.vue";
import ForgotForm from "./login_form/forgot_form.vue";
import SignUpForm from "./login_form/sign_up_form.vue";
import ForgotSuccess from "./login_form/forgot_success.vue";
import Cookies from "js-cookie";
import { urlObj, KREATOR_ACTION_LIST } from "@/comm/common_data";
import { shouldEditWebsite } from "@/comm/utils";
import { RENDER_CONFIG } from "@/config";
import klook from "klook";

export default {
  data() {
    return {
      dialog_show: false,
      close_icon: false,
      loading: false,
    };
  },
  components: {
    LoginForm,
    ForgotForm,
    ForgotSuccess,
    SignUpForm,
  },
  methods: {
    close_dialog_fn() {
      this.close_icon = false;
      this.dialog_show = false;
      this.change_type("");
    },
    change_type(type) {
      this.$store.commit("change_join_now_type", type);
    },
    signup_auto_login_form() {
      window.location.replace("/" + window.KLK_LANG_PATH + "signup_auto");
    },
    get_csrf_token() {
      klook.ajaxGet(urlObj.csrf_token, (res) => {
        if (!res.success) {
          console.error("获取 token 失败");
        }
      });
    },
    findLeftLastSlashIndex(str) {
      for (let i = 0; i < str.length; i++) {
        if (str[i] !== "/") {
          return i;
        }
      }
    },
    location(path, prefer_lang) {
      const prefix = path.indexOf("?") > -1 ? "&" : "?";
      const action = `${prefix}action=login`;
      if (prefer_lang) {
        if (prefer_lang === "en-US") {
          prefer_lang = "";
          // prefer_lang 为''去掉path前面的'/'
          if (path && path.startsWith("/")) {
            path = path.slice(1);
          }
        }
        window.location.href = `/${prefer_lang}${path}${action}`;
      } else {
        window.location.href = window.KLK_LANG_PATH
          ? `/${window.KLK_LANG_PATH.substring(
              0,
              window.KLK_LANG_PATH.length - 1
            )}${path}${action}`
          : `${window.KLK_LANG_PATH}${path}${action}`;
      }
    },
    get_user_info() {
      // 通过token获取登陆后的session
      this.loading = true;
      klook.ajaxGet(urlObj.get_user_detail_masked, (res) => {
        this.loading = false;
        if (res.success) {
          // 获取csrf-token
          this.get_csrf_token();
          this.close_dialog_fn();
          // 如果user role是admin 或者 manager. 登录后直接路由到admin模块. 其他role还是默认到dashboard.
          let { result } = res;
          let { user_info }= result;
          let user_role_ids = user_info.gid;
          let { status } = (user_info && user_info.kreator) || {};
          let source_type = user_info.source_type;
          const isFormEmail = this.$route.query.isFromAffiliateTetrisEmail;

          if (+user_info.need_reassign_manager !== 1 && isFormEmail) {
            window.open('https://www.klook.com/tetris/promo/aff-2023wrappedup/');
          }

          if (source_type === 9 || [1].includes(status)) {
            window.location.replace(
              `/${window.KLK_LANG_PATH}my_ads/text_links`
            );
            return;
          }

          if (status === KREATOR_ACTION_LIST.Approved ) {
            // 需要重新刷新页面，
            window.location.replace(
              `/${window.KLK_LANG_PATH}dashboard_kol`
            );
            return;
          }
          // 根据当前用户是否应该 change website name来做跳转
          if (shouldEditWebsite(user_info)) {
            window.location.replace(
              `/${window.KLK_LANG_PATH}change_website_name`
            );
            return;
          }
          let prefer_lang =
            (res.result && user_info && user_info.prefer_lang) || "";
          // 这里根据当前query参数 redirect 来做区别, 如果有redirect参数, 优先级最高
          if (this.$route.query.redirect) {
            let path = this.$route.query.redirect;
            // 添加白名单, affiliate 单页面
            const affiliatePage = RENDER_CONFIG.filter(
              (item) => item.id === -1
            )[0];
            const page = affiliatePage.render_map.filter((item) => {
              // product_list_page
              return item.id === -3;
            })[0];
            if (
              this.$router.match(path).matched.length > 0 ||
              (page && path.includes(`/v1/affnode/${page.name}`))
            ) {
              this.location(path, prefer_lang);
            } else {
              window.location.replace(
                "/" + window.KLK_LANG_PATH + "?action=login"
              );
            }
            return;
          }
          if (
            user_role_ids &&
            Array.isArray(user_role_ids) &&
            (user_role_ids.includes(1) || user_role_ids.includes(2))
          ) {
            window.location.replace("/admin/affiliate_admin?action=login");
          } else {
            let path = "/dashboard";
            this.location(path, prefer_lang);
          }
        } else {
          var error_info;
          if (
            res.error &&
            res.error.code === klook.AJAX_ERROR_CODE_OBJ.user_declined
          ) {
            error_info = this.$t(
              "COMMON_JOIN_NOW_DIALOG_CONTENT_application_decline_error"
            );
          } else {
            error_info =
              (res.error && res.error.message) ||
              this.$t("MULTIPLE_client_server_failure");
          }
          this.$refs.login_form &&
            this.$refs.login_form.login_error_handle &&
            this.$refs.login_form.login_error_handle({
              error_info: error_info,
            });
          if (Cookies.get("sess")) {
            Cookies.remove("sess");
          }
        }
      });
    },
  },
  computed: {
    dialog_type() {
      return this.$store.state.joinNowModule.type;
      // return 'signUp';
    },
  },
  watch: {
    dialog_type() {
      this.close_icon =
        this.$store.state.joinNowModule.type !== "forgot_success";
      this.dialog_show = !(this.$store.state.joinNowModule.type == "");
    },
  },
};
</script>
