// initial state

import { urlObj } from "@/comm/common_data";
import klook from "klook";
import inhouse from "@klook/inhouse-tracker";
import Cookies from "js-cookie";
import { isAdmin, isKreator } from "@/comm/user_role";

const getTotal = () => {
  return new Promise((resolve, reject) => {
    klook
      .ajaxGet(urlObj.notification_read_totals, (resp) => {
        resolve(resp);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

const getQuestion = () => {
  return new Promise((resolve, reject) => {
    klook
      .ajaxGet(
        urlObj.feedback_get_question,
        {
          module: "overall",
          question_type: "csat",
        },
        (resp) => {
          resolve(resp);
        }
      )
      .catch((error) => {
        reject(error);
      });
  });
};

const getV3UserInfo = () => {
  return new Promise((resolve, reject) => {
    klook
      .ajaxGet(
        urlObj.user_info,
        (resp) => {
          resolve(resp);
        }
      )
      .catch((error) => {
        reject(error);
      });
  });
};

var state = {
  total: 0,
  delayTime: {},
  question: {},
  v3UserInfo: {},
  clickNewGategoryFlags: {},
};

// getters
var getters = {};

// actions
var actions = {
  async notificationTotal({ commit }) {
    const res = await getTotal();
    if (res && res.result) {
      commit("SET_TOTAL", res.result.total || 0);
    }
  },
  async initQuestion({ commit }) {
    const res = await getQuestion();
    if (res && res.result) {
      commit("SET_QUESTION", res.result || 0);
    }
  },
  async getV3useInfo({ commit }) {
    const res = await getV3UserInfo();
    if (res && res.result) {
      commit("SET_V3_USER_INFO", {...(res.result?.user_info || {})} || 0);
    }
  },
};

// mutations
var mutations = {
  ["SET_TOTAL"](state, total) {
    state.total = total;
  },
  ["SET_DELAY_TIME"](state, delayTime) {
    state.delayTime = delayTime;
  },
  ["SET_QUESTION"](state, question) {
    state.question = question;
  },
  ["SET_V3_USER_INFO"](state, v3UserInfo) {
    state.v3UserInfo = v3UserInfo;
  },
  ["UPDATE_NEW_CATEGORY_FLAG"](state, flag) {
    state.clickNewGategoryFlags = {...state.clickNewGategoryFlags, ...flag} ;
  }
};

export { state, getters, actions, mutations };
