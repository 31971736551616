export default {
  en: {
    days: ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"],
    months: [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ],
    pickers: ["This Week", "Last Week", "This Month", "Last Month"],
    placeholder: {
      date: "Select Date",
      dateRange: "Select Date Range",
    },
  },
  "zh-CN": {
    days: ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"],
    months: [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ],
    pickers: ["本周", "上周", "本月", "上月"],
    placeholder: {
      date: "选择日期",
      dateRange: "选择日期范围",
    },
  },
  "zh-HK": {
    days: ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"],
    months: [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ],
    pickers: ["本週", "上週", "本月", "上月"],
    placeholder: {
      date: "選擇日期",
      dateRange: "選擇日期範圍",
    },
  },
  "zh-TW": {
    days: ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"],
    months: [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ],
    pickers: ["本週", "上週", "本月", "上月"],
    placeholder: {
      date: "選擇日期",
      dateRange: "選擇日期範圍",
    },
  },
  ko: {
    days: ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"],
    months: [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ],
    pickers: ["이번 주", "지난 주", "이번 달", "지난 달"],
    placeholder: {
      date: "날짜 선택",
      dateRange: "날짜 기간 선택",
    },
  },
  th: {
    days: ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"],
    months: [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ],
    pickers: ["สัปดาห์นี้", "สัปดาห์ที่ผ่านมา", "เดือนนี้", "เดือนที่ผ่านมา"],
    placeholder: {
      date: "เลือกวันที่",
      dateRange: "เลือกช่วงวันที่",
    },
  },
  vi: {
    days: ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"],
    months: [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ],
    pickers: ["Tuần này", "Tuần trước", "Tháng này", "Tháng trước"],
    placeholder: {
      date: "Chọn Ngày",
      dateRange: "Chọn khoảng ngày",
    },
  },
  id: {
    days: ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"],
    months: [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ],
    pickers: ["Minggu Ini", "Minggu Lalu", "Bulan Ini", "Bulan Lalu"],
    placeholder: {
      date: "Pilih Tanggal",
      dateRange: "Pilih Periode",
    },
  },
  ja: {
    days: ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"],
    months: [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ],
    pickers: ["今週", "前週", "今月", "前月"],
    placeholder: {
      date: "日付を選択",
      dateRange: "期間を選択",
    },
  },
};
