
<div
  class="mx-datepicker"
  :class="{ disabled: disabled }"
  :style="{
    width: computedWidth,
    'min-width': range ? (type === 'datetime' ? '320px' : '210px') : '140px',
  }"
  v-clickoutside="handleOutSideClose"
>
  <input
    :name="inputName"
    :disabled="disabled"
    :class="inputClass"
    :value="text"
    :readonly="!editable || range"
    :placeholder="innerPlaceholder"
    ref="input"
    @mouseenter="hoverIcon"
    @mouseleave="hoverIcon"
    @click="togglePopup"
    @input="handleInput"
    @change="handleChange"
    @mousedown="$event.preventDefault()"
  />
  <i
    class="mx-input-icon"
    :class="
      showCloseIcon ? 'mx-input-icon__close' : 'mx-input-icon__calendar'
    "
    @mouseenter="hoverIcon"
    @mouseleave="hoverIcon"
    @click="clickIcon"
  ></i>
  <div
    class="mx-datepicker-popup"
    :class="{ range: range }"
    :style="position"
    ref="calendar"
    v-show="showPopup"
  >
    <calendar-panel
      v-if="!range"
      v-model="currentValue"
      @select="selectDate"
      :show="showPopup"
      :defaultCurrentTime="defaultCurrentTime"
    ></calendar-panel>
    <div v-else style="overflow: hidden">
      <div class="mx-datepicker-top" v-if="ranges.length && showRangePanel">
        <span
          v-for="(range, i) in ranges"
          @click="selectRange(range)"
          :key="i"
          >{{ range.text }}</span
        >
      </div>
      <calendar-panel
        style="width: 50%; box-shadow: 1px 0 rgba(0, 0, 0, 0.1)"
        :limit-start-time="startDisableTime"
        :limit-end-time="defaultEndTime"
        v-model="currentValue[0]"
        :end-at="currentValue[1]"
        @select="selectDate"
        :show="showPopup"
        type="start"
      ></calendar-panel>
      <calendar-panel
        type="end"
        style="width: 50%"
        v-model="currentValue[1]"
        :start-at="currentValue[0]"
        :limit-end-time="endDisableTime"
        @select="selectDate"
        :show="showPopup"
      ></calendar-panel>
    </div>
    <div class="mx-datepicker-footer" v-if="confirm">
      <button
        type="button"
        class="mx-datepicker-btn mx-datepicker-btn-confirm"
        @click="confirmDate"
      >
        {{ confirmText }}
      </button>
    </div>
  </div>
</div>
