
<div class="common_join_now_dialog_content">
  <klk-dialog
    width="440px"
    class="dialog_wrapper"
    v-model="dialog_show"
    :close_icon="close_icon"
    @close="close_dialog_fn"
  >
    <login-form
      v-if="dialog_type === 'login'"
      ref="login_form"
      @login_form_sign_up="signup_auto_login_form"
      @login_form_forgot_password="change_type('forgot')"
      @login_form_get_user_info="get_user_info"
      :loading="loading"
    >
    </login-form>
    <forgot-form
      :data-spm-page="'LogInPopUp'"
      v-if="dialog_type === 'forgot'"
      @forgot_form_success="change_type('forgot_success')"
    >
    </forgot-form>
    <sign-up-form
      @login_form_sign_up="signup_auto_login_form"
      :loading="loading"
      v-if="dialog_type === 'signUp'"
    ></sign-up-form>
    <forgot-success
      v-if="dialog_type === 'forgot_success'"
      @forgot_success_close="change_type('')"
    >
    </forgot-success>
  </klk-dialog>
</div>
