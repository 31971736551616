<template >
  <div :class="className" v-show="show">
    <span class="icon">
      <!-- <i :class="type_icon"></i> -->
      <svg class="svg_wrapper alert_mark">
        <use
          v-if="type_icon == 'klook-icon-exclamation-mark'"
          xlink:href="#exclamation-mark"
        ></use>
        <use
          v-if="type_icon == 'klook-icon-check-mark'"
          xlink:href="#check-mark"
        ></use>
      </svg>
    </span>
    <span v-show="title" v-html="title"></span>
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: "KlkAlert",
  props: {
    title: {
      type: String,
      require: true,
    },
    show: {
      type: Boolean,
      default: function () {
        return true;
      },
    },
    type: {
      type: String,
      default: function () {
        return "warning"; //success | warning
      },
    },
    class_name: {
      type: String,
      default: function () {
        return "";
      },
    },
  },
  computed: {
    type_icon: function () {
      if (this.type == "warning" || this.type == "info") {
        return "klook-icon-exclamation-mark";
      } else if (this.type == "success") {
        return "klook-icon-check-mark";
      }
      return "";
    },
    className: function () {
      return "klk_alert " + "klk_alert_" + this.type + " " + this.class_name;
    },
  },
};
</script>

<style lang="scss">
.klk_alert {
  width: 100%;
  height: auto;
  padding: 8px 15px;
  text-align: center;
  .icon {
    vertical-align: middle;
    display: inline-block;
  }
  .alert_mark {
    width: 16px;
    height: 16px;
  }
  &.klk_alert_warning {
    background: #ffeeee;
    color: #fd5830;
  }
  &.klk_alert_info {
    background: #d7eafd;
    color: #4c87e6;
    .klook-icon-exclamation-mark {
      color: #4c87e6;
    }
  }
  &.klk_alert_success {
    background: #e8f9d4;
    color: #46b826;
  }
  .klook-icon-check-mark {
    color: #46b826;
  }
  .klook-icon-exclamation-mark {
    color: #fd5830;
  }
}
</style>
