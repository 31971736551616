<template>
  <div :class="classes" :name="name">
    <slot></slot>
  </div>
</template>
<script>
import { findComponentsDownward, oneOf } from "../../../utils/assist";
import Emitter from "../../../mixins/emitter";

const prefixCls = "klk_radio_group";

let seed = 0;
const now = Date.now();
const getUuid = () => `KlkRadioGroup_${now}_${seed++}`;

export default {
  name: "KlkRadioGroup",
  mixins: [Emitter],
  props: {
    value: {
      type: [String, Number, Number, Boolean],
      default: "",
    },
    size: {
      validator(value) {
        return oneOf(value, ["small", "large", "default"]);
      },
    },
    type: {
      validator(value) {
        return oneOf(value, ["button"]);
      },
    },
    vertical: {
      type: Boolean,
      default: false,
    },
    name: {
      type: String,
      default: getUuid,
    },
  },
  data() {
    return {
      currentValue: this.value,
      childrens: [],
    };
  },
  computed: {
    classes() {
      return [
        `${prefixCls}`,
        // {
        //     [`${prefixCls}-${this.size}`]: !!this.size,
        //     [`ivu-radio-${this.size}`]: !!this.size,
        //     [`${prefixCls}-${this.type}`]: !!this.type,
        //     [`${prefixCls}-vertical`]: this.vertical
        // }
      ];
    },
  },
  mounted() {
    this.updateValue();
  },
  methods: {
    updateValue() {
      this.childrens = findComponentsDownward(this, "KlkRadio");
      if (this.childrens) {
        this.childrens.forEach((child) => {
          child.currentValue = this.value === child.label;
          child.group = true;
        });
      }
    },
    change(data) {
      this.currentValue = data.value;
      this.updateValue();
      this.$emit("input", data.value);
      this.$emit("on-change", data.value);
      this.dispatch("KlkFormItem", "on-form-change", data.value);
    },
  },
  watch: {
    value() {
      this.currentValue = this.value;
      this.updateValue();
    },
  },
};
</script>
