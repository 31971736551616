import Vue from "vue";
import _ from "underscore";

export default {
  bind: function (el, binding) {
    Vue.nextTick().then(function () {
      function adjustSideMenu() {
        var el_top = el.getBoundingClientRect().top;
        var scroll_top =
          document.body.scrollTop || document.documentElement.scrollTop;
        if (el_top <= parseInt(binding.value.suck_top)) {
          el.style.position = "fixed";
          el.style.top = binding.value.suck_top + "px";
        }
        if (scroll_top <= binding.value.normal_top) {
          el.style.position = "static";
        }
      }
      el.__vueSuckTop__ = _.throttle(adjustSideMenu, 10);
      window.addEventListener("scroll", el.__vueSuckTop__);
    });
  },
  update() {},
  unbind(el /* , binding */) {
    el.style.position = "static";
    window.removeEventListener("scroll", el.__vueSuckTop__);
    delete el.__vueSuckTop__;
  },
};
