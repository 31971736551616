<template >
  <div class="klk_rate">
    <div class="klk_rate_icon">
      <span class="klk_rate_dark">
        <!-- <svg class="svg_wrapper">
                    <use xlink:href="#star"></use>
                </svg>
                <svg class="svg_wrapper">
                    <use xlink:href="#star"></use>
                </svg>
                <svg class="svg_wrapper">
                    <use xlink:href="#star"></use>
                </svg>
                <svg class="svg_wrapper">
                    <use xlink:href="#star"></use>
                </svg>
                <svg class="svg_wrapper">
                    <use xlink:href="#star"></use>
                </svg> -->
        <svg v-for="n in 5" :key="n" class="svg_wrapper svg_star_wrapper">
          <use xlink:href="#star"></use>
        </svg>
      </span>
      <span class="klk_rate_light" :style="'width:' + light_width + 'px'">
        <svg v-for="n in 5" class="svg_wrapper svg_star_wrapper" :key="n">
          <use xlink:href="#star"></use>
        </svg>
        <!-- <svg class="svg_wrapper">
                    <use xlink:href="#star"></use>
                </svg>
                <svg class="svg_wrapper">
                    <use xlink:href="#star"></use>
                </svg>
                <svg class="svg_wrapper">
                    <use xlink:href="#star"></use>
                </svg>
                <svg class="svg_wrapper">
                    <use xlink:href="#star"></use>
                </svg>
                <svg class="svg_wrapper">
                    <use xlink:href="#star"></use>
                </svg> -->
      </span>
    </div>
    <div class="klk_rate_text">{{ text }}</div>
  </div>
</template>

<script>
export default {
  name: "KlkRate",
  props: {
    percentage: {
      type: Number,
      require: true,
    },
    text: {
      type: String,
      default: function () {
        return "";
      },
    },
  },
  data() {
    return {
      light_width: 0,
    };
  },
  watch: {
    percentage: function (newVal) {
      this.light_width = 75 * newVal;
    },
  },
  mounted() {
    this.light_width = 75 * this.percentage;
  },
};
</script>

<style lang="scss">
.klk_rate {
  height: 20px;
  white-space: nowrap;
  text-align: left;
  font-size: 12px;
  .svg_star_wrapper {
    margin: 0 1.5px;
  }
  .klk_rate_icon {
    position: relative;
    width: 75px;
    height: 20px;
    line-height: 20px;
    float: left;
    .klk_rate_dark {
      position: absolute;
      left: 0;
      height: 20px;
      top: 2px;
      .svg_wrapper {
        color: #d5d5d5;
      }
    }
    .klk_rate_light {
      white-space: nowrap;
      width: 20px;
      height: 20px;
      overflow: hidden;
      position: absolute;
      top: 2px;
      left: 0;
      .svg_wrapper {
        color: #ffa628;
      }
    }
  }
  .klk_rate_text {
    padding-left: 4px;
    float: left;
    line-height: 20px;
    font-size: 14px;
    text-align: left;
    color: #888888;
  }
}
</style>
