<template >
    <div class="klk_toast" v-show="visible">
      <div class="toast_inner">
        <i :class="icon" class="icon"></i>
        <div class="title">{{ title }}</div>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    name: "KlkLoading",
    data() {
      return {
        visible: false, // 是否可见 
        timer: null, // 定时器
        duration: 1500, // 持续时间
        title: "", // 弹框内容
        showClose: true, // 是否显示关闭按钮
        isCenter: true, // 是否居中,
        icon:"el-icon-circle-check"
      };
    },
    mounted() {
      this.startTimer();
    },
    methods: {
      startTimer() {
        this.timer = setTimeout(() => {
          if (this.visible) {
            this.close();
          }
        }, this.duration);
      },
      clearTimer() {
        clearTimeout(this.timer);
      },
      close() {
        this.visible = false;
      },
      handleAfterLeave() {
        this.$destroy(true);
        document.body.removeChild(this.$el);
      },
    },
  };
  </script>
  
  <style lang="scss">
  .klk_toast {
    padding: 15px;
    width: 160px;
    height: 160px;
    z-index: 99999;
    position: absolute;
    left: 50%;
    top: 50%;
    background: rgba(255, 255, 255, 0.7);
    text-align: center;
    transform: translate(-50%,-50%);
    border-radius: 15px;

    .icon {
      font-size: 50px;
      color: #52C41A;
    }

    .title {
      font-size: 20px;
      line-height: 28px;
      color: #52C41A;
    }

  }
 
  </style>
  