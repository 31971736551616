<style lang="scss">
#app {
  min-height: 100%;
  min-width: 1160px;
  display: flex;
  flex-direction: column;
  background: #f5f5f5;
  padding-top: 64px;

  .global_content {
    flex: 1 1 auto;
    width: 100%;
    margin: 0 auto;
  }

  .notification_message {
    width: 100%;
    background: $theme_color;
    color: white;
    padding: 10px 24px 14px 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 9;
    position: fixed;

    .jump {
      text-decoration: underline;
      cursor: pointer;
      color: white;
    }

    .svg_wrapper {
      position: absolute;
      right: 20px;

      svg {
        width: 14px;
        height: 14px;
      }
    }
  }

  .alert-wrapper {
    .el-alert--warning.is-light,
    .el-alert--success.is-light,
    .el-alert--info.is-light {
      background-color: #fff;

      .el-alert__description {
        color: black;
        font-size: 14px;
        margin-top: 0;

        a {
          color: #2fc48d;
        }
      }
    }
  }
}
</style>

<template>
  <div id="app" :style="changeWebSiteName() ? { padding: 0 } : {}">
    <div>
      <common-header
        :is_signin="is_signin"
        :total="total"
        v-if="!changeWebSiteName()"
      ></common-header>
      <div class="notification_message" v-show="showNotification()">
        {{ $t("27384", { number: total }) }}
        &nbsp;
        <a class="jump" @click="jumpUrl()">Click here to read</a>
        <div @click="handleClickClose" class="svg_wrapper">
          <klk-icon type="close_white"></klk-icon>
        </div>
      </div>
    </div>

    <div
      class="global_content"
      :style="changeWebSiteName() ? { display: 'flex' } : {}"
    >
      <all-over-feedback v-if="is_not_admin"></all-over-feedback>
      <div
        class="alert-wrapper"
        :style="{
          'padding-top': showNotification() ? '30px' : 0,
        }"
        v-if="showKreatorNotification()"
      >
        <el-alert :type="getNotificationType" :closable="false" show-icon>
          <div v-html="getKreatorNotification()"></div>
        </el-alert>
      </div>
      <transition name="fade" mode="out-in">
        <keep-alive include="Dashboard">
          <router-view></router-view>
        </keep-alive>
      </transition>
    </div>
    <common-footer v-if="!changeWebSiteName()"></common-footer>
    <confirm-user-info-dialog
      :dialog_show="show_confirm_user_info_dailog"
      @on-close="fromLoginFlag = false"
      v-if="show_confirm_user_info_dailog"
    ></confirm-user-info-dialog>
  </div>
</template>

<script>
import Vue from "vue";
import { global_mixin } from "./mixins/global_mixin";
import loading from "./directives/loading.js";
import sucktop from "./directives/sucktop.js";
import CommonHeader from "./tpl/common_header.vue";
import CommonFooter from "./tpl/common_footer.vue";
import { $t, shouldEditWebsite } from "@/comm/utils";
import { KREATOR_ACTION_LIST, FROM_LOGIN_KEY } from "@/comm/common_data";
import Feedback from "./components/feedback";
import AllOverFeedback from "./tpl/feedback.vue";
import ConfirmUserInfoDialog from "./tpl/confirm_user_info_dialog.vue";
import AffiliateUI from "./components/index.js";
import klook from "klook";
import deepClone from "lodash/cloneDeep";
import inhouse from "@klook/inhouse-tracker";
import Cookies from "js-cookie";

Vue.use(Feedback);
// register global components
Vue.use(AffiliateUI);
//register global mixin
Vue.mixin(global_mixin);
//register global directives
Vue.directive("loading", loading);
Vue.directive("sucktop", sucktop);

var is_signin = false;
if (USER_INFO && USER_INFO.id) {
  is_signin = true;
}
window.__klk_aff_host = window.location.protocol + "//" + window.location.host; //模仿之前的affiliate js加载的逻辑

export default {
  name: "App",
  components: {
    CommonHeader,
    CommonFooter,
    AllOverFeedback,
    ConfirmUserInfoDialog,
  },
  provide() {
    return {
      adTypeList: [
        {
          value: 0,
          text: this.$t("MULTIPLE_all"),
        },
        // TextLink
        {
          value: 1,
          text: this.$t("MULTIPLE_text_links"),
        },
        // TextLinkHotel
        {
          value: 2,
          text: `${this.$t("MULTIPLE_text_links")} (${this.$t("hotel")})`,
        },
        // TextLinkHotelDeals
        {
          value: 3,
          text: `${this.$t("MULTIPLE_text_links")} (${this.$t("77359")})`,
        },
        // PlacementLink
        {
          value: 4,
          text: this.$t("AD_LIST_MIXIN_placement_link"),
        },
        // DynamicWidget
        {
          value: 5,
          text: this.$t("MULTIPLE_dynamic_widgets"),
        },
        // DynamicWidgetHotel
        {
          value: 6,
          text: `${this.$t("MULTIPLE_dynamic_widgets")} (${this.$t("hotel")})`,
        },
        // DynamicWidgetHotelDeals
        {
          value: 7,
          text: `${this.$t("MULTIPLE_dynamic_widgets")} (${this.$t("77359")})`,
        },
        // AutoDynamicWidgetHotel
        {
          value: 9,
          text: `${this.$t("MULTIPLE_auto_dynamic_widget")} (${this.$t(
            "hotel"
          )})`,
        },

        // AutoDynamicWidget
        {
          value: 8,
          text: this.$t("MULTIPLE_auto_dynamic_widget"),
        },

        // Banner
        {
          value: 10,
          text: this.$t("MULTIPLE_static_banners"),
        },
        // ActivityBanner
        {
          value: 11,
          text: this.$t("MULTIPLE_activity_banners"),
        },
        // SearchBox
        {
          value: 12,
          text: this.$t("MULTIPLE_search_box"),
        },
        // SearchBoxOld
        {
          value: 13,
          text: `${this.$t("MULTIPLE_search_box")}(old version)`,
        },
        // direct text link
        {
          value: 14,
          text: `${this.$t("MULTIPLE_text_links")} (direct)`,
        },
        // search API
        {
          value: 15,
          text: `TTD Search API`,
        },
        {
          value: 16,
          text: `${this.$t("MULTIPLE_text_links")} (${this.$t("car_rental")})`,
        },
      ],
      dateRangeOptions: [
        {
          label: this.$t("79351"),
          ihtKey: 'Yesterday',
          value: 1,
        },
        {
          label: this.$t("79352"),
          value: 7,
          ihtKey: 'Last7Days',
        },
        {
          label: this.$t("79353"),
          value: 30,
          ihtKey: 'Last30Days',
        },
      ],
      indexOptions: [
        {
          value: "clicks",
          label: this.$t("79367"),
          ihtKey: 'Clicks',
        },
        {
          value: "tickets",
          label: this.$t("79369"),
          ihtKey: 'Tickets',
        },
        {
          value: "commissions",
          label: this.$t("79368"),
          ihtKey: 'Commissions',
        },
        {
          value: "epc",
          label: this.$t("MULTIPLE_epc"),
          ihtKey: 'EPC',
        },
        {
          value: "ctr",
          label: this.$t("MULTIPLE_ctr"), // CTR
          ihtKey: 'CTR',
        },
        {
          value: "cvr",
          label: this.$t("CVR"), // CVR
          ihtKey: 'CVR',
        },
      ],
    };
  },
  data() {
    return {
      pageViewTime: null,
      is_signin: is_signin,
      show_notification: true,
      fromLoginFlag: false,
    };
  },
  computed: {
    total() {
      return this.$store.state.globalObjModule.total;
    },
    is_not_admin() {
      return !this.$route.path.includes("/admin/");
    },
    kreator() {
      const kreator = USER_INFO.kreator;
      if (!kreator || kreator.status === 0) {
        return null;
      }
      return kreator;
    },

    show_confirm_user_info_dailog() {
      return (
        this.fromLoginFlag &&
        +USER_INFO.need_reassign_manager === 1 &&
        +USER_INFO.status === 1
      );
    },
    getNotificationType() {
      const kreator = this.kreator;
      if (!kreator) return "info";
      const status = kreator.noti_type;
      const typeMap = {
        1: "warning",
        2: "success",
        3: "info",
        4: "success",
      };
      return typeMap[status];
    },
  },
  watch: {
    $route: {
      handler(to = {}, from = {}) {
        if (to.name === "ad_performance") {
          this.pageViewTime = new Date();
        }
        if (from.name === "ad_performance") {
          window.dataLayer.push({
            event: "trackEventCustom",
            eventCategory: "Ad Performance",
            eventAction: "Page view",
            eventValue:
              (new Date().getTime() - this.pageViewTime.getTime()) / 1000,
          });
          this.pageViewTime = null;
        }
        // 本地存在登录，来自邮件需要打开tetris
        if (to.query.isFromAffiliateTetrisEmail && USER_INFO.id) {
          if (+USER_INFO.need_reassign_manager !== 1) {
            this.openKreatorTetrisPage(to);
          } else {
            this.fromLoginFlag = true;
          }
        }
        console.log({ to, from });
      },
      immediate: true,
    },
  },
  beforeCreate() {
    if(Cookies.get('islogin')) {
      this.$store.dispatch({
        type: 'getV3useInfo',
      }).then(_ => {
        inhouse.setSprops({
          UserAdmin: this.$store.state.globalObjModule.v3UserInfo.klook_admin || '',
        });
      });
    }
  },
  created() {
    this.fromLoginFlag = localStorage.getItem(FROM_LOGIN_KEY);
  },
  mounted() {
    if (this.showNotification()) {
      // 展示 10 S 后消失
      setTimeout(() => {
        this.show_notification = false;
      }, 10000);
    }
    if (USER_INFO && USER_INFO.id && +USER_INFO.approved === 1) {
      this.$store.dispatch({
        type: "notificationTotal",
      });
      this.$store.dispatch({
        type: "initQuestion",
      });
    }
  },
  methods: {
    openKreatorTetrisPage(route) {
      window.location.href =
        "https://www.klook.com/tetris/promo/aff-2023wrappedup/";
      const query = deepClone(route.query);
      delete query.isFromAffiliateTetrisEmail;
      this.$router.replace({
        query,
      });
    },
    showNotification() {
      const action = klook.getUrlParam("action");
      // 已经登陆, 并且从登陆页跳转至 dashboard 页面
      return (
        USER_INFO &&
        USER_INFO.id &&
        this.total > 0 &&
        this.show_notification &&
        window.location.pathname.includes("dashboard") &&
        action === "login"
      );
    },
    jumpUrl() {
      window.dataLayer.push({
        event: "notification",
        eventCategory: "Dashboard",
        eventAction: "Notification Click",
      });
      this.$router.push({
        name: "notification",
      });
    },
    handleClickClose() {
      this.show_notification = false;
    },
    changeWebSiteName() {
      return shouldEditWebsite();
    },
    getKreatorNotification() {
      const kreator = this.kreator;
      if (!kreator) return;
      const status = kreator.noti_type;
      const promo_code = kreator.promo_code;
      const tplMap = {
        1: `${$t("85895")} ${$t("85894")} `,
        2: `${$t("85896", { Promo_code: promo_code })} ${$t("85894")}`,
        3: $t("85897", {
          MULTILANG_URL_PLACEHOLDER:
            "https://affiliate.klook.com/my_ads/text_links",
        }),
        4: $t("102773", { Promo_code: kreator.promo_code }),
      };
      return tplMap[status];
    },

    showKreatorNotification() {
      const kreator = this.kreator;
      if (!kreator) return false;
      return (
        ![
          "resource_hub",
          "signup_auto",
          "choose_user_type",
          "resendlink",
          "activation_feedback",
          "activation_feedback_source",
          "demo",
        ].includes(this.$route.name) &&
        kreator.noti_type &&
        kreator.noti_type > 0
      );
    },
  },
};
</script>
