function handleShow(el, binding) {
  var position = window.getComputedStyle(el).position;

  if (position === "static" || position === "") {
    el.__static = true;
    el.style.position = "relative";
  }

  var box = document.createElement("div");
  box.className = "vue_loading";
  el.appendChild(box);

  if (binding.modifiers && binding.modifiers.fixed) {
    box.className += " fixed";
  }

  var msg = document.createElement("div");

  switch (binding.arg) {
    case "ring":
      msg.className = "uil-ring-css vue_loading_msg";
      msg.innerHTML = el.__options.html;
      break;
    default:
      msg.innerHTML =
        '<span class="circle_1"></span><span class="circle_2"></span><span class="circle_3"></span>';
      msg.className = "vue_loading_msg";
  }

  box.appendChild(msg);
  box.style.opacity = 1;

  el.__loadingBox = box;
}
function handleHide(el) {
  if (el.__firstLoading) {
    return;
  }
  el.__loadingBox.parentNode.removeChild(el.__loadingBox);
  if (el.__static) {
    el.style.removeProperty("position");
  }
  el.__loadingBox = null;
}
function toggleLoading(el, binding) {
  if (binding.value) {
    handleShow(el, binding);
  } else {
    handleHide(el);
  }
}
export default {
  bind: function (el, binding) {
    el.__static = false;
    el.__loadingBox = null;
    el.__firstLoading = true;
    el.__options = {
      html: "<div></div>",
    };
    toggleLoading(el, binding);
  },
  update: function (el, binding) {
    // console.log('binding',binding);
    el.__firstLoading = false;

    if (binding.oldValue !== binding.value) {
      toggleLoading(el, binding);
    }
  },
};
