// var KlkDialog = require('./src/dialog.vue');

import Modal from "./src/confirm";
import _ from "underscore";
var modalInstance;

function getModalInstance(render) {
  modalInstance =
    modalInstance ||
    Modal.newInstance({
      // closable: false,
      // maskClosable: false,
      // footerHide: true,
      render: render,
    });

  return modalInstance;
}

function confirm(options) {
  var render = "render" in options ? options.render : undefined;
  var instance = getModalInstance(render);

  options.onRemove = function () {
    modalInstance = null;
  };

  instance.show(options);
}

// Modal.info = function (props = {}) {
//     props.icon = 'info';
//     props.showCancel = false;
//     return confirm(props);
// };

// Modal.success = function (props = {}) {
//     props.icon = 'success';
//     props.showCancel = false;
//     return confirm(props);
// };
//
// Modal.warning = function (props = {}) {
//     props.icon = 'warning';
//     props.showCancel = false;
//     return confirm(props);
// };
//
// Modal.error = function (props = {}) {
//     props.icon = 'error';
//     props.showCancel = false;
//     return confirm(props);
// };
//
// Modal.confirm = function (props = {}) {
//     props.icon = 'confirm';
//     props.showCancel = true;
//     return confirm(props);
// };

Modal.alert = function (props) {
  if (typeof props == "string") {
    props = {
      content: props,
    };
  }
  // props.icon = 'confirm';

  props = _.extend(
    {
      showOk: true,
      showCancel: false,
    },
    props
  );

  return confirm(props);
};
//
// Modal.remove = function () {
//     if (!modalInstance) {   // at loading status, remove after Cancel
//         return false;
//     }
//
//     var instance = getModalInstance();
//
//     instance.remove();
// };

export { Modal as KlkModal, Modal as KlkDialog };
