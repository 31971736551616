
<div class="common_footer" v-if="should_show_common_footer" :data-spm-page="`${getPageName}?trg=manual`">
  <div class="common_footer_content" :data-spm-module="'Footer?trg=manual'">
    <div class="footer_upper">
      <div class="related_links">
        <a
          v-for="item in related_link_list"
          :key="item.url"
          :href="item.url"
          target="_blank"
        >
          <klk-poptip
            trigger="hover"
            class="wechat_wrapper"
            v-if="item.icon_type === 'wechat'"
          >
            <klk-icon :type="item.icon_type"></klk-icon>
            <div slot="content" class="poptip_content">
              <img src="../../imgs/klook.png" width="130px" alt=""/>
            </div>
          </klk-poptip>
          <klk-icon :type="item.icon_type" v-else></klk-icon>
        </a>
      </div>
    </div>
    <div class="footer_center">
      <div class="licence_wrapper">
        © 2014-{{ new Date().getFullYear() }} Klook Travel Technology <br/>
        Limited. All Rights Reserved. <br/>
        HK Travel Agent Licence: 354005. <br/>
        SG Travel Agent Licence: 02851. <br/>
        TW Travel Agent License: 甲 03950.
      </div>
      <div class="about_klook list">
        <div class="list_title">
          {{ $t("COMMON_FOOTER_about_klook") }}
        </div>
        <ul>
          <li v-for="(item, index) in about_klook_list" :key="index">
            <a target="_blank" :href="item.url">{{ item.label }}</a>
          </li>
        </ul>
      </div>

      <div class="about_klook list">
        <div class="list_title">
          {{ $t("COMMON_FOOTER_partnerships") }}
        </div>
        <ul>
          <li v-for="(item, index) in partnerships_list" :key="index">
            <a
              target="_blank"
              :href="item.url"
              @click="on_partnership_link(index)"
            >{{ item.label }}</a
            >
          </li>
        </ul>
      </div>

      <div class="about_klook list">
        <div class="list_title">
          {{ $t("COMMON_FOOTER_terms_of_user") }}
        </div>
        <ul>
          <li v-for="(item, index) in terms_of_user_list" :key="index">
            <a target="_blank" :href="item.url">{{ item.label }}</a>
          </li>
        </ul>
      </div>

      <div class="ask_klook_and_payment_channel">
        <button
          class="base_btn ask_klook_btn"
          type="button"
          :data-spm-item="'ContactUs'"
          name="button"
          @click="ask_klook"
        >
          {{ $t("COMMON_FOOTER_ask_klook") }}
        </button>
      </div>
    </div>
  </div>
</div>
