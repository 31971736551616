<style lang="scss">
.sign_up_dialog {
  padding: 24px;

  .klk_dialog_title {
    font-weight: 500;
    padding: 0 !important;
    font-size: 30px;
    line-height: 40px;
    color: #333333;
  }

  .instantly {
    font-weight: normal;
    font-size: 14px;
    line-height: 22px;
    margin-top: 8px;
    margin-bottom: 24px;
  }

  .sign_up_tips {
    background: #e6f7ff;
    border: 1px solid #91d5ff;
    box-sizing: border-box;
    border-radius: 2px;
    width: 100%;
    padding: 9px 16px;
    margin-bottom: 24px;

    div:nth-child(1) {
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 24px;
      margin-bottom: 6px;
    }

    div:nth-child(2) {
      font-size: 12px;
      line-height: 20px;
    }
  }

  .error_tips {
    background: #fff1f0;
    word-wrap: break-word;
    border: 1px solid #ffccc7;
    box-sizing: border-box;
    margin-top: 24px;
    font-weight: normal;
    margin-bottom: 22px;
    padding-left: 16px;
    font-size: 14px;
    height: 40px;
    line-height: 40px;
    border-radius: 2px;
  }

  .sign_up_button {
    width: 100%;
    height: 40px;
    background: $theme_color;
    box-shadow: 0 2px 0 rgba(0, 0, 0, 0.043);
    border-radius: 2px;
    border: none;
    outline: none;
    font-size: 16px;
    line-height: 24px;
    color: #fff;
    cursor: pointer;
    margin-top: 24px;
  }

  .login_button {
    text-align: center;
    font-size: 14px;
    line-height: 22px;
    cursor: pointer;
    color: rgba(0, 0, 0, 0.85);
    margin-top: 17px;
  }

  .sign_up_form {
    ::v-deep .klk_form_item_error_tip {
      margin-top: 5px;
    }
  }

  .klk_form_item_error_tip {
    margin-top: 5px !important;
  }
}
</style>

<template>
  <div class="sign_up_dialog" v-loading="loading" data-spm-page="SignUpPopUp">
    <div class="klk_dialog_title">
      {{ $t("MULTIPLE_sign_up") }}
    </div>
    <div class="instantly">{{ $t("set_up_account_instantly") }}</div>
    <div class="sign_up_tips">
      <div>{{ $t("has_klook") }}</div>
      <div>{{ $t("can_use_email") }}</div>
    </div>
    <div class="error_tips" :data-spm-module="`Error?oid=error_${login_error_code}&ext=${encodeURIComponent(JSON.stringify({
          msg: login_error,
        }))}`" v-show="login_error !== ''">
      {{ login_error }}
    </div>
    <div class="sign_up_form">
      <klk-form :rules="formRules" :model="form_data" ref="sign_up_form">
        <klk-form-item prop="email" style="margin-top: 0">
          <el-autocomplete
            style="width: 100%;"
            :placeholder="$t('MULTIPLE_email')"
            :trigger-on-focus="false"
            :fetch-suggestions="querySearch"
            v-model.trim="form_data.email"
          >
          </el-autocomplete>
        </klk-form-item>
        <klk-form-item prop="sign_up_password">
          <el-input
            :placeholder="$t('MULTIPLE_password')"
            type="password"
            v-model="form_data.sign_up_password"
          ></el-input>
        </klk-form-item>
      </klk-form>
    </div>
    <div class="sign_up_btns">
      <el-button
        type="primary"
        :data-spm-module="'SignUp'"
        :data-spm-virtual-item="`__virtual`"
        style="width: 100%;margin-top: 10px;"
        @click="sign_up">
        {{ $t("MULTIPLE_sign_up") }}
      </el-button>
      <div
        :data-spm-module="'LoginIn'"
        :data-spm-virtual-item="`__virtual`"
        class="login_button"
        @click="loginIn">
        {{ $t("MULTIPLE_log_in") }}
      </div>
    </div>
  </div>
</template>

<script>
import klook from "klook";
import { formRules, urlObj } from "@/comm/common_data";
import {
  getCaptcha,
  initCaptcha,
  simpleCaptchaErrHandler,
} from "@/comm/captcha";
import Config from "@klook/aff-config";

export default {
  props: {},
  data() {
    return {
      enterEvent: () => {
      },
      login_error: "",
      login_error_code: '',
      formRules: {},
      loading: false,
      captchaSettings: {},
      captchaInitSuccess: false,
      form_data: {
        email: "",
        sign_up_password: "",
      },
    };
  },
  methods: {
    getQuerySearchList(queryString) {
      let key = queryString;
      if (queryString.indexOf("@") !== -1) {
        key = queryString.split("@")[0];
      }

      return [
        "@gmail.com",
        "@hotmail.com",
        "@naver.com",
        "@yahoo.com",
        "@qq.com",
        "@outlook.com",
        "@163.com",
        "@live.com",
        "@icloud.com"
      ].map((item) => {
        return { value: `${key}${item}` };
      }).filter((res) => res.value?.indexOf(queryString) > -1);
    },
    querySearch(queryString, cb) {
      cb([ ...this.getQuerySearchList(queryString) ]);
    },
    async getCaptchaData() {
      if (this.captchaInitSuccess) {
        return getCaptcha(this.captchaSettings)
          .then((result) => {
            if (result === false) {
              return initCaptcha("login")
                .then((captcha) => {
                  this.captchaInitSuccess = true;
                  this.captchaSettings = captcha;
                  return getCaptcha(captcha);
                })
                .catch((error) => {
                  this.errorMessage(error);
                });
            }
            return result;
          })
          .then((captchaData) => {
            return captchaData;
          })
          .catch((error) => {
            this.errorMessage(error);
            simpleCaptchaErrHandler(error, this);
          });
      }
    },
    sign_up() {
      this.$refs["sign_up_form"].validate(async (valid) => {
        if (valid && this.captchaInitSuccess) {
          let captchaData = await this.getCaptchaData();
          if (captchaData) {
            this.submit_signup_info(captchaData);
          }
        }
      });
    },
    loginIn() {
      window.dataLayer.push({
        event: "trackEventCustom",
        eventCategory: "Sign Up",
        eventAction: "Log In",
      });
      this.$store.commit("change_join_now_type", "login");
    },
    submit_signup_info(captchaData) {
      let password = klook.md5(this.form_data.sign_up_password);

      let obj = Object.assign(
        {},
        {
          captcha: {
            gateway: "klook",
            ...captchaData,
            refer_url: location.href,
            ["X-Platform"]: "desktop",
            need_cookie: true,
          },
          email: this.form_data.email,
          password,
        }
      );
      this.loading = true;
      // 前端在登陆和注册时都要去调用用户中心的登陆接口鉴权
      klook.ajaxPostJSON(
        urlObj.user_login,
        obj,
        async (res) => {
          // 鉴权成功 请求affiliate登录接口判断是否有affiliate账户 没有就去填写资料 否则提示已存在用户
          if (res.success) {
            // this.login_error_handle(this.$t("user_already_exists"));
            let token = res.result && res.result.token;
            this.newLogin(token);
          } else {
            // 失败走注册流程 调用注册接口 create_toc_user
            await this.captchaInit();
            let captchaData = await this.getCaptchaData();
            klook.ajaxPostJSON(
              urlObj.create_toc_user,
              {
                password,
                login_email: this.form_data.email,
                captcha: captchaData,
              },
              (res) => {
                if (res.success) {
                  let token = res.result && res.result.token;
                  // 调用affiliate登录接口触发填写资料
                  this.newLogin(token);
                } else {
                  this.login_error_handle(res.error);
                  this.loading = false;
                }
              },
              {
                ["Accept-Language"]: Config.USER_LANG_MAP[KLK_LANG],
                ["Currency"]: "USD",
              }
            );
          }
        },
        {
          ["Accept-Language"]: Config.USER_LANG_MAP[KLK_LANG],
        }
      );
    },
    newLogin(token) {
      klook.ajaxGet(
        urlObj.new_login,
        {
          // login_id: this.form_data.email,
        },
        (resp) => {
          this.loading = false;
          if (resp.success) {
            let result = (resp && resp.result) || {};
            // 不存在affiliate用户
            if (result.secret) {
              window.dataLayer.push({
                event: "trackEventCustom",
                eventCategory: "Sign Up",
                eventAction: "Sign Up",
              });
              this.$store.commit("update_secret", result.secret || "");
              this.$store.commit("change_join_now_type", "");
              this.$router.push({
                name: "choose_user_type",
                query: {
                  email: this.form_data.email,
                  type: "Sign_Up",
                },
              });
            } else {
              // 存在用户
              this.login_error_handle({ message: this.$t("user_already_exists") });
            }
          } else {
            this.login_error_handle(resp.error);
          }
        },
        {
          ["Accept-Language"]: Config.USER_LANG_MAP[KLK_LANG],
          ["Token"]: token,
        }
      );
    },
    login_error_handle(error) {
      this.loading = false;
      this.login_error = error?.message || ''
      this.login_error_code = error?.code || ''
    },
    captchaInit() {
      initCaptcha("register")
        .then((captcha) => {
          this.captchaInitSuccess = true;
          this.captchaSettings = captcha;
        })
        .catch((error) => {
          this.errorMessage(error);
        });
    },
  },
  created() {
    this.enterEvent = (e) => {
      if (e.code === "Enter") {
        this.sign_up();
      }
    };
    document.addEventListener("keyup", this.enterEvent);
    this.captchaInit();
    this.formRules = formRules.call(this);
  },

  destroyed() {
    document.removeEventListener("keyup", this.enterEvent);
  },
};
</script>
