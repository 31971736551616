<style lang="scss">
.forgot_success {
  .title {
    margin: 40px 0 12px;
    font-size: 24px;
    font-weight: 500;
  }
  .forgot_success_text {
    line-height: 1.5;
    text-align: center;
    font-size: 14px;
    color: #666666;
  }
  .theme_btn_base {
    margin: 24px 0 32px;
  }
}
</style>

<template >
  <div class="forgot_success">
    <div class="title" style="text-align: center">
      {{ $t("FORGOT_SUCCESS_thank_you") }}
    </div>
    <div class="klk_dialog_body">
      <p class="forgot_success_text">
        {{ $t("FORGOT_SUCCESS_thank_you_desc") }}
      </p>
    </div>
    <div class="klk_dialog_footer">
      <button
        type="button"
        class="theme_btn_base"
        name="button"
        @click="forgot_success"
      >
        {{ $t("MULTIPLE_ok") }}
      </button>
    </div>
  </div>
</template>

<script>
export default {
  methods: {
    forgot_success() {
      this.$emit("forgot_success_close");
    },
  },
};
</script>
